import { Button, SortableTable } from '@eppendorf/vnls-react-components';
import { createColumnHelper } from '@tanstack/react-table';
import { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { isValidHttpUrl } from '$shared/utils/is-valid-http-url.util';

import { LicenseTableItem } from '$features/licenses/license';

interface Props {
  items: LicenseTableItem[];
}

function UrlWrapper({ url, label }: { url?: string; label: string }) {
  return url && isValidHttpUrl(url) ? (
    <Button variant="tertiary">
      <Link to={url} target="_blank">
        {label}
      </Link>
    </Button>
  ) : (
    url
  );
}

const columnHelper = createColumnHelper<LicenseTableItem>();

export function OpenSourceLibrariesTable({ items }: Props): ReactElement {
  const { t } = useTranslation();

  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        header: t('osl.name'),
      }),
      columnHelper.accessor('version', {
        header: t('osl.version'),
      }),
      columnHelper.accessor('license', {
        header: t('osl.license'),
      }),
      columnHelper.accessor('publisher', {
        header: t('osl.publisher'),
      }),
      columnHelper.accessor('url', {
        header: t('osl.url'),
        // eslint-disable-next-line react/no-unstable-nested-components -- we want this here
        cell: ({ row }) => (
          <UrlWrapper url={row.original.url} label={t('osl.visitWebsite')} />
        ),
      }),
      columnHelper.accessor('repository', {
        header: t('osl.repository'),
        // eslint-disable-next-line react/no-unstable-nested-components -- we want this here
        cell: ({ row }) => (
          <UrlWrapper url={row.original.repository} label={t('osl.visitRepository')} />
        ),
      }),
    ],
    [t],
  );

  return <SortableTable data={items} columns={columns} />;
}
