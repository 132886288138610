import { Dialog, DialogProps } from '@eppendorf/vnls-react-components';
import { useQuery } from '@tanstack/react-query';
import { FunctionComponentElement, useEffect, useState } from 'react';

import {
  AddDeviceDialogScreens,
  DeviceDialogContent,
} from '$features/add-device/device-dialog/device-dialog-content';
import { createDeviceQuery } from '$features/devices/devices.api';

export interface DeviceDialogWrapperProps {
  isOpen?: boolean;
  onOpenChange?: (open: boolean) => void;
  trigger?: DialogProps['trigger'];
}

export function DeviceDialogWrapper({
  isOpen = false,
  onOpenChange,
  trigger,
}: DeviceDialogWrapperProps): FunctionComponentElement<DeviceDialogWrapperProps> {
  const [serialNo, setSerialNo] = useState<string | undefined>();
  const [activeScreen, setActiveScreen] =
    useState<AddDeviceDialogScreens>('submitScreen');

  const { data: device } = useQuery({
    ...createDeviceQuery({ manufacturer: 'Eppendorf', serialNumber: serialNo ?? '' }),
    enabled: !!(
      isOpen &&
      serialNo &&
      (activeScreen === 'successScreen' || activeScreen === 'waitingScreen')
    ),
  });

  function handleOpenChange(open: boolean): void {
    if (onOpenChange) onOpenChange(open);
  }

  function handleScreenChange(screen: AddDeviceDialogScreens): void {
    setActiveScreen(screen);
  }

  function resetDialog(): void {
    setSerialNo(undefined);
    setActiveScreen('submitScreen');
  }

  useEffect(() => resetDialog(), [isOpen]);

  return (
    <Dialog
      isOpen={isOpen}
      onOpenChange={(open) => handleOpenChange(open)}
      trigger={trigger}
      className="w-max-4xl"
      hideCloseButton={
        activeScreen === 'waitingScreen' ||
        activeScreen === 'successScreen' ||
        activeScreen === 'errorScreen'
      }
    >
      <DeviceDialogContent
        serialNumber={serialNo}
        onSerialNumberChange={setSerialNo}
        activeScreen={activeScreen}
        setActiveScreen={(screen: AddDeviceDialogScreens) => handleScreenChange(screen)}
        device={device}
      />
    </Dialog>
  );
}
