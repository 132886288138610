import { PaginatedResponse, User } from '@eppendorf/vnls-user-tenant-utils';
import { QueryClient } from '@tanstack/react-query';
import { ReactElement } from 'react';
import { Outlet, useRouteError } from 'react-router-dom';

import { prepopulateCache } from '$shared/utils/react-query.utils';

import { createUsersQueryWithQueryParams } from '$features/users/users.api';
import { UsersOverview } from '$features/users-overview';

export async function usersLoader(queryClient: QueryClient): Promise<null> {
  return prepopulateCache<PaginatedResponse<User>>(
    queryClient,
    createUsersQueryWithQueryParams({ page: 1, pageSize: 10, sort: '+status' }),
  );
}

export function UserManagementPage(): ReactElement {
  const routeError = useRouteError();
  return (
    <>
      <UsersOverview />
      {!routeError && <Outlet />}
    </>
  );
}
