import {
  DeviceModels,
  DeviceTypeConfig,
} from '@eppendorf/vnls-application-configuration-service-types';
import { Device as InventoryDevice } from '@eppendorf/vnls-inventory-service-types';
import { Device as RealtimeDevice } from '@eppendorf/vnls-telemetry-and-events-types';

import { useDeviceTypeConfig } from '$components/app-config/useDeviceTypeConfig';

/**
 * Return the DeviceTypeConfig merging "config.parameters" with "custom.parameters" for a given device
 * @param device The device information
 * @returns The DeviceTypeConfig for the given model name or null if no DeviceTypeConfig is found
 */
export function useDeviceParameters(
  device?: InventoryDevice | RealtimeDevice | null,
): DeviceTypeConfig<DeviceModels> | null {
  const deviceTypeConfig = useDeviceTypeConfig(device);

  if (!device || !deviceTypeConfig) {
    return null;
  }

  return {
    ...deviceTypeConfig,
    parameters: [
      ...deviceTypeConfig.parameters,
      ...(deviceTypeConfig.custom?.[device.model as DeviceModels]?.parameters ?? []),
    ],
  };
}
