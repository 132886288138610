interface ProjectVersionDescription {
  url?: string;
  repository: string;
  publisher: string;
  license: string;
}

interface ProjectLicenseDescription extends ProjectVersionDescription {
  versions: Record<string, Partial<ProjectVersionDescription> | null>;
}

export type LicensesMap = Record<string, ProjectLicenseDescription>;

export interface LicenseTableItem {
  name: string;
  version: string;
  license: string;
  publisher: string;
  url?: string;
  repository: string;
}

const mapLicenseToTableItem = (
  name: string,
  version: string,
  license: ProjectLicenseDescription,
): LicenseTableItem => {
  const versionLicense = license.versions[version as keyof typeof license.versions];

  return {
    name,
    version,
    license: versionLicense?.license ?? license.license,
    publisher: versionLicense?.publisher ?? license.publisher,
    url: versionLicense?.url ?? license.url,
    repository: versionLicense?.repository ?? license.repository,
  };
};

export const mapLicensesToTableItems = (
  licenses?: LicensesMap | null,
): LicenseTableItem[] =>
  licenses
    ? Object.keys(licenses).reduce<LicenseTableItem[]>((result, key) => {
        const license = licenses[key as keyof LicensesMap];

        const licenseVersions = Object.keys(license.versions);

        const mappedLicenseVersions = licenseVersions.map((version) =>
          mapLicenseToTableItem(key, version, license),
        );

        result.push(...mappedLicenseVersions);

        return result;
      }, [])
    : [];
