import { Device } from '@eppendorf/vnls-inventory-service-types';
import { HasClassName, Icon } from '@eppendorf/vnls-react-components';
import { Device as RealtimeDevice } from '@eppendorf/vnls-telemetry-and-events-types';
import { FunctionComponentElement } from 'react';

import { DeviceNameOrSerialNumber } from '$features/device-list/device-name-or-serialnumber';
import { useDeviceViewConfig } from '$features/devices/devices.view.config';

export interface DeviceDetailHeaderProps extends HasClassName {
  device?: Device | RealtimeDevice;
}

export function DeviceDetailHeader({
  className,
  device,
}: DeviceDetailHeaderProps): FunctionComponentElement<DeviceDetailHeaderProps> {
  const viewConfig = useDeviceViewConfig(device);

  return (
    <div className={`flex flex__ai--center ${className}`}>
      <Icon name={viewConfig.deviceIcon} />
      <div className="m-left-s m-right-l">
        <h2 className="title">
          <DeviceNameOrSerialNumber device={device} />
        </h2>
        <div>
          {device?.manufacturer} {device?.model}
        </div>
      </div>
    </div>
  );
}
