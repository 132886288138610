import { ChartType } from '@eppendorf/vnls-application-configuration-service-types';
import {
  Bucket,
  Device,
  HistoricalData,
} from '@eppendorf/vnls-telemetry-and-events-types';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import gql from 'graphql-tag';

import { GraphQLAmplifyClient } from '$shared/graphql/graphql-client';
import { getLocalizedDateTime } from '$shared/utils/date.utils';

import { RANGE_PERIOD } from '$features/monitoring/history-charts/useRangePeriod';
import { getQueryParameters } from '$features/monitoring/history-charts/utils/getQueryParameters';
import {
  applyFractionDigits,
  createValueElement,
} from '$features/monitoring/shared/formatting.utils';

export type DeviceHistoryCharts = Pick<Device, 'historicalData'>;
export type TransformedHistoricalData =
  | (Omit<HistoricalData, 'buckets'> & {
      buckets: Array<Bucket>;
    })
  | undefined;

function isNumber(value: unknown): value is number {
  return Number.isFinite(value);
}

export const createHistoryChartsQuery = (
  manufacturer: string,
  serialNumber: string,
  subject: string,
  rangePeriod: string,
  from: number | null,
  to: number | null,
  minBucketStep: number | null,
  chartType: ChartType = ChartType.Continuous,
): UseQueryOptions<HistoricalData> => {
  // eslint-disable-next-line security/detect-object-injection -- there will be an error if a period is not found
  const queryParams = getQueryParameters[rangePeriod](from, to);

  if (minBucketStep && minBucketStep > queryParams.bucketStep)
    queryParams.bucketStep = minBucketStep;

  const historyChartsQuery = gql(`
  query HistoryCharts {
    device(serialNumber: "${serialNumber}", manufacturer: "${manufacturer}") {
      historicalData(from: ${queryParams.from}, to: ${queryParams.to}, bucketStep: ${queryParams.bucketStep}, subject: "${subject}", chartType: "${chartType}") {
        subject
        name
        unit
        fractionalDigits
        buckets {
          from
          to
          processValue
          setPoint
          alertHigh
          alertLow
        }
      }
    }
  }`);

  return {
    queryKey: [
      'monitoring-history-charts',
      serialNumber,
      subject,
      rangePeriod,
      ...[rangePeriod === RANGE_PERIOD.CUSTOM ? `${from}-${to}` : undefined],
    ],
    queryFn: async (): Promise<HistoricalData> => {
      try {
        const { data } = await GraphQLAmplifyClient.query<{
          data: { device: DeviceHistoryCharts };
        }>(historyChartsQuery.loc?.source.body || '', {});

        if (!data || !data.device.historicalData) {
          throw new Error('Device is not found or has no historical data');
        }

        return data.device.historicalData;
      } catch (e: unknown) {
        const error = e as Error;

        return Promise.reject(
          new Error(`Request for historical data failed: ${error?.message}`),
        );
      }
    },
    refetchOnWindowFocus: true,
    refetchInterval: rangePeriod === RANGE_PERIOD.LIVE ? 5000 : false,
  };
};

export const useHistoryChartsQuery = (
  manufacturer: string,
  serialNumber: string,
  subject: string,
  rangePeriod: string,
  from: number | null,
  to: number | null,
  minBucketStep: number | null,
  chartType?: ChartType,
) => {
  const { data, ...responseParams } = useQuery(
    createHistoryChartsQuery(
      manufacturer,
      serialNumber,
      subject,
      rangePeriod,
      from,
      to,
      minBucketStep,
      chartType,
    ),
  );

  const transformedChartData: TransformedHistoricalData = data && {
    ...data,
    buckets: data?.buckets.map((item) => ({
      ...item,
      from: item.from,
      to: item.to,
      processValue: isNumber(item?.processValue)
        ? Number(
            createValueElement(
              applyFractionDigits(data?.fractionalDigits, false),
              'double',
              `${item?.processValue}`,
            ),
          )
        : null,
      alertHigh: isNumber(item?.alertHigh)
        ? Number(
            createValueElement(
              applyFractionDigits(data?.fractionalDigits, false),
              'double',
              `${item?.alertHigh}`,
            ),
          )
        : null,
      alertLow: isNumber(item?.alertLow)
        ? Number(
            createValueElement(
              applyFractionDigits(data?.fractionalDigits),
              'double',
              `${item?.alertLow}`,
            ),
          )
        : null,
      setPoint: isNumber(item?.setPoint)
        ? Number(
            createValueElement(
              applyFractionDigits(data?.fractionalDigits),
              'double',
              `${item?.setPoint}`,
            ),
          )
        : null,
      unit: data?.unit,
      fullDate: getLocalizedDateTime(item.to),
    })),
  };

  return {
    data: transformedChartData,
    ...responseParams,
  };
};
