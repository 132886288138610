import { memo, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Loader } from '$components/loader/loader';
import { SidecarRouterProvider } from '$components/sidecar-router/sidecar-router-context';
import { SidecarRouterProps } from '$components/sidecar-router/sidecar-router.definitions';

const SidecarRouterBody = memo(
  ({
    router,
    firstRoutePartial,
    searchParams,
  }: SidecarRouterProps & {
    firstRoutePartial?: string;
    searchParams: URLSearchParams;
  }) => {
    const [loaderFinished, setLoaderFinished] = useState(false);

    useEffect(() => {
      if (!router[firstRoutePartial!]?.loader) {
        setLoaderFinished(true);
        return;
      }

      async function runLoader() {
        if (router[firstRoutePartial!]?.loader !== undefined && firstRoutePartial) {
          await router[firstRoutePartial!]?.loader?.({
            params: searchParams,
          });
        }
      }

      setLoaderFinished(false);

      runLoader()
        .catch((error) => {
          // eslint-disable-next-line no-console -- we want to have errors thrown here
          console.error('Sidecar Loader error:', error);
        })
        .finally(() => {
          setLoaderFinished(true);
        });
    }, [searchParams]);

    return (
      <SidecarRouterProvider value={{ route: router[firstRoutePartial!] }}>
        {!loaderFinished ? <Loader /> : <span />}
        {loaderFinished && router[firstRoutePartial!]?.element}
      </SidecarRouterProvider>
    );
  },

  (prevProps, nextProps) => prevProps.firstRoutePartial === nextProps.firstRoutePartial,
);

export function SidecarRouter({ router }: SidecarRouterProps) {
  const [searchParams] = useSearchParams();
  const firstRoutePartial = searchParams.get('sidecarRoute')?.split('/')[0];

  return (
    <SidecarRouterBody
      router={router}
      firstRoutePartial={firstRoutePartial}
      searchParams={searchParams}
    />
  );
}
