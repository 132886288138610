import { differenceInDays, differenceInHours, differenceInMinutes } from "date-fns";

import { getLocalizedTime } from "./date.utils";

export function linearInterpolation(from: number, to: number): Array<number> {
  const daysDiff = Math.abs(differenceInDays(new Date(from), new Date(to)));
  const hoursDiff = Math.abs(differenceInHours(new Date(from), new Date(to)));
  const minsDiff = Math.abs(differenceInMinutes(new Date(from), new Date(to)));
  const ticksCount = daysDiff || hoursDiff || minsDiff;
  const step = (to - from) / ticksCount;
  return Array.from({ length: ticksCount }, (_, ix) => from + ix * step);
}

export function liveInterpolation(to: number, count: number): Array<number> {
    
  const time = getLocalizedTime(to);
  const minutes = Number(time.split(':')[1]);
  // 300000 is the 5 min interval between each data point
  const roundedTo = Math.round(to / 300000) * 300000;// Round to closest 5 minutes

  const startTimestamp = // check if minutes is a multiple of 5
    minutes % 5 === 0 ? roundedTo - (count - 2) * 300000 : roundedTo - 3600000;
  const timestamps = Array.from({ length: count }, (_, ix) => startTimestamp + ix * 300000);
  if (to < roundedTo) {
    timestamps.pop(); // Remove future timestamp
  }
  return timestamps; 
}
