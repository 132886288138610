import { Icons } from '@eppendorf/vnls-css/dist';
import { Icon, IconSizeClasses } from '@eppendorf/vnls-react-components';
import { FunctionComponentElement } from 'react';
import { useTranslation } from 'react-i18next';

export interface RouterNameCellProps {
  routerName: string;
  manufacturer: string;
  icon: Icons;
}

export function RouterNameCell({
  manufacturer,
  routerName,
  icon,
}: RouterNameCellProps): FunctionComponentElement<RouterNameCellProps> {
  const { t } = useTranslation();

  return (
    <div className="flex flex__ai--center">
      <Icon size={IconSizeClasses.Small} name={icon} />

      <div className="m-left-s">
        <div className="body-bold">
          {routerName || t('deviceDetail.fallbackHeadlineNameEmpty')}
        </div>
        <div className="color-gray-800">{manufacturer}</div>
      </div>
    </div>
  );
}
