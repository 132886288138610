import { Auth } from '@aws-amplify/auth';

import { retryFunc } from '$shared/utils/retry-func';

function initializeTracking(
  accountId: string,
  visitorId: string,
  email: string,
  version = '4.0.0',
) {
  if (!window.pendo) {
    // eslint-disable-next-line no-console -- for debugging purposes
    console.warn('could not find pendo instance for tracking');
    return;
  }

  window.pendo.initialize({
    disableGuides: true,
    visitor: {
      id: visitorId, // Required if user is logged in
      email, // Recommended if using Pendo Feedback, or NPS Email
      // full_name:    // Recommended if using Pendo Feedback
      // role:         // Optional
    },

    account: {
      id: accountId, // Highly recommended, required if using Pendo Feedback
      // name:         // Optional
      // is_paying:    // Recommended if using Pendo Feedback
      // monthly_value:// Recommended if using Pendo Feedback
      // planLevel:    // Optional
      // planPrice:    // Optional
      // creationDate: // Optional
      'vnls-version': version,
    },
  });
}

function teardownPendo() {
  if (!window.pendo) {
    // eslint-disable-next-line no-console -- for debugging purposes
    console.warn('could not find pendo instance for tracking');
    return;
  }

  window.pendo.teardown();

  const localStorageKeys = [];
  for (let i = 0; i < localStorage.length; i += 1) {
    localStorageKeys.push(localStorage.key(i));
  }
  localStorageKeys
    .filter((key) => key?.includes('_pendo_'))
    .forEach((pendoKey) => {
      if (pendoKey) {
        localStorage.removeItem(pendoKey);
      }
    });
}

const COOKIE_CATEGORIES = {
  STRICT: 'Strictly Necessary Cookies',
  FUNCTIONAL: 'Functional Cookies',
  PERFORMANCE: 'Performance Cookies',
};

const initializePendo = async () => {
  const currentSession = await Auth.currentSession();
  if (currentSession) {
    const { payload } = currentSession.getIdToken();
    initializeTracking(payload.tenantId, payload.sub, payload.email);
  }
};

export function checkOneTrustAndInitializePendo() {
  if (!import.meta.env.PUBLIC_ONE_TRUST_ID) {
    return;
  }
  retryFunc(
    async () => {
      if (!window.OneTrust) {
        throw new Error('please retry');
      }
      window.OneTrust.OnConsentChanged((state) => {
        const performanceGroup = window.OneTrust.GetDomainData().Groups.find(
          (group) => group.GroupName === COOKIE_CATEGORIES.PERFORMANCE,
        );
        if (state.detail.includes(performanceGroup?.CustomGroupId)) {
          initializePendo();
        } else {
          teardownPendo();
        }
      });

      return 'all good, no more retries';
    },
    100,
    100,
  );
}
