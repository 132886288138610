import { InviteUserForm } from '$features/invite-user/invite-user-dialog/screens/invite-screen';

export function displayUser(user?: InviteUserForm): string {
  if (user?.firstName && user?.lastName) {
    return `${user?.firstName} ${user?.lastName}, ${user?.email}`;
  }

  if (user?.firstName || user?.lastName) {
    return `${user?.firstName || user?.lastName}, ${user?.email}`;
  }

  return user?.email || '';
}
