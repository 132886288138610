import { User } from '@eppendorf/vnls-user-tenant-utils';
import { FunctionComponentElement, useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { useAutoFocus } from '$shared/custom-hooks/useAutoFocus';

import {
  ConfirmDialogIconType,
  ConfirmationDialog,
} from '$components/confirmation-dialog/confirmation-dialog';
import { displayUser } from '$features/invite-user/invite-user-dialog/screens/display-user.util';

export interface DeleteSuccessProps {
  user: User;
}

export function DeleteSuccessScreen({
  user,
}: DeleteSuccessProps): FunctionComponentElement<void> {
  const { t } = useTranslation();
  const closeButtonRef = useRef<null | HTMLButtonElement>(null);

  useAutoFocus(closeButtonRef);
  return (
    <ConfirmationDialog
      title={t('userDelete.deleteSuccess')}
      iconType={ConfirmDialogIconType.success}
      dialogDescription={
        <Trans
          i18nKey="userDelete.descriptionUserDeleted"
          values={{
            user: displayUser(user),
          }}
          components={{ bold: <span className="font-weight-bold" /> }}
        />
      }
    />
  );
}
