import { Dialog, DialogProps } from '@eppendorf/vnls-react-components';
import { User } from '@eppendorf/vnls-user-tenant-utils';
import { FunctionComponentElement, useEffect, useState } from 'react';

import {
  DeleteUserDialogContent,
  UserDeleteScreens,
} from '$features/delete-user/delete-user-dialog/delete-user-dialog-content';

export interface DeleteUserDialogWrapperProps {
  isOpen?: boolean;
  onOpenChange?: (open: boolean) => void;
  trigger?: DialogProps['trigger'];
  selectedUser: User;
}

export function DeleteUserDialogWrapper({
  isOpen = false,
  onOpenChange,
  trigger,
  selectedUser,
}: DeleteUserDialogWrapperProps): FunctionComponentElement<DeleteUserDialogWrapperProps> {
  function handleOpenChange(open: boolean): void {
    if (onOpenChange) onOpenChange(open);
  }
  const [activeScreen, setActiveScreen] = useState<UserDeleteScreens>(
    UserDeleteScreens.DeleteConfirmScreen,
  );

  function handleScreenChange(screen: UserDeleteScreens): void {
    setActiveScreen(screen);
  }

  function resetDialog(): void {
    setActiveScreen(UserDeleteScreens.DeleteConfirmScreen);
  }

  useEffect(() => resetDialog(), [isOpen]);

  return (
    <Dialog
      isOpen={isOpen}
      onOpenChange={(open) => handleOpenChange(open)}
      trigger={trigger}
      hideCloseButton={
        activeScreen === UserDeleteScreens.DeleteSuccessScreen ||
        activeScreen === UserDeleteScreens.DeleteErrorScreen
      }
      className="w-max-4xl"
    >
      <DeleteUserDialogContent
        selectedUser={selectedUser}
        activeScreen={activeScreen}
        setActiveScreen={(screen: UserDeleteScreens) => handleScreenChange(screen)}
      />
    </Dialog>
  );
}
