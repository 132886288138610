import { Icons } from '@eppendorf/vnls-css/dist';
import { Button, Icon, IconSizeClasses } from '@eppendorf/vnls-react-components';
import cn from 'classnames';
import { ReactElement, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import styles from './tabs-navigation.module.scss';

export interface TabNavigationRoute {
  path: string;
  icon: Icons;
  label: string;
}

interface TabsNavigationProps {
  routes: TabNavigationRoute[];
  onTabChange?: () => void;
  shouldChangeTabOnActive?: boolean;
}

export function TabsNavigation({
  routes,
  onTabChange,
  shouldChangeTabOnActive,
}: TabsNavigationProps): ReactElement {
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeTabPath, setActiveTabPath] = useState('');

  function goTo(path: string): void {
    const newRoute = searchParams.get('sidecarRoute')?.replace(/\/[^/]+$/, `/${path}`);
    searchParams.set(
      'sidecarRoute',
      decodeURIComponent(newRoute || searchParams.get('sidecarRoute') || ''),
    );
    setSearchParams(searchParams);
  }

  useEffect(() => {
    if (shouldChangeTabOnActive && activeTabPath) {
      goTo(activeTabPath);
    }
  }, [shouldChangeTabOnActive]);

  function isActivePath(path: string): boolean {
    return searchParams.get('sidecarRoute')?.endsWith(path) ?? false;
  }

  function handleTabClick(path: string) {
    if (onTabChange) {
      onTabChange();
      setActiveTabPath(path);
    } else {
      goTo(path);
      setActiveTabPath('');
    }
  }
  return (
    <ul className="flex border-solid border-color-gray-400 border-bottom-s">
      {routes.map((route) => (
        <li
          className={cn(
            'flex flex__ai--center cursor--pointer m-right-xxl border-solid border-bottom-m ',
            {
              'border-color-blue-500': isActivePath(route.path),
              'border-color-white': !isActivePath(route.path),
            },
          )}
          key={route.path}
        >
          <Button
            variant="tertiary"
            className={cn('interactive', styles.navigationButton, {
              'color-gray-900': !isActivePath(route.path),
            })}
            onClick={() => handleTabClick(route.path)}
          >
            <Icon
              size={IconSizeClasses.Small}
              name={route.icon as Icons}
              className={cn('masked', 'm-right-s', {
                'bg-blue-500': isActivePath(route.path),
              })}
            />
            <span>
              {/* eslint-disable-next-line @typescript-eslint/no-explicit-any -- we know it exists */}
              {route.label}
            </span>
          </Button>
        </li>
      ))}
    </ul>
  );
}
