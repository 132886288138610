import { Icon, IconSizeClasses } from '@eppendorf/vnls-react-components';
import { ReactElement } from 'react';
import { Trans } from 'react-i18next';

export function NoChartDataAvailable(): ReactElement {
  return (
    <div className="flex flex__jc--center bg-gray-50">
      <div className="flex flex__dir--column flex__ai--center p-top-m p-bottom-xxxl">
        <Icon
          size={IconSizeClasses.XLarge}
          name="charts"
          className="bg-gray-600 m-bottom-xxl"
        />
        <span className="m-bottom-m sub-title">
          <Trans i18nKey="monitoringDetail.historyChartsDetail.noDataAvailable" />
        </span>
        <span className="m-bottom-m body-regular">
          <Trans i18nKey="monitoringDetail.historyChartsDetail.noChartData" />
        </span>
      </div>
    </div>
  );
}
