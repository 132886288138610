import { Device as InventoryDevice } from '@eppendorf/vnls-inventory-service-types';
import { Device as RealtimeDevice } from '@eppendorf/vnls-telemetry-and-events-types';

import { useDeviceTypeConfig } from '$components/app-config/useDeviceTypeConfig';

/**
 * Return the device type for a given model name
 * @param device The device information
 * @param fallback The default type to be return if nothing is found
 * @returns The device type for the given model name. e.g. 'freezer' or null if no device type is found
 */
export function useDeviceType(
  device?: InventoryDevice | RealtimeDevice | null,
  fallback: string = 'generic',
): string | null {
  const deviceTypeConfig = useDeviceTypeConfig(device);

  if (!device) {
    return fallback ?? null;
  }

  return deviceTypeConfig?.type ?? device?.type ?? fallback ?? null;
}
