export enum AlertConfigThresholdLevel {
  High = 'high',
  Low = 'low',
}

export enum AlertConfigThresholdType {
  Alert = 'alert',
  Warn = 'warn',
}

interface AlertConfigThreshold {
  type: AlertConfigThresholdType;
  level: AlertConfigThresholdLevel;
  value: number;
}

export interface AlertConfig {
  active: boolean;
  thresholds: AlertConfigThreshold[];
}
