import { createContext } from 'react';

export interface SidecarContextValue {
  isSidecardClosing: boolean;
  isCancelDialogOpen: boolean;
  setIsCancelDialogOpen: (isOpen: boolean) => void;
}

export const SidecarContext = createContext<SidecarContextValue>({
  isSidecardClosing: false,
  isCancelDialogOpen: false,
  setIsCancelDialogOpen: () => {},
});

export const SidecarProvider = SidecarContext.Provider;
