import { HasClassName } from '@eppendorf/vnls-react-components';
import { RealtimeData, UnitState } from '@eppendorf/vnls-telemetry-and-events-types';
import { ReactElement } from 'react';

import { ActivityStatus } from '$features/monitoring/activity-status/activity-status';
import { findBySubjectAndComponent } from '$features/monitoring/monitoring.utils';

export interface ActivityStatusWrapperProps extends HasClassName {
  realtimeArray: (RealtimeData | null)[];
  bordered?: boolean;
  withDateWhenOffline?: boolean;
}

export function ActivityStatusWrapper({
  realtimeArray,
  className,
  bordered,
  withDateWhenOffline = false,
}: ActivityStatusWrapperProps): ReactElement {
  const status = findBySubjectAndComponent(realtimeArray, 'Unit', 'unitState');
  const activityStatus = (status?.value as UnitState) ?? null;
  const offlineSince =
    withDateWhenOffline && status?.timestamp && activityStatus === UnitState.Offline
      ? status?.timestamp
      : undefined;

  return (
    <ActivityStatus
      bordered={bordered}
      offlineSince={offlineSince}
      className={className}
      activityStatus={activityStatus}
    />
  );
}
