import {
  API,
  GRAPHQL_AUTH_MODE,
  GraphQLQuery,
  GraphQLSubscription,
} from '@aws-amplify/api';

import { refreshAuthorizationHeader } from '$shared/auth/auth.utils';

// TODO: there's no need for this to be a class
export class GraphQLAmplifyClient {
  public static async query<T>(query: string, variables: object): Promise<T> {
    const authToken = await refreshAuthorizationHeader();
    try {
      return (await API.graphql<GraphQLQuery<T>>({
        query,
        variables,
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
        authToken,
      })) as T;
    } catch (error) {
      /* client throws exception if >=1 error present
       *  in this case the error object equals the entire response and therefore contains our
       *  payload data too and so we return it
       */
      return error as T;
    }
  }
}

export async function amplifySubscription<T>(query: string, variables: object) {
  const authToken = await refreshAuthorizationHeader();
  return API.graphql<GraphQLSubscription<T>>({
    query,
    variables,
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
    authToken,
  });
}
