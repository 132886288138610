import {
  ButtonBase,
  ButtonBaseProps,
  HasClassName,
} from '@eppendorf/vnls-react-components';
import cn from 'classnames';
import './button-toggle.scss';

export interface ButtonToggleItem
  extends Pick<ButtonBaseProps, 'disabled' | 'title' | 'onClick'> {
  id: string;
  text: string;
  active: boolean;
}

export interface ButtonToggleProps extends HasClassName {
  items: ButtonToggleItem[];
  testId?: string;
}

// TODO: move to vnls-react-components
export function ButtonToggle({
  items,
  className,
  testId = 'button-toggle',
}: ButtonToggleProps) {
  return (
    <div
      data-testid={testId}
      className={cn(
        'flex flex__jc--space-between gap-xs p-xs m-bottom-l border-s border-solid border-color-gray-500 overflow-hidden overflow-x-auto btn-toggle',
        className,
      )}
    >
      {items.map((item) => (
        <ButtonBase
          key={item.id}
          onClick={item.onClick}
          className={cn(
            'p-left-l p-right-l p-top-xs p-bottom-xs font-size-s border-radius-xxl btn-toggle--info whitespace-nowrap',
            {
              'color-white': item.active,
              'btn--info': item.active,
              'btn--primary': item.active,
              'color-gray-600': item.disabled,
            },
          )}
          disabled={item.disabled}
        >
          {item.text}
        </ButtonBase>
      ))}
    </div>
  );
}
