import { subHours } from 'date-fns';

import { RANGE_PERIOD } from '$features/monitoring/history-charts/useRangePeriod';

const oneMinute = 60 * 1000;
const oneHour = 60 * oneMinute;
const oneDay = 24 * oneHour;

const PERIOD_TO_BUCKET_STEP_MAP = [
  { period: oneDay * 80, bucketStep: oneHour * 12 },
  { period: oneDay * 40, bucketStep: oneHour * 8 },
  { period: oneDay * 20, bucketStep: oneHour * 4 },
  { period: oneDay * 10, bucketStep: oneHour * 2 },
  { period: oneDay * 5, bucketStep: oneHour },
  { period: oneDay * 4, bucketStep: oneMinute * 30 },
  { period: oneDay * 3, bucketStep: oneMinute * 20 },
  { period: oneDay * 2, bucketStep: oneMinute * 10 },
  { period: oneDay, bucketStep: oneMinute * 5 },
  { period: 0, bucketStep: 10_000 },
] as const;

const getCustomQueryParameters = (from?: number | null, to?: number | null) => {
  const queryFrom = from || subHours(Date.now(), 1).setSeconds(0).valueOf();
  const queryTo = (to && to + 1) || new Date().setSeconds(0).valueOf();
  const range = queryTo - queryFrom;

  const { bucketStep } =
    PERIOD_TO_BUCKET_STEP_MAP.find(({ period }) => range >= period) ??
    PERIOD_TO_BUCKET_STEP_MAP[0];

  return {
    from: queryFrom,
    to: queryTo,
    bucketStep,
  };
};

const getLiveQueryParameters = () => {
  const { bucketStep } = PERIOD_TO_BUCKET_STEP_MAP[PERIOD_TO_BUCKET_STEP_MAP.length - 1];

  return {
    from: subHours(Date.now(), 1).setSeconds(0).valueOf() - bucketStep,
    to: Date.now() - bucketStep,
    bucketStep,
  };
};

export const getQueryParameters = {
  [RANGE_PERIOD.CUSTOM]: getCustomQueryParameters,
  [RANGE_PERIOD.LIVE]: getLiveQueryParameters,
};
