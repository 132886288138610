import { Icon, IconSizeClasses, Threshold } from '@eppendorf/vnls-react-components';
import { RealtimeData } from '@eppendorf/vnls-telemetry-and-events-types';
import i18next from 'i18next';
import React from 'react';

import { getThresholdName } from '$features/monitoring/monitoring.utils';
import { ThresholdDynamicValueLabel } from '$features/monitoring/parameters/threshold-dynamic-value-label';
import { ThresholdStaticValueLabel } from '$features/monitoring/parameters/threshold-static-value-label';
import {
  ThresholdType,
  ThresholdValueLabelProps,
} from '$features/monitoring/parameters/types';
import { AlertThresholds } from '$features/monitoring/parameters/types/alert-thresholds';
import {
  createValueElement,
  applyFractionDigits,
} from '$features/monitoring/shared/formatting.utils';

export function createThresholdTile(type: ThresholdType) {
  const bgColorClass = type === ThresholdType.Alert ? 'bg-red-500' : 'bg-orange-400';

  return (
    <div className={`${bgColorClass} flex flex__jc--center flex__ai--center`}>
      <Icon name={`event-${type}`} size={IconSizeClasses.Small} />
    </div>
  );
}

export function createThresholdTileOk() {
  return (
    <div className="bg-green-500 flex flex__jc--center flex__ai--center">
      <Icon name="checkmark" size={IconSizeClasses.Small} className="bg-white" />
    </div>
  );
}

const thresholdFactory = (
  realtimeData: RealtimeData | undefined,
  type: ThresholdType,
  ThresholdValueComponent: React.FC<ThresholdValueLabelProps>,
): Threshold | undefined =>
  realtimeData
    ? {
        descriptionLabel: i18next.t(
          `alertThresholds.${getThresholdName(realtimeData) ?? 'unknown'}`,
        ),
        valueLabel: <ThresholdValueComponent realtimeData={realtimeData} />,
        component: createThresholdTile(type),
      }
    : undefined;

const createThresholdsWithoutSetpoint = (
  alertThresholds: AlertThresholds | undefined,
  thresholdValueCmp: React.FC<{ realtimeData: RealtimeData }>,
): Threshold[] =>
  [
    thresholdFactory(alertThresholds?.alertHigh, ThresholdType.Alert, thresholdValueCmp),
    thresholdFactory(alertThresholds?.warnHigh, ThresholdType.Warn, thresholdValueCmp),
    {
      component: createThresholdTileOk(),
      isReference: true,
    },
    thresholdFactory(alertThresholds?.warnLow, ThresholdType.Warn, thresholdValueCmp),
    thresholdFactory(alertThresholds?.alertLow, ThresholdType.Alert, thresholdValueCmp),
  ].filter(Boolean) as Threshold[];

const createThresholdsWithSetpoint = (
  alertThresholds: AlertThresholds | undefined,
  setPoint: Partial<RealtimeData> | undefined,
  ThresholdValueCmp: React.FC<ThresholdValueLabelProps>,
): Threshold[] =>
  [
    thresholdFactory(alertThresholds?.alertHigh, ThresholdType.Alert, ThresholdValueCmp),
    thresholdFactory(alertThresholds?.warnHigh, ThresholdType.Warn, ThresholdValueCmp),
    {
      component: createThresholdTileOk(),
    },

    setPoint
      ? {
          descriptionLabel: 'Set point',
          valueLabel: `${createValueElement(
            applyFractionDigits(setPoint.fractionalDigits),
            setPoint.valueType,
            setPoint.value,
          )} ${setPoint.unit}`,
          component: (
            <div className="flex flex__jc--center flex__ai--center h-4xs p-bottom-xl bg-gray-800 color-white" />
          ),
          isReference: true,
        }
      : undefined,

    {
      component: createThresholdTileOk(),
    },
    thresholdFactory(alertThresholds?.warnLow, ThresholdType.Warn, ThresholdValueCmp),
    thresholdFactory(alertThresholds?.alertLow, ThresholdType.Alert, ThresholdValueCmp),
  ].filter(Boolean) as Threshold[];

export const getThresholdSetpointsFactory =
  (
    alertThresholds: AlertThresholds,
    setPoint?: RealtimeData,
  ): ((editable: boolean) => Threshold[]) =>
  (editable: boolean) => {
    const thresholdValueComponent = editable
      ? ThresholdDynamicValueLabel
      : ThresholdStaticValueLabel;

    return setPoint
      ? createThresholdsWithSetpoint(alertThresholds, setPoint, thresholdValueComponent)
      : createThresholdsWithoutSetpoint(alertThresholds, thresholdValueComponent);
  };
