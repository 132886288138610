import { PaginationResponse } from '@eppendorf/vnls-inventory-service-types';
import {
  HasChildren,
  Pagination as PaginationLib,
} from '@eppendorf/vnls-react-components';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

const defaultPageSizes = [10, 25, 50, 100];

export interface PaginationProps extends HasChildren {
  paginationData: PaginationResponse;
  onPageSizeChange: (newSize: string) => void;
  onPageChange: (newPage: number) => void;
}

export function Pagination({
  paginationData,
  onPageSizeChange,
  onPageChange,
}: PaginationProps): ReactElement {
  const { t } = useTranslation();

  return (
    <PaginationLib
      selectLabel={t('pagination.pageSize')}
      itemRangeLabel={(startItem, endItem) =>
        t('pagination.itemRange', {
          startItem,
          endItem,
          totalItems: paginationData.totalItems,
        })
      }
      paginationData={{ ...paginationData, currentPage: paginationData.page }}
      pageSizeOptions={defaultPageSizes}
      onPageChange={onPageChange}
      onPageSizeChange={onPageSizeChange}
    />
  );
}
