import { Device } from '@eppendorf/vnls-inventory-service-types';
import { Device as RealtimeDevice } from '@eppendorf/vnls-telemetry-and-events-types';
import { useTranslation } from 'react-i18next';

export interface DeviceNameOrSerialNumberProps {
  device?: Device | RealtimeDevice;
}

export function DeviceNameOrSerialNumber({ device }: DeviceNameOrSerialNumberProps) {
  const { t } = useTranslation();

  if (!device) {
    return t('deviceDetail.fallbackHeadlineNameEmpty');
  }

  return device.name || device.serialNumber;
}
