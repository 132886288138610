import { Device } from '@eppendorf/vnls-inventory-service-types';
import { Button, Dialog, Icon, IconSizeClasses } from '@eppendorf/vnls-react-components';
import { FunctionComponentElement, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { useAutoFocus } from '$shared/custom-hooks/useAutoFocus';

import { DeviceNameCell } from '$components/device/name-cell';
import { UnlessSenseMonitor } from '$components/unless-sense-monitor';

export interface ErrorScreenProps {
  onSubmit?: () => void;
  device?: Device;
}

export function ErrorScreen({
  onSubmit,
  device,
}: ErrorScreenProps): FunctionComponentElement<ErrorScreenProps> {
  const { t } = useTranslation();
  const pairLaterButtonRef = useRef<null | HTMLButtonElement>(null);

  useAutoFocus(pairLaterButtonRef);

  return (
    <>
      <div className="flex flex__dir--column flex__ai--center p-l">
        <Icon
          name="info"
          className="bg-blue-500 m-bottom-xxl"
          size={IconSizeClasses.XLarge}
        />
        <Dialog.Title>{t('addDevice.addError')}</Dialog.Title>
        <div>
          <p>{t('addDevice.addErrorInfo')}</p>
          <div className="p-top-xxxl p-bottom-xxxl">
            {device && <DeviceNameCell device={device} />}
          </div>
          <p className="m-top-xl">
            <span>
              <UnlessSenseMonitor
                deviceModel={device?.model}
                fallback={t('addDevice.waitingInfoSenseMonitor')}
              >
                {t('addDevice.waitingInfo')}
              </UnlessSenseMonitor>
              {` ${t('errors.contactSupport')} `}
            </span>
            {/* TOOD: We dont have a Link/Anchor/.. component yet, see: https://github.com/eppendorf/vn-browser-libs/issues/202 */}
            <a
              href="https://www.eppendorf.com/service-support/"
              className="btn-base btn btn--tertiary btn--info btn--small font-weight-regular"
              target="_blank"
              style={{ display: 'inline', textDecoration: 'underline' }}
              rel="noreferrer"
            >
              {t('errors.contactSupport2')}
            </a>
          </p>
        </div>
      </div>
      <Dialog.Actions className="m-top-xxxl">
        <Dialog.Close>
          <Button variant="secondary">{t('shared.close')}</Button>
        </Dialog.Close>
        <Button onClick={onSubmit}>{t('shared.tryAgain')}</Button>
      </Dialog.Actions>
    </>
  );
}
