import React from 'react';
import ReactDOM from 'react-dom/client';

import { checkOneTrustAndInitializePendo } from '$shared/tracking/pendo';

import { AppWrapper } from 'src/app-wrapper';

import './main.scss';

import '$shared/amplify/amplify-configure';

checkOneTrustAndInitializePendo();

const prepareApp = async (): Promise<ServiceWorkerRegistration | undefined> => {
  if (import.meta.env.PUBLIC_ENABLE_MSW === 'true') {
    const { worker } = await import('src/mocks/msw-setup-browser');
    return worker.start();
  }

  return Promise.resolve(undefined);
};

prepareApp().then(() => {
  const root = document.getElementById('root');
  if (root) {
    ReactDOM.createRoot(root).render(
      <React.StrictMode>
        <AppWrapper />
      </React.StrictMode>,
    );
  }
});
