import {
  Button,
  Checkbox,
  Dialog,
  Icon,
  IconSizeClasses,
} from '@eppendorf/vnls-react-components';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import '../../../../main.scss';

import { getEventIconName } from '$features/monitoring/events/events.utils';
import { AddNotificationRuleScreenProps, Severity } from '$features/notifications/types';

export const severities: Severity[] = [
  {
    name: 'error',
  },
  {
    name: 'alert',
  },
  {
    name: 'warning',
  },
  {
    name: 'info',
  },
];

interface SeverityListItemProps {
  severity: Severity;
  selected: boolean;
  onSelect: (severity: Severity) => void;
}

function SeverityListItem({ severity, selected, onSelect }: SeverityListItemProps) {
  const { name } = severity;
  const { t } = useTranslation();
  return (
    <p className="flex flex__ac--flex-start cursor--pointer">
      <Checkbox
        checked={selected}
        onChange={() => onSelect(severity)}
        ariaLabel={severity.name}
        className="p-m"
      />
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions -- clickable area */}
      <span onClick={() => onSelect(severity)} onKeyDown={() => onSelect(severity)}>
        <Icon
          name={`event-${getEventIconName(name)}`}
          size={IconSizeClasses.XSmall}
          key={`tag-${name}`}
          className="m-x-m p-x-m"
        />
      </span>
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions -- clickable area */}
      <span
        className="p-top-xxs"
        onClick={() => onSelect(severity)}
        onKeyDown={() => onSelect(severity)}
      >
        <strong className="font-weight-bold">
          {t(`notificationTags.${name}` as const)}{' '}
        </strong>
        {t(`notificationTags.${name}Message` as const)}
      </span>
    </p>
  );
}

interface SeverityListProps extends AddNotificationRuleScreenProps {
  onBack: () => void;
  onContinue: () => void;
  screenHasErrors: boolean;
}

function SeverityList({
  formControl,
  onBack,
  onContinue,
  screenHasErrors,
}: SeverityListProps) {
  const { t } = useTranslation();
  const { watch, setValue, register } = formControl;

  useEffect(() => {
    register('tags', {
      validate: (tags) => tags?.length > 0,
    });
    formControl.trigger('tags');
  }, [register]);

  const handleSeveritySelect = useCallback(
    (severity: Severity) => {
      const tags = watch('tags') || [];
      if (tags.includes(severity.name)) {
        setValue(
          'tags',
          tags.filter((tag) => tag !== severity.name),
          { shouldDirty: true },
        );
      } else {
        setValue('tags', [...tags, severity.name], {
          shouldValidate: true,
          shouldDirty: true,
        });
      }
    },
    [watch, setValue],
  );

  return (
    <>
      <h1 className="sub-title p-top-xxl p-bottom-xxl">{t('tagsCondition.select')}</h1>
      <p className="p-bottom-xxl">
        <span>{t('tagsCondition.notify')}</span>
        <strong className="font-weight-bold">{`${t('tagsCondition.events')} `}</strong>
        <span>{t('tagsCondition.within')}</span>
        <strong className="font-weight-bold">{t('tagsCondition.severity')}</strong>
      </p>
      <div className="flex flex__dir--column gap-xl">
        {severities.map((severity) => (
          <SeverityListItem
            key={severity.name}
            severity={severity}
            onSelect={handleSeveritySelect}
            selected={watch('tags')?.includes(severity.name)}
          />
        ))}
      </div>

      <Dialog.Actions>
        <Button variant="secondary" onClick={onBack}>
          {t('shared.back')}
        </Button>

        <Button onClick={onContinue} disabled={screenHasErrors}>
          {t('shared.continue')}
        </Button>
      </Dialog.Actions>
    </>
  );
}

interface ConditionScreenProps extends AddNotificationRuleScreenProps {
  onBack: () => void;
  onContinue: () => void;
  screenHasErrors: boolean;
}

export function ConditionScreen({
  formControl,
  onBack,
  onContinue,
  screenHasErrors,
}: ConditionScreenProps) {
  return (
    <SeverityList
      formControl={formControl}
      onBack={onBack}
      onContinue={onContinue}
      screenHasErrors={screenHasErrors}
    />
  );
}
