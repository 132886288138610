import { HasChildren } from '@eppendorf/vnls-react-components';
import React from 'react';

import { FeatureToggle } from '$shared/feature-toggle/types';

import { useGetFeatureToggle } from '$components/app-config/app-config.api';

interface FeatureIsEnabledProps extends HasChildren {
  feature: FeatureToggle;
  fallback?: React.ReactNode;
}

export function useFeatureIsEnabled(feature: FeatureToggle): boolean {
  const { data: featureList } = useGetFeatureToggle();

  return featureList?.includes(feature) ?? false;
}

export function FeatureIsEnabled({ feature, fallback, children }: FeatureIsEnabledProps) {
  const isEnabled = useFeatureIsEnabled(feature);
  return isEnabled ? children : (fallback ?? null);
}
