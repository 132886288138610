import { HasClassName } from '@eppendorf/vnls-react-components';
import cn from 'classnames';
import { ReactElement } from 'react';

import { createColorClassFor } from '$features/monitoring/parameters/utils';
import { createValueElement } from '$features/monitoring/shared/formatting.utils';

export interface MonitoringParameterValueProps extends HasClassName {
  value?: string;
  unit?: string | null;
  valueType?: string;
  status?: string;
  formatter: Intl.NumberFormat;
}

export function MonitoringParameterValue({
  value,
  valueType,
  unit,
  status,
  className,
  formatter,
}: MonitoringParameterValueProps): ReactElement {
  return (
    <div
      className={cn(className, {
        [createColorClassFor(status, 'color')]: status,
        'color-gray-900': !status,
      })}
    >
      {createValueElement(formatter, valueType, value)}
      {unit}
    </div>
  );
}
