import { Auth } from '@aws-amplify/auth';
import type { CognitoUserSession } from 'amazon-cognito-identity-js';

export async function refreshAuthorizationHeader(): Promise<string> {
  try {
    // this method will automatically refresh the accessToken and idToken if tokens are expired and a valid refreshToken presented
    const session: CognitoUserSession = await Auth.currentSession();
    const authHeaderValue = `Bearer ${session.getIdToken().getJwtToken()}`;
    return authHeaderValue;
  } catch (error) {
    // eslint-disable-next-line no-console -- maybe useful for debugging
    console.error(error);
    return Promise.reject(error);
  }
}
