import { DeviceTypes } from '@eppendorf/vnls-application-configuration-service-types';
import {
  type Device,
  type RealtimeData,
  UnitState,
} from '@eppendorf/vnls-telemetry-and-events-types';
import { useMemo } from 'react';

import { useDeviceTypeConfig } from '$components/app-config/useDeviceTypeConfig';
import { useDeviceParameterAccessor } from '$features/monitoring/hooks/use-device-parameter-accessor';
import {
  buildAlertConfig,
  findBySubjectAndComponent,
  alertThresholdsAvailable,
  getDefaultAlertThresholds,
} from '$features/monitoring/monitoring.utils';
import { useUpdateThresholds } from '$features/monitoring/parameters/alerting.api';
import { MonitoringParameter } from '$features/monitoring/parameters/monitoring-parameter';
import { MonitoringParameterOffline } from '$features/monitoring/parameters/monitoring-parameter-offline';
import { getThresholdSetpointsFactory } from '$features/monitoring/parameters/threshold-configs';
import type { ThresholdsFormProfile } from '$features/monitoring/parameters/thresholds-form';
import type { AlertThresholds } from '$features/monitoring/parameters/types/alert-thresholds';

interface Props {
  telemetry: RealtimeData;
  device: Device;
  invalidateDeviceQuery: () => Promise<Device>;
}

export function MonitoringParameterWrapper({
  device,
  telemetry,
  invalidateDeviceQuery,
}: Props) {
  const { mutateAsync } = useUpdateThresholds(device.serialNumber, invalidateDeviceQuery);
  const parameterAccessor = useDeviceParameterAccessor(device);

  const handleSubmitThresholds = (
    telemetryId: string,
    thresholds: ThresholdsFormProfile,
  ): Promise<AlertThresholds> => mutateAsync({ telemetryId, thresholds });

  const params = useMemo(
    () => buildAlertConfig(telemetry.subject, device.realtime),
    [device.realtime, telemetry.subject],
  );

  const config = useDeviceTypeConfig(device);

  const isSensor = config?.type === DeviceTypes.MONITOR;

  const online =
    findBySubjectAndComponent(device?.realtime, 'Unit', 'unitState')?.value !==
    UnitState.Offline.toString();

  if (isSensor && !alertThresholdsAvailable(params.alertThresholds)) {
    params.alertThresholds = getDefaultAlertThresholds(telemetry);
  }

  return online ? (
    <MonitoringParameter
      key={telemetry.subject}
      name={telemetry.name}
      setPoint={params.setPoint}
      processValue={params.processValue}
      alertStatus={params.alertStatus}
      alertThresholds={params.alertThresholds}
      fractionalDigits={telemetry.fractionalDigits}
      isSensor={isSensor}
      parameterConfig={parameterAccessor(telemetry)}
      computeThresholds={getThresholdSetpointsFactory(
        params.alertThresholds,
        params.setPoint,
      )}
      onSubmitThresholds={(formValues) =>
        handleSubmitThresholds(telemetry.subject, formValues)
      }
    />
  ) : (
    <MonitoringParameterOffline
      key={telemetry.subject}
      name={telemetry.name}
      parameterConfig={parameterAccessor(telemetry)}
    />
  );
}
