import { ReactElement } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';

import { DeviceDetail } from '$features/devices/device-detail';
import { useGetDevice } from '$features/devices/devices.api';

export function DeviceDetailWrapper(): ReactElement {
  const [searchParams] = useSearchParams();

  const { data } = useGetDevice({
    manufacturer: searchParams.get('manufacturer') ?? '',
    serialNumber: searchParams.get('serialNumber') ?? '',
  });

  const methods = useForm({
    defaultValues: data,
    reValidateMode: 'onChange',
    mode: 'onSubmit',
  });

  return (
    /* eslint-disable-next-line react/jsx-props-no-spreading -- usage according to offical react-hook-form docs */
    <FormProvider {...methods}>
      <DeviceDetail />
    </FormProvider>
  );
}
