import {
  Button,
  Input,
  Label,
  ValidationMessage,
} from '@eppendorf/vnls-react-components';
import { User, UserRole } from '@eppendorf/vnls-user-tenant-utils';
import { Dispatch, ReactElement, SetStateAction, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { AccessControlGuard } from '$shared/access-control/access-control-guard';

import { CancelEditDialog } from '$components/cancel-edit-dialog/cancel-edit-dialog';
import { FeatureIsEnabled } from '$components/feature-toogle/feature-is-enabled';
import { RolesDropdown } from '$features/edit-user/roles-dropdown/roles-dropdown';
import { EditUserForm } from '$features/show-user/form-wrapper';
import { stringifyUserRoles } from '$features/users/roles.utils';
import { useUpdateUser } from '$features/users/users.api';

export interface EditViewProps {
  onEditViewCancel: () => void;
  onRoleChange?: (role: UserRole) => void;
  dialogState: [boolean, Dispatch<SetStateAction<boolean>>];
  user: User;
  isCurrentUser: boolean;
}

export function EditView({
  onEditViewCancel,
  dialogState: [isCancelDialogOpen, setIsCancelDialogOpen],
  user,
  isCurrentUser,
}: EditViewProps): ReactElement {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors: formErrors, isSubmitted, isValid },
  } = useFormContext();

  const handleRoleChange = (newRole: UserRole) => {
    setValue('roles', [newRole], { shouldDirty: true });
  };
  const {
    mutateAsync,
    error: updateUserError,
    isLoading: isUpdateUserLoading,
    isSuccess: isUpdateUserSuccess,
  } = useUpdateUser();

  function resetToReadView(): void {
    reset(user);
    setIsCancelDialogOpen(false);
    onEditViewCancel();
  }

  function resetToEditView(): void {
    setIsCancelDialogOpen(false);
  }

  useEffect(() => {
    setValue('roles', user.roles || []);

    if (updateUserError) {
      resetToEditView();
    }

    if (isUpdateUserSuccess) {
      resetToReadView();
    }

    if (isCancelDialogOpen && isUpdateUserSuccess) {
      resetToEditView();
      navigate(-1);
    }
  }, [isUpdateUserSuccess, updateUserError, isCancelDialogOpen, user, setValue]);

  useEffect(() => {
    if (formErrors.firstName || formErrors.lastName) {
      resetToEditView();
    }
  }, [formErrors.firstName, formErrors.lastName]);

  async function handleFormSubmit(formData: EditUserForm): Promise<void> {
    if (!user || !formData || !formData.roles) {
      throw new Error('Invalid formData');
    }

    try {
      await mutateAsync({
        id: user.id,
        firstName: formData.firstName,
        lastName: formData.lastName,
        roles: isCurrentUser ? undefined : formData.roles,
      });
    } catch {
      // eslint-disable-next-line no-useless-return -- disables test error output
      return;
    }
  }

  function handleDialogOnOpenChange(): void {
    resetToEditView();
  }

  function handleDialogDiscard(): void {
    resetToReadView();
    navigate(-1);
  }

  function handleDialogSaveChanges(): void {
    handleSubmit(handleFormSubmit)();
  }

  const readOnlyRoles = (
    <p className="font-weight-bold m-bottom-xs">{stringifyUserRoles(user.roles || [])}</p>
  );

  return (
    <>
      {user && (
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <div className="m-bottom-m">
            <Label htmlFor="firstName">{t('userManagement.firstName')}*</Label>
            <Input
              invalid={!!formErrors.firstName}
              className="m-top-xs"
              id="firstName"
              disabled={isUpdateUserLoading}
              type="text"
              // eslint-disable-next-line react/jsx-props-no-spreading -- spread is needed for react-hook-form
              {...register('firstName', {
                required: true,
                maxLength: 120,
              })}
            />
            {formErrors.firstName?.type === 'required' && (
              <ValidationMessage>{t('validationMessages.required')}</ValidationMessage>
            )}
            {formErrors.firstName?.type === 'maxLength' && (
              <ValidationMessage>
                {t('validationMessages.maxLength120')}
              </ValidationMessage>
            )}
          </div>

          <div className="m-bottom-m">
            <Label htmlFor="lastName">{t('userManagement.lastName')}*</Label>
            <Input
              invalid={!!formErrors.lastName}
              className="m-top-xs"
              id="lastName"
              disabled={isUpdateUserLoading}
              type="text"
              // eslint-disable-next-line react/jsx-props-no-spreading -- spread is needed for react-hook-form
              {...register('lastName', {
                required: true,
                maxLength: 120,
              })}
            />
            {formErrors.lastName?.type === 'required' && (
              <ValidationMessage>{t('validationMessages.required')}</ValidationMessage>
            )}
            {formErrors.lastName?.type === 'maxLength' && (
              <ValidationMessage>
                {t('validationMessages.maxLength120')}
              </ValidationMessage>
            )}
          </div>

          <h3 className="m-bottom-xs">{t('shared.email')}</h3>
          <p className="font-weight-bold m-bottom-xs">{user.email}</p>
          <h3 className="m-bottom-l color-gray-800">{t('userManagement.emailNote')}</h3>

          <h3 className="m-bottom-xs">{t('userManagement.role')}</h3>

          <FeatureIsEnabled feature="editRole" fallback={readOnlyRoles}>
            {isCurrentUser ? (
              <>
                {readOnlyRoles}
                <h3 className="m-bottom-xs color-gray-800">
                  {t('userManagement.contactAdmin')}
                </h3>
              </>
            ) : (
              <AccessControlGuard
                requiredPermissions="UPDATE_OTHERS_ROLE"
                fallbackComponent={readOnlyRoles}
              >
                <RolesDropdown
                  disabled={isUpdateUserLoading}
                  currentRole={watch('roles')?.[0]}
                  onRoleChange={handleRoleChange}
                />
                <h3 className="m-bottom-l p-top-s color-gray-800">
                  {t('userManagement.roleUpdate5MinNotice')}
                </h3>
              </AccessControlGuard>
            )}
          </FeatureIsEnabled>

          {updateUserError && (
            <ValidationMessage className="m-bottom-m">
              {t('errors.unexpected')}
            </ValidationMessage>
          )}

          <div className="dialog-content__actions-wrapper m-top-xxl">
            <Button
              onClick={() => {
                resetToReadView();
              }}
              variant="secondary"
              size="small"
              disabled={isUpdateUserLoading}
            >
              {t('shared.cancel')}
            </Button>
            <Button
              type="submit"
              size="small"
              disabled={
                (isSubmitted && !isValid) ||
                isUpdateUserLoading ||
                !!Object.keys(formErrors).length
              }
            >
              {t('shared.save')}
            </Button>
          </div>
        </form>
      )}

      <CancelEditDialog
        isOpen={isCancelDialogOpen}
        onOpenChange={() => handleDialogOnOpenChange()}
        onDiscardChanges={() => handleDialogDiscard()}
        onSaveChanges={() => handleDialogSaveChanges()}
        isLoading={isUpdateUserLoading}
      />
    </>
  );
}
