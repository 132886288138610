import { useEffect, useState } from 'react';

import { buildUserId } from '$shared/auth/build-user-id';

export function useCurrentUserId(email?: string, tenantId?: string): string | undefined {
  const [currentUserId, setCurrentUserId] = useState<string>();

  useEffect(() => {
    const buildUserIdWrapper = async () => {
      if (!email || !tenantId) {
        setCurrentUserId(undefined);
        return;
      }

      const hashHex = await buildUserId(email, tenantId);

      setCurrentUserId(hashHex);
    };

    buildUserIdWrapper();
  }, [email, tenantId]);

  return currentUserId;
}
