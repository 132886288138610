export function retryFunc<T>(
  func: () => Promise<T>,
  maxRetries: number,
  delay: number,
): Promise<T> {
  return new Promise((resolve, reject) => {
    let retries = 0;

    async function attempt() {
      try {
        const res = await func();
        if (res) {
          resolve(res);
        } else if (retries < maxRetries) {
          retries += 1;
          setTimeout(attempt, delay);
        } else {
          reject(new Error('Exceeded maximum retries'));
        }
      } catch (error) {
        if (retries < maxRetries) {
          retries += 1;
          setTimeout(attempt, delay);
        } else {
          reject(new Error('Exceeded maximum retries'));
        }
      }
    }

    attempt();
  });
}
