import { useTranslation } from 'react-i18next';
import { useRouteError } from 'react-router-dom';

type Error = { statusText?: string; message?: string };

function createErrorText(error: unknown): string {
  if (!error || typeof error !== 'object') {
    return '';
  }

  return (error as Error).statusText || (error as Error).message || '';
}

export function ErrorPage() {
  const error: unknown = useRouteError();
  const { t } = useTranslation();

  return (
    <div id="error-page">
      <h1>{t('errors.oops')}</h1>
      <p>{t('errors.unexpected')}</p>
      <p>
        <i>{createErrorText(error)}</i>
      </p>
    </div>
  );
}
