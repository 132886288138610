import { AlertThresholds } from './alert-thresholds';

export type AlertThresholdKey = Exclude<keyof AlertThresholds, 'enabled' | 'alertState'>;

export const AlertThresholdKeys: AlertThresholdKey[] = [
  'alertHigh',
  'alertLow',
  'warnHigh',
  'warnLow',
];
