import { AlertState } from "@eppendorf/vnls-telemetry-and-events-types";


export function getTitleByStatus(status?: AlertState) {
  switch (status) {
    case AlertState.Alert:
      return 'Alert';
    case AlertState.Warn:
      return 'Warning';
    default:
      return 'Ok';
  }
}
