// eslint-disable-next-line eslint-comments/disable-enable-pair -- !
/* eslint-disable @typescript-eslint/no-explicit-any -- ! */
import { Input, ValidationMessage } from '@eppendorf/vnls-react-components';
import cn from 'classnames';
import { FieldValues, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import styles from './info-item.module.scss';

type ValueOf<T> = T[keyof T];

export function EditInfoItem<T extends FieldValues>({
  label,
  id,
}: {
  label: string;
  id: ValueOf<T>;
}) {
  const { t } = useTranslation();

  const {
    register,
    formState: { errors: formErrors },
  } = useFormContext<T>();

  return (
    <div className="flex m-bottom-l flex__ai--center">
      <div className={cn('body-bold', styles.label)}>{label}</div>
      <div className={cn('color-gray-900', 'body-regular', styles.value)}>
        <Input
          type="string"
          id={id}
          // eslint-disable-next-line security/detect-object-injection -- this is safe
          invalid={!!formErrors[id]}
          className="w-max-xxl"
          // eslint-disable-next-line react/jsx-props-no-spreading -- It is safe to spread register
          {...register(id, { maxLength: 120 })}
        />
        {
          // eslint-disable-next-line security/detect-object-injection -- !
          formErrors[id]?.type === 'maxLength' && (
            <ValidationMessage>{t('validationMessages.maxLength120')}</ValidationMessage>
          )
        }
      </div>
    </div>
  );
}
