import {
  type HasClassName,
  Icon,
  IconSizeClasses,
  Tooltip,
} from '@eppendorf/vnls-react-components';
import type { ReactElement } from 'react';

import { capitalize, decapitalize } from '$shared/utils/string.utils';

export interface DoorOrLidStatusProps extends HasClassName {
  state?: string;
  subject: 'door' | 'lid';
  fallback?: string;
}

export function DoorOrLidStatus({
  state,
  subject,
  fallback,
  className,
}: DoorOrLidStatusProps): ReactElement | undefined | string {
  function getIconByStatus(status?: string) {
    switch (status?.toLocaleLowerCase()) {
      case 'opened':
        return <Icon name={`${subject}-open`} size={IconSizeClasses.XSmall} />;
      case 'locked':
        return <Icon name="lock-locked" size={IconSizeClasses.XSmall} />;
      case 'unlocked':
        return <Icon name="lock-unlocked" size={IconSizeClasses.XSmall} />;
      case 'closed':
        return <Icon name={`${subject}-closed`} size={IconSizeClasses.XSmall} />;
      default:
        return undefined;
    }
  }

  return state ? (
    <Tooltip
      trigger={
        <div className={`inline-block w-max-xxs flex flex__jc--center ${className}`}>
          {getIconByStatus(state)}
        </div>
      }
    >
      {`${capitalize(subject)} ${decapitalize(state)}`}
    </Tooltip>
  ) : (
    fallback
  );
}
