import { UnitState } from '@eppendorf/vnls-telemetry-and-events-types';

import i18next from '$shared/i18n/i18n';

export function getActivityStatusText(activityStatus: UnitState | null): string {
  switch (activityStatus) {
    case UnitState.AutomaticallyRunning:
      return i18next.t('translation:activityStatus.automaticallyRunning');
    case UnitState.Done:
      return i18next.t('translation:activityStatus.done');
    case UnitState.Error:
      return i18next.t('translation:activityStatus.error');
    case UnitState.Idle:
      return i18next.t('translation:activityStatus.idle');
    case UnitState.ManuallyRunning:
      return i18next.t('translation:activityStatus.manuallyRunning');
    case UnitState.Paused:
      return i18next.t('translation:activityStatus.paused');
    case UnitState.Offline:
      return i18next.t('translation:activityStatus.offline');
    case UnitState.Online:
      return i18next.t('translation:activityStatus.online');
    case UnitState.Hold:
      return i18next.t('translation:activityStatus.hold');
    case UnitState.Running:
      return i18next.t('translation:activityStatus.running');
    case UnitState.Unreachable:
      return i18next.t('translation:activityStatus.unreachable');
    case UnitState.Preparing:
      return i18next.t('translation:activityStatus.preparing');
    default:
      return '';
  }
}
