import cn from 'classnames';
import React from 'react';

import styles from './info-item.module.scss';

export function InfoItem({
  label,
  value = '-',
}: {
  label: string;
  value?: React.ReactNode;
}) {
  return (
    <div className="flex m-bottom-l flex__ai--center">
      <div className={cn('font-weight-bold', styles.label)}>{label}</div>
      <div className={cn('color-gray-900', 'body-regular', styles.value)}>{value}</div>
    </div>
  );
}
