import { NotificationRule } from '@eppendorf/vnls-notification-service-types';
import { FunctionComponentElement, useState } from 'react';

import { EditNotificationRuleDialog } from '$features/notifications/add-or-edit-notification-rule-dialog';
import { NotificationRuleDetail } from '$features/notifications/detail/notification-detail';
import { NotificationRuleDetailData } from '$features/notifications/types';

import useFetchDevices from './useFetchDevices';

export interface NotificationRuleDetailWrapperProps {
  data: NotificationRule;
}

export function NotificationRuleDetailWrapper({
  data,
}: NotificationRuleDetailWrapperProps): FunctionComponentElement<NotificationRuleDetailWrapperProps> {
  const filteredSubjects = data.subjects.filter((subject) => subject.type === 'device');
  const { devices, errors } = useFetchDevices(filteredSubjects);
  const notificationRuleData: NotificationRuleDetailData = {
    ...data,
    ...{ devices: { details: devices, errors } },
  };

  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);

  function handleEditDialogOpen(): void {
    setIsEditDialogOpen(true);
  }

  return (
    <>
      <NotificationRuleDetail
        data={notificationRuleData}
        onEditDialogOpen={() => handleEditDialogOpen()}
      />

      {/* Needed ro probably reset the Component */}
      {isEditDialogOpen && (
        <EditNotificationRuleDialog
          data={notificationRuleData!}
          isOpen={isEditDialogOpen}
          onOpenChange={(open) => setIsEditDialogOpen(open)}
        />
      )}
    </>
  );
}
