import { DropdownMenu, Icon, IconSizeClasses } from '@eppendorf/vnls-react-components';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useAuthContext } from '$shared/auth/use-auth-context';
import { useCurrentUserId } from '$shared/custom-hooks/useCurrentUserId';
import { useTokenAttributes } from '$shared/custom-hooks/useTokenAttributes';

import { stringifyUserRoles } from '$features/users/roles.utils';
import { useGetUser } from '$features/users/users.api';

export function Avatar(): ReactElement {
  const { signOut } = useAuthContext();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { email: emailFromToken, tenantId } = useTokenAttributes(['email', 'tenantId']);
  const currentUserId = useCurrentUserId(emailFromToken, tenantId);
  const { data } = useGetUser(currentUserId || '');
  const { firstName, lastName, email, id, roles } = data || {};
  const userRoles = stringifyUserRoles(roles || []);

  const userNameDisplay = firstName && lastName ? `${firstName} ${lastName}` : email;
  const avatarTextDisplay =
    firstName && lastName
      ? firstName.slice(0, 1) + lastName.slice(0, 1)
      : email?.slice(0, 2);
  return (
    <DropdownMenu
      sideOffset={14}
      trigger={
        <div className="avatar avatar--small" data-testid="dropdown-menu-trigger">
          {avatarTextDisplay}
        </div>
      }
    >
      <DropdownMenu.Title>
        <div className="flex p-right-l">
          <div className="avatar avatar--medium">{avatarTextDisplay}</div>
          <div className="m-left-m">
            <p className="font-weight-bold">{userNameDisplay}</p>
            <p>{userRoles}</p>
          </div>
        </div>
      </DropdownMenu.Title>
      <DropdownMenu.Item
        onClick={() => {
          navigate({
            search: `?sidecarRoute=user&id=${id}`,
          });
        }}
      >
        <div className="flex flex__ai--center">
          <Icon name="user" size={IconSizeClasses.XSmall} className="m-right-s" />
          <p>{t('userManagement.profile')}</p>
        </div>
      </DropdownMenu.Item>
      <DropdownMenu.Item onClick={signOut}>
        <div className="flex flex__ai--center">
          <Icon name="logout" size={IconSizeClasses.XSmall} className="m-right-s" />
          <p>{t('shared.logout')}</p>
        </div>
      </DropdownMenu.Item>
    </DropdownMenu>
  );
}
