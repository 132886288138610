import { RecipientSubject } from '@eppendorf/vnls-notification-service-types';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

export function NotificationDetailRecipient({
  recipients,
}: {
  recipients: RecipientSubject[] | null;
}): ReactElement {
  const { t } = useTranslation();

  return (
    <div className="recipients row p-top-xl">
      <div className="col-3">
        <h3 className="body-bold">{t('notificationDetail.recipients')}</h3>
      </div>
      <div className="recipients-list col-9 ">
        {recipients &&
          recipients.map((recipient, index) => (
            <div
              key={recipient.name}
              className={index === recipients.length - 1 ? '' : 'p-bottom-s'}
            >
              {recipient.name}
            </div>
          ))}
      </div>
    </div>
  );
}
