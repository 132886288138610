import { PermissionsType } from '@eppendorf/vnls-user-tenant-utils';
import { ReactNode } from 'react';

import { useAccessControlGuard } from '$shared/custom-hooks/useAccessControlGuard';

interface AccessControlGuardProps {
  requiredPermissions: PermissionsType | PermissionsType[];
  fallbackComponent?: ReactNode;
  children: ReactNode;
}

export function AccessControlGuard({
  requiredPermissions,
  fallbackComponent,
  children,
}: AccessControlGuardProps): ReactNode {
  const hasPermissions = useAccessControlGuard(requiredPermissions);

  if (!hasPermissions) {
    return fallbackComponent && fallbackComponent;
  }
  return children;
}
