import {
  PaginatedResponse,
  User,
  UserCreateRequestBody,
  UsersQueryParams,
  UserUpdateRequestBody,
} from '@eppendorf/vnls-user-tenant-utils';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';

import { fetcher } from '$shared/fetch';
import {
  createPathWithQueryParams,
  createUserPath,
  objectToURLSearchParams,
  USERS_BASE_PATH,
} from '$shared/utils/api-paths';

export const userQueryKey = 'users';

export const createUsersQueryWithQueryParams = (queryParams: UsersQueryParams) => ({
  queryKey: [userQueryKey, ...objectToURLSearchParams(queryParams).values()],
  queryFn: async () =>
    fetcher.get<PaginatedResponse<User>>(
      createPathWithQueryParams(USERS_BASE_PATH, queryParams),
    ),
  keepPreviousData: true,
});
export const useUsersWithQueryParams = (queryParams: UsersQueryParams) =>
  useQuery(createUsersQueryWithQueryParams(queryParams));

export const createGetUserQuery = (id: string) => ({
  queryKey: [userQueryKey, id],
  queryFn: async () => fetcher.get<User>(createUserPath(id)),
  enabled: !!id,
});
export const useGetUser = (id: string) => useQuery(createGetUserQuery(id));

export const useAddUser = () => {
  const addUser = (user: UserCreateRequestBody) =>
    fetcher.post<UserCreateRequestBody, User>(USERS_BASE_PATH, user);

  const queryClient = useQueryClient();
  return useMutation<AxiosResponse<User>, AxiosError, UserCreateRequestBody>(addUser, {
    onSuccess: () => {
      queryClient.invalidateQueries([userQueryKey]);
    },
  });
};

export const useUpdateUser = () => {
  const updateUser = (user: UserUpdateRequestBody & Pick<User, 'id'>) => {
    const { id, ...userWithoutId } = user;
    return fetcher.patch<UserUpdateRequestBody, User>(createUserPath(id), userWithoutId);
  };

  const queryClient = useQueryClient();
  return useMutation<User, AxiosError, UserUpdateRequestBody & Pick<User, 'id'>>(
    updateUser,
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries([userQueryKey]);

        queryClient.setQueryData([userQueryKey, data.id], data);
      },
    },
  );
};

export const useDeleteUser = () => {
  const deleteUser = (id: string) => fetcher.delete(`${USERS_BASE_PATH}/${id}`);
  const queryClient = useQueryClient();
  return useMutation<AxiosResponse, AxiosError, string>(deleteUser, {
    onSuccess: () => {
      queryClient.invalidateQueries([userQueryKey]);
    },
  });
};

export const useResendInvite = () => {
  const updateInviteSendAt = (id: string) =>
    fetcher.post<unknown, User>(
      `${USERS_BASE_PATH}/${id}/actions/resend-invite`,
      undefined,
    );

  const queryClient = useQueryClient();
  return useMutation<AxiosResponse<User>, AxiosError, string>(updateInviteSendAt, {
    onSuccess: (data) => {
      queryClient.invalidateQueries([userQueryKey]);

      queryClient.setQueryData([userQueryKey, data.data.id], data);
    },
    onError: (error) => {
      // eslint-disable-next-line no-console -- for debugging
      console.log(error);
    },
  });
};
