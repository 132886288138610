import { createLicensesPath } from '$shared/utils/api-paths';

import { LicensesMap } from '$features/licenses/license';

import { environment } from 'src/environment';

export const createLicensesQuery = async (): Promise<LicensesMap> => {
  const data = await fetch(environment.apiUrl + createLicensesPath());

  return (await data.json()) as LicensesMap;
};
