import { ValidationMessage } from '@eppendorf/vnls-react-components';
import cn from 'classnames';
import { FieldValues, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

type ValueOf<T> = T[keyof T];

export function EditInfoItemTextarea<T extends FieldValues>({
  id,
  maxLength = 2500,
}: {
  id: ValueOf<T>;
  maxLength?: number;
}) {
  const { t } = useTranslation();
  const {
    register,
    watch,
    formState: { errors: formErrors },
  } = useFormContext<T>();
  /* eslint-disable-next-line security/detect-object-injection -- ! */
  const formError = formErrors[id];

  return (
    <div className="m-bottom-l">
      <div
        className={cn(
          'p-x-s',
          'p-y-s',
          'w-full',
          'color-gray-900',
          'body-regular',
          'border-radius-s',
          'border-s',
          'border-solid',
          formError ? 'border-color-red-500' : 'border-color-gray-500',
        )}
      >
        <textarea
          className="w-full"
          rows={20}
          maxLength={maxLength}
          // eslint-disable-next-line react/jsx-props-no-spreading -- It is safe to spread register
          {...register(id, {
            maxLength,
          })}
        />
      </div>

      <p
        className={cn('m-y-s', formError ? 'color-red-500' : 'color-gray-700')}
      >{`${watch(id)?.length || 0}/${maxLength}`}</p>

      {formError?.type === 'maxLength' && (
        <ValidationMessage>{t('validationMessages.maxLength2500')}</ValidationMessage>
      )}
    </div>
  );
}
