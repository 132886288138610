import { NotificationRule } from '@eppendorf/vnls-notification-service-types';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { getFormatedLocalizedDate } from '$shared/utils/date.utils';

export function NotificationDetailCreatedInfo({
  data,
}: {
  data: NotificationRule | null;
}): ReactElement {
  const { t } = useTranslation();
  const createdBy = data?.createdBy;
  const createdAt = data?.createdAt ? getFormatedLocalizedDate(data?.createdAt) : '';

  return (
    <div className="createdBy row p-top-xl">
      <div className="col-3">
        <h3 className="body-bold">{t('notificationDetail.createdBy')}</h3>
      </div>
      <div className="createdBy-name col-9 ">
        {createdBy && (
          <div className="recipient-name">{`by ${createdBy} on ${createdAt}`}</div>
        )}
      </div>
    </div>
  );
}
