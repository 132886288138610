import { Device } from '@eppendorf/vnls-telemetry-and-events-types';
import { useMutation } from '@tanstack/react-query';

import { fetcher } from '$shared/fetch';
import { createMonitorPath } from '$shared/utils/api-paths';

import { buildAlertConfig, parseBoolean } from '$features/monitoring/monitoring.utils';
import { ThresholdsFormProfile } from '$features/monitoring/parameters/thresholds-form';
import {
  AlertConfig,
  AlertConfigThresholdLevel,
  AlertConfigThresholdType,
  AlertThresholdKeys,
} from '$features/monitoring/parameters/types';
import { AlertThresholds } from '$features/monitoring/parameters/types/alert-thresholds';

type AlertConfigUpdateResult = {
  //
};

type UpdateThresholdsPayload = {
  telemetryId: string;
  thresholds: ThresholdsFormProfile;
};

export const mapThresholdsToAlertConfig = (
  thresholds: ThresholdsFormProfile,
): AlertConfig => ({
  active: thresholds.active,
  thresholds: [
    {
      type: AlertConfigThresholdType.Alert,
      level: AlertConfigThresholdLevel.High,
      value: +thresholds.alertHigh,
    },
    {
      type: AlertConfigThresholdType.Alert,
      level: AlertConfigThresholdLevel.Low,
      value: +thresholds.alertLow,
    },
    {
      type: AlertConfigThresholdType.Warn,
      level: AlertConfigThresholdLevel.High,
      value: +thresholds.warnHigh,
    },
    {
      type: AlertConfigThresholdType.Warn,
      level: AlertConfigThresholdLevel.Low,
      value: +thresholds.warnLow,
    },
  ],
});

export const useUpdateThresholds = (
  monitorId: string,
  invalidate: () => Promise<Device>,
) => {
  const retryDelay = 1000;
  const totalRetryCount = 10;

  const valuesUpdated = async (
    { telemetryId, thresholds }: UpdateThresholdsPayload,
    // eslint-disable-next-line consistent-return -- this is a recursive function
  ): Promise<AlertThresholds | undefined> => {
    const deviceData = await invalidate();
    const config = buildAlertConfig(telemetryId, deviceData.realtime);

    if (
      thresholds.active === !!parseBoolean(config.alertThresholds.enabled?.value) &&
      AlertThresholdKeys.every(
        (key) =>
          thresholds[key as keyof ThresholdsFormProfile].toString() ===
          config.alertThresholds[key as keyof AlertThresholds]?.value,
      )
    ) {
      return config.alertThresholds;
    }
  };

  const update = async ({ telemetryId, thresholds }: UpdateThresholdsPayload) => {
    await fetcher.put<AlertConfig, AlertConfigUpdateResult>(
      createMonitorPath(monitorId, `alertConfig/${encodeURI(telemetryId)}`),
      mapThresholdsToAlertConfig(thresholds),
    );

    return new Promise<AlertThresholds>((resolve, reject) => {
      const retry = (payload: UpdateThresholdsPayload, retryCount: number) => {
        // eslint-disable-next-line consistent-return -- this is a recursive function
        valuesUpdated(payload).then((newThresholds) => {
          if (newThresholds) {
            return resolve(newThresholds);
          }

          if (retryCount < 1) {
            return reject();
          }

          setTimeout(
            retry,
            retryDelay * (totalRetryCount + 1 - retryCount),
            payload,
            retryCount - 1,
          );
        });
      };
      retry({ telemetryId, thresholds }, totalRetryCount);
    });
  };

  return useMutation(update);
};
