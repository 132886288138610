import {
  Button,
  Dialog,
  Input,
  Label,
  ValidationMessage,
} from '@eppendorf/vnls-react-components';
import { UserCreateRequestBody } from '@eppendorf/vnls-user-tenant-utils';
import { FunctionComponentElement } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useAddUser } from '$features/users/users.api';

export interface InviteScreenProps {
  onSubmit: (invitedUser: InviteUserForm) => void;
}

export type InviteUserForm = UserCreateRequestBody;

export function InviteScreen({
  onSubmit,
}: InviteScreenProps): FunctionComponentElement<InviteScreenProps> {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors: formErrors, isValid, isSubmitted },
  } = useForm<InviteUserForm>();

  const { mutateAsync, isError, error, isLoading, reset } = useAddUser();

  async function handleFormSubmit(form: InviteUserForm): Promise<void> {
    try {
      await mutateAsync({ ...form });
    } catch {
      return;
    }

    onSubmit(form);
  }

  return (
    <>
      <Dialog.Title>{t('userInvite.inviteUser')}</Dialog.Title>
      <Dialog.Description>{t('userInvite.descriptionInviteUser')}</Dialog.Description>

      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <div className="m-bottom-m">
          <Label htmlFor="firstName">{t('userManagement.firstName')}*</Label>
          <Input
            invalid={!!formErrors.firstName}
            className="m-top-xs"
            id="firstName"
            type="text"
            disabled={isLoading}
            // eslint-disable-next-line react/jsx-props-no-spreading -- spread is needed for react-hook-form
            {...register('firstName', {
              required: true,
            })}
          />
          {formErrors.firstName?.type === 'required' && (
            <ValidationMessage>{t('validationMessages.required')}</ValidationMessage>
          )}
        </div>
        <div className="m-bottom-m">
          <Label htmlFor="lastName">{t('userManagement.lastName')}*</Label>
          <Input
            invalid={!!formErrors.lastName}
            className="m-top-xs"
            id="lastName"
            type="text"
            disabled={isLoading}
            // eslint-disable-next-line react/jsx-props-no-spreading -- spread is needed for react-hook-form
            {...register('lastName', {
              required: true,
            })}
          />
          {formErrors.lastName?.type === 'required' && (
            <ValidationMessage>{t('validationMessages.required')}</ValidationMessage>
          )}
        </div>
        <div className="m-bottom-m">
          <Label htmlFor="email">{t('shared.email')}*</Label>
          <Input
            invalid={!!formErrors.email || isError}
            className="m-top-xs"
            id="email"
            type="text"
            disabled={isLoading}
            // eslint-disable-next-line react/jsx-props-no-spreading -- spread is needed for react-hook-form
            {...register('email', {
              required: true,
              maxLength: 120,
              // eslint-disable-next-line regexp/no-super-linear-backtracking -- the implementation used seems to be fine and anyway it would only slow down the user's browser
              pattern: /^\S+@\S+\.\S{2,}$/,
              setValueAs: (v) => v.trim(),
              onChange: reset,
            })}
          />
          {!formErrors.email && !isError && (
            <p className="color-gray-700 font-size-s">
              {t('userInvite.descriptionEmailMessageCannotBeChanged')}
            </p>
          )}
          {isError && error.response?.status === 409 && (
            <ValidationMessage>
              {t('userInvite.errorMessageUniqueEMail')}
            </ValidationMessage>
          )}
          {formErrors.email?.type === 'required' && (
            <ValidationMessage>{t('validationMessages.required')}</ValidationMessage>
          )}
          {formErrors.email?.type === 'maxLength' && (
            <ValidationMessage>
              {t('userInvite.errorMessageEmailMaxLength')}
            </ValidationMessage>
          )}
          {formErrors.email?.type === 'pattern' && (
            <ValidationMessage>
              {t('userInvite.errorMessageEmailWrongFormat')}
            </ValidationMessage>
          )}
        </div>

        {isError && error.response?.status !== 409 && (
          <ValidationMessage className="m-bottom-m">
            {t('errors.unexpected')}
          </ValidationMessage>
        )}
        <Dialog.Actions>
          <Dialog.Close>
            <Button variant="secondary">{t('shared.cancel')}</Button>
          </Dialog.Close>
          <Button
            type="submit"
            className="btn--solid"
            disabled={(isSubmitted && !isValid) || isLoading || isError}
          >
            {t('userInvite.sendInvite')}
          </Button>
        </Dialog.Actions>
      </form>
    </>
  );
}
