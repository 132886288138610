import { DeviceParameterConfig } from '@eppendorf/vnls-application-configuration-service-types';
import { DotsLoader } from '@eppendorf/vnls-react-components';
import _get from 'lodash.get';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { LoadingErrorHint } from '$components/loading-error-hint/loading-error-hint';
import {
  ChartLegend,
  ChartLegendParam,
} from '$features/monitoring/history-charts/components/Legend';
import { NoChartDataAvailable } from '$features/monitoring/history-charts/components/no-data-available/no-chart-data-available';
import { HistoryChart } from '$features/monitoring/history-charts/history-chart/history-chart';
import { useHistoryChartsQuery } from '$features/monitoring/history-charts/monitoring.history-charts.graphql.api';
import { useHistoryChartsConfig } from '$features/monitoring/history-charts/useHistoryChartsConfig';
import { ParameterHeadline } from '$features/monitoring/shared/parameter-headline';

type ChartWrapperProps = {
  subject: string;
  parameterConfig: DeviceParameterConfig;
  manufacturer: string;
  serialNumber: string;
  rangePeriod: string;
  from: number | null;
  to: number | null;
  minBucketStep: number | null;
};

export function ChartWrapper({
  subject,
  parameterConfig,
  manufacturer,
  serialNumber,
  rangePeriod,
  from,
  to,
  minBucketStep,
}: ChartWrapperProps) {
  const { t } = useTranslation();
  const { data, isLoading, isError } = useHistoryChartsQuery(
    manufacturer || '',
    serialNumber || '',
    subject || '',
    rangePeriod,
    from,
    to,
    minBucketStep,
    parameterConfig.chartType,
  );

  const hasDataPointsFor = useCallback(
    (param: 'alertHigh' | 'alertLow' | 'setPoint') => {
      if (!data) {
        return false;
      }
      return data.buckets.some((chunk) => !!_get(chunk, param, null));
    },
    [data],
  );

  const { chartColors, showThresholdRefs } = useHistoryChartsConfig();
  const legendParameters: Array<ChartLegendParam> = [
    {
      value: t('monitoringDetail.historyChartsDetail.highLowAlertIndicator'),
      color: chartColors.alertHighLowColor,
      show:
        showThresholdRefs &&
        (hasDataPointsFor('alertHigh') || hasDataPointsFor('alertLow')),
    },
    {
      value: t('monitoringDetail.historyChartsDetail.setPointIndicator'),
      color: chartColors.setPointColor,
      show: showThresholdRefs && hasDataPointsFor('setPoint'),
    },
    {
      value: t('monitoringDetail.historyChartsDetail.processValueIndicator'),
      color: chartColors.processValueColor,
      show: true,
    },
  ];
  const isDataExist = data?.buckets.some(
    (b) => b.alertHigh || b.alertLow || b.setPoint || b.processValue,
  );

  const parameterName = data?.name || parameterConfig?.name;

  return (
    <div className="m-bottom-7xl p" data-testid="chart-wrapper" key={data?.subject}>
      {parameterName && (
        <div className="flex flex__ai--center m-y-l m-x-xxxs flex__jc--space-between">
          <ParameterHeadline parameterName={parameterName} unit={data?.unit} />
          <ChartLegend>
            {legendParameters.map(
              (param) =>
                param.show && (
                  <ChartLegend.Parameter
                    key={param.value}
                    value={param.value}
                    color={param.color}
                  />
                ),
            )}
          </ChartLegend>
        </div>
      )}
      {isLoading && <DotsLoader className="m-top-10xl m-bottom-10xl" />}
      {isError && <LoadingErrorHint />}
      {data && !isDataExist && <NoChartDataAvailable />}
      {!isError && isDataExist && <HistoryChart historicalData={data} />}
    </div>
  );
}
