import { Auth } from '@aws-amplify/auth';
import { HasChildren } from '@eppendorf/vnls-react-components';
import React, { useEffect } from 'react';

import { useAuthContext } from '$shared/auth/use-auth-context';

import { Loader } from '$components/loader/loader';

interface Props {
  autoSignIn?: boolean;
  fallback?: React.ReactNode;
}

export function AuthGuard({
  children,
  autoSignIn,
  fallback,
}: HasChildren & Props): React.ReactNode {
  const { isLoading, isAuthenticated } = useAuthContext();

  useEffect(() => {
    const signIn = async () => {
      await Auth.federatedSignIn();
    };

    if (isAuthenticated === false && autoSignIn) signIn();
  }, [isAuthenticated]);

  if (isLoading) {
    return <Loader />;
  }

  return isAuthenticated ? children : fallback ?? '';
}
