import { DotsLoader, Sidecar } from '@eppendorf/vnls-react-components';
import { User } from '@eppendorf/vnls-user-tenant-utils';
import { ReactElement, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useRouteError } from 'react-router-dom';

import { useCurrentUserId } from '$shared/custom-hooks/useCurrentUserId';
import { useTokenAttributes } from '$shared/custom-hooks/useTokenAttributes';

import { LoadingErrorHint } from '$components/loading-error-hint/loading-error-hint';
import { EditView } from '$features/edit-user/edit-view';
import { ReadView } from '$features/show-user/read-view';
import { getStatusDisplay } from '$features/users-overview/users-overview';

export function ShowUserContent({
  isLoading,
  isError,
  user,
}: {
  isLoading: boolean;
  isError: boolean;
  user?: User;
}): ReactElement {
  const routeError = useRouteError();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [isEditMode, setIsEditMode] = useState(false);
  const [isSidecarOpen, setIsSidecarOpen] = useState(true);
  const [isCancelDialogOpen, setIsCancelDialogOpen] = useState(false);

  const { email, tenantId } = useTokenAttributes(['email', 'tenantId']);
  const currentUserId = useCurrentUserId(email, tenantId);
  const { formState } = useFormContext();

  function closeSidecar(): void {
    setIsSidecarOpen(false);
    navigate({
      search: '',
    });
  }

  function handleSidecarOpenChange(): void {
    if (Object.keys(formState.dirtyFields).length > 0) {
      setIsCancelDialogOpen(true);
      return;
    }

    closeSidecar();
  }

  function handleEditViewCancel(): void {
    setIsEditMode(false);
  }

  const statusBadge = user?.status ? getStatusDisplay(user?.status, true) : null;
  const isCurrentUser = currentUserId === user?.id;
  const titleName = isCurrentUser
    ? t('userDetail.myProfile')
    : `${user?.firstName} ${user?.lastName}`;

  return (
    <Sidecar isOpen={isSidecarOpen} onOpenChange={() => handleSidecarOpenChange()}>
      {isLoading && !routeError && <DotsLoader className="m-top-xl" />}

      {routeError || isError ? (
        <LoadingErrorHint className="m-top-xl" />
      ) : (
        !isLoading && (
          <>
            <Sidecar.Title>
              <div className="flex flex__ai--center m-right-m m-bottom-xxxl">
                <div className="m-right-l">
                  <h2 className="title">{titleName}</h2>
                </div>
                <div className="m-right-l">{statusBadge}</div>
              </div>
            </Sidecar.Title>
            <div className="box">
              {!isEditMode && (
                <ReadView user={user!} onEdit={() => setIsEditMode(true)} />
              )}

              {isEditMode && (
                <EditView
                  user={user!}
                  isCurrentUser={isCurrentUser}
                  onEditViewCancel={() => handleEditViewCancel()}
                  dialogState={[isCancelDialogOpen, setIsCancelDialogOpen]}
                />
              )}
            </div>
          </>
        )
      )}
    </Sidecar>
  );
}
