import { Auth } from '@aws-amplify/auth';
import { HasChildren } from '@eppendorf/vnls-react-components';
import type { CognitoUserSession } from 'amazon-cognito-identity-js';
import { useState, useEffect, useMemo } from 'react';

import { AuthContext, AuthContextValue } from '$shared/auth/auth-context';
import { redirectToLogin } from '$shared/fetch';

import { Loader } from '$components/loader/loader';

export function AuthContextProvider({ children }: HasChildren) {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    if (isAuthenticated) {
      return;
    }

    async function checkUserSession() {
      try {
        const session: CognitoUserSession = await Auth.currentSession();
        setIsAuthenticated(!!session);
      } catch (_) {
        setIsAuthenticated(false);
      }
    }

    checkUserSession().catch((error) => {
      // eslint-disable-next-line no-console -- maybe useful for debugging
      console.error(error);
    });
  }, []);

  const value: AuthContextValue = useMemo(
    () => ({
      isAuthenticated: !!isAuthenticated,
      isLoading: isAuthenticated === undefined,
      signIn: redirectToLogin,
      signOut: async () => {
        setIsAuthenticated(undefined);
        await Auth.signOut();
      },
    }),
    [isAuthenticated],
  );

  if (value.isLoading) {
    return <Loader />;
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
