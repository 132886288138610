import { Dialog, DialogProps } from '@eppendorf/vnls-react-components';
import { FunctionComponentElement, useEffect, useState } from 'react';

import {
  InviteUserDialogContent,
  UserInviteScreens,
} from '$features/invite-user/invite-user-dialog/invite-user-dialog-content';

export interface InviteUserDialogWrapperProps {
  isOpen?: boolean;
  onOpenChange?: (open: boolean) => void;
  trigger?: DialogProps['trigger'];
}

export function InviteUserDialogWrapper({
  isOpen = false,
  onOpenChange,
  trigger,
}: InviteUserDialogWrapperProps): FunctionComponentElement<InviteUserDialogWrapperProps> {
  function handleOpenChange(open: boolean): void {
    if (onOpenChange) onOpenChange(open);
  }
  const [activeScreen, setActiveScreen] = useState<UserInviteScreens>(
    UserInviteScreens.InviteScreen,
  );

  function handleScreenChange(screen: UserInviteScreens): void {
    setActiveScreen(screen);
  }

  function resetDialog(): void {
    setActiveScreen(UserInviteScreens.InviteScreen);
  }

  useEffect(() => resetDialog(), [isOpen]);

  return (
    <Dialog
      isOpen={isOpen}
      onOpenChange={(open) => handleOpenChange(open)}
      trigger={trigger}
      className="w-max-4xl"
      hideCloseButton={activeScreen === UserInviteScreens.SuccessScreen}
    >
      <InviteUserDialogContent
        activeScreen={activeScreen}
        setActiveScreen={(screen: UserInviteScreens) => handleScreenChange(screen)}
      />
    </Dialog>
  );
}
