import { Event as DeviceEvent } from '@eppendorf/vnls-notification-service-types';
import { ForwardedRef, FunctionComponentElement, forwardRef, useCallback } from 'react';
import { Virtuoso, VirtuosoHandle } from 'react-virtuoso';

import { EventRow } from '$features/monitoring/events/components/event-row';

type VirtualListProps = {
  data: DeviceEvent[];
  loadMore: () => void;
  isScrolling?: (value: boolean) => void;
};

export const VirtualList = forwardRef(
  (
    { data, loadMore, isScrolling }: VirtualListProps,
    ref: ForwardedRef<VirtuosoHandle>,
  ): FunctionComponentElement<VirtualListProps> => {
    const itemContent = useCallback(
      (_: number, deviceEvent: DeviceEvent) => (
        <EventRow key={deviceEvent.sourceEventId} deviceEvent={deviceEvent} />
      ),
      [],
    );

    return (
      <Virtuoso
        data={data}
        endReached={loadMore}
        overscan={200}
        style={{ height: '100%' }}
        itemContent={itemContent}
        ref={ref}
        isScrolling={(value) => isScrolling && isScrolling(value)}
      />
    );
  },
);
