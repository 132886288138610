import { NotificationRule } from '@eppendorf/vnls-notification-service-types';
import { QueryClient } from '@tanstack/react-query';
import { ReactElement } from 'react';

import { prepopulateCache } from '$shared/utils/react-query.utils';

import { NotificationDetailSidecar } from '$features/notifications/detail/notifications-detail-sidecar';
import { createGetNotificationRuleByIdQuery } from '$features/notifications/notification-rules.api';

export async function notificationRuleDetailLoader(
  qClient: QueryClient,
  notificationId: string,
): Promise<null> {
  return prepopulateCache<NotificationRule>(
    qClient,
    createGetNotificationRuleByIdQuery(notificationId),
  );
}

export function NotificationRuleDetailPage(): ReactElement {
  return <NotificationDetailSidecar />;
}
