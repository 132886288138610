import { IconsDeviceBattery } from '@eppendorf/vnls-css/dist';
import { Icon } from '@eppendorf/vnls-react-components';

interface BatteryLevelProps {
  soc?: number;
  charging?: boolean;
  className?: string;
}

function calcBatteryLevelIcon(
  charging: boolean,
  soc?: number,
): IconsDeviceBattery | null {
  if (soc === null || soc === undefined) return null;
  if (soc > 60) return charging ? 'battery-charging-full' : 'battery-full';
  if (soc > 40) return charging ? 'battery-charging-half' : 'battery-half';
  if (soc > 10) return charging ? 'battery-charging-warning' : 'battery-warning';
  if (soc > 0) return charging ? 'battery-charging-alert' : 'battery-alert';
  return charging ? 'battery-charging-empty' : 'battery-empty';
}

export function BatteryStatus({ soc, charging, className }: BatteryLevelProps) {
  const batteryLevel = calcBatteryLevelIcon(charging ?? false, soc);
  if (!batteryLevel) return null;

  return (
    <div className={`flex flex__ai--center ${className}`}>
      <Icon name={batteryLevel} />
      {soc}%
    </div>
  );
}
