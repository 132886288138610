import { ReactElement } from 'react';

import { AuthGuard } from '$shared/auth/auth-guard';

import { Avatar } from '$components/header/avatar';

export function Header(): ReactElement {
  return (
    <header className="flex flex__jc--flex-end p-y-m p-x-xxxl border-bottom-s border-solid border-color-gray-300 bg-white">
      <AuthGuard>
        <Avatar />
      </AuthGuard>
    </header>
  );
}
