import { Button, Dialog, Icon, IconSizeClasses } from '@eppendorf/vnls-react-components';
import { FunctionComponentElement, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { useAutoFocus } from '$shared/custom-hooks/useAutoFocus';

export interface ErrorDialogRetryProps {
  onTryAgain: () => void;
  title: string;
  message: string;
}

export function ErrorDialogRetry({
  onTryAgain,
  title,
  message,
}: ErrorDialogRetryProps): FunctionComponentElement<ErrorDialogRetryProps> {
  const { t } = useTranslation();
  const closeButtonRef = useRef<null | HTMLButtonElement>(null);
  const tryAgainButtonRef = useRef<null | HTMLButtonElement>(null);

  useAutoFocus(closeButtonRef);
  return (
    <>
      <div className="flex flex__dir--column flex__ai--center text-align-center">
        <Icon
          name="failed"
          className="bg-red-500 m-bottom-xxl"
          size={IconSizeClasses.XLarge}
        />
        <Dialog.Title>{title}</Dialog.Title>
        <Dialog.Description className="m-bottom-xxs">{message}</Dialog.Description>
        <p className="m-top-xl">
          <span>{t('errors.contactSupport')} </span>

          {/* TOOD: We dont have a Link/Anchor/.. component yet, see: https://github.com/eppendorf/vn-browser-libs/issues/202 */}
          <a
            href="https://www.eppendorf.com/service-support/"
            className="btn-base btn btn--tertiary btn--info btn--small font-weight-regular"
            target="_blank"
            style={{ display: 'inline', textDecoration: 'underline' }}
            rel="noreferrer"
          >
            {t('errors.contactSupport2')}
          </a>
        </p>
      </div>
      <Dialog.Actions className="m-top-xxl">
        <Button ref={tryAgainButtonRef} onClick={onTryAgain} variant="secondary">
          {t('shared.tryAgain')}
        </Button>

        <Dialog.Close>
          <Button ref={closeButtonRef} className="btn--solid">
            {t('shared.close')}
          </Button>
        </Dialog.Close>
      </Dialog.Actions>
    </>
  );
}
