import { HasChildren } from '@eppendorf/vnls-react-components';
import { RealtimeData, UnitState } from '@eppendorf/vnls-telemetry-and-events-types';

import { findBySubjectAndComponent } from '$features/monitoring/monitoring.utils';

interface UnlessOfflineProps extends HasChildren {
  realtimeArray: (RealtimeData | null)[];
  noFallback?: boolean;
}

export function UnlessOffline({
  realtimeArray,
  children,
  noFallback = false,
}: UnlessOfflineProps) {
  const status = findBySubjectAndComponent(realtimeArray, 'Unit', 'unitState');

  if (status?.value === UnitState.Offline.toString()) {
    return <div>{noFallback ? '' : '-'}</div>;
  }

  return children;
}
