import { Button, Checkbox } from '@eppendorf/vnls-react-components';
import { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAuthContext } from '$shared/auth/use-auth-context';

import { MasterLayout } from '../master-layout/master-layout';

export function SetupAccountPage(): ReactElement {
  const { signIn } = useAuthContext();
  const { t } = useTranslation();

  const policyLink = import.meta.env.PUBLIC_POLICY_LINK;

  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  return (
    <MasterLayout>
      <p className="m-bottom-xxl font-size-m font-weight-regular">
        {t('landing.setUpAccountHint')}
      </p>
      <form>
        <label className="flex flex__ai--center gap-m">
          <Checkbox
            checked={isChecked}
            onChange={handleCheckboxChange}
            className="checkbox w-4xs h-4xs"
          />
          <p className="font-size-s font-weight-regular">
            {t('landing.policyHint')}{' '}
            <a href={policyLink} className="color-blue-500">
              {t('landing.policyLink')}
            </a>
          </p>
        </label>
        <Button
          onClick={signIn}
          type="button"
          disabled={!isChecked}
          variant="primary"
          className="m-top-xxl m-bottom-xxl w-full"
        >
          {t('landing.setUpButton')}
        </Button>
      </form>
    </MasterLayout>
  );
}
