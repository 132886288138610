import { FunctionComponentElement } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import {
  ConfirmDialogIconType,
  ConfirmationDialog,
} from '$components/confirmation-dialog/confirmation-dialog';
import {
  NotificationRuleMode,
  NotificationRuleSubmitForm,
} from '$features/notifications/types';

export interface SuccessScreenProps {
  mode: NotificationRuleMode;
  notiRule?: NotificationRuleSubmitForm;
}

export function SuccessScreen({
  mode,
  notiRule,
}: SuccessScreenProps): FunctionComponentElement<SuccessScreenProps> {
  const { t } = useTranslation();

  return (
    <>
      {mode === NotificationRuleMode.CREATE && (
        <ConfirmationDialog
          title={t('notificationRules.addSuccessHeadline')}
          iconType={ConfirmDialogIconType.success}
          dialogDescription={
            <Trans
              i18nKey="notificationRules.addSuccessMessage"
              values={{
                name: notiRule?.notificationRuleName,
              }}
              components={{ bold: <span className="font-weight-bold" /> }}
            />
          }
        />
      )}
      {mode === NotificationRuleMode.EDIT && (
        <ConfirmationDialog
          title={t('notificationRules.editSuccessHeadline')}
          iconType={ConfirmDialogIconType.success}
          dialogDescription={
            <>
              <Trans
                i18nKey="notificationRules.editSuccessMessage"
                values={{
                  name: notiRule?.notificationRuleName,
                }}
                components={{ bold: <span className="font-weight-bold" /> }}
              />

              <p className="m-top-m">
                <Trans
                  i18nKey="notificationRules.editSuccessHint"
                  components={{ bold: <span className="font-weight-bold" /> }}
                />
              </p>
            </>
          }
        />
      )}
    </>
  );
}
