import {
  DeviceModels,
  DeviceTypeConfig,
} from '@eppendorf/vnls-application-configuration-service-types';
import { Device as InventoryDevice } from '@eppendorf/vnls-inventory-service-types';
import { Device as RealtimeDevice } from '@eppendorf/vnls-telemetry-and-events-types';

import { useGetDeviceTypeConfigs } from './app-config.api';

/**
 * Return the DeviceTypeConfig for a given device
 * @param device The device information
 * @returns The DeviceTypeConfig for the given model name or null if no DeviceTypeConfig is found
 */
export function useDeviceTypeConfig(
  device?: InventoryDevice | RealtimeDevice | null,
): DeviceTypeConfig<DeviceModels> | null {
  const { data: deviceTypeConfigs } = useGetDeviceTypeConfigs();

  if (!device || !deviceTypeConfigs) {
    return null;
  }

  return (
    deviceTypeConfigs.find(
      (config) =>
        config.typeIds?.includes?.(device.type as string) ??
        config.models.includes(device.model as DeviceModels),
    ) ?? null
  );
}
