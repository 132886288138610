import { DevicesGetResponse } from '@eppendorf/vnls-inventory-service-types';
import { QueryClient } from '@tanstack/react-query';
import { Outlet, useRouteError } from 'react-router-dom';

import { prepopulateCache } from '$shared/utils/react-query.utils';

import { DeviceList } from '$features/device-list';
import { createDevicesQueryWithQueryParams } from '$features/devices/devices.api';

export async function devicesLoader(queryClient: QueryClient): Promise<null> {
  return prepopulateCache<DevicesGetResponse>(
    queryClient,
    createDevicesQueryWithQueryParams({ page: 1, pageSize: 10 }),
  );
}

export function DevicesPage() {
  const routeError = useRouteError();
  return (
    <>
      <DeviceList />
      {!routeError && <Outlet />}
    </>
  );
}
