import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { I18nextProvider } from 'react-i18next';
import { RouterProvider } from 'react-router-dom';

import { AuthContextProvider } from '$shared/auth/auth-context-provider';
import { useAuthContext } from '$shared/auth/use-auth-context';
import i18next from '$shared/i18n/i18n';
import { queryClient } from '$shared/query/query-client';

import { createRouter } from 'src/router';

function AppRouterProvider() {
  const { isAuthenticated } = useAuthContext();

  return <RouterProvider router={createRouter(queryClient, isAuthenticated)} />;
}

export function AppWrapper() {
  return (
    <I18nextProvider i18n={i18next}>
      <QueryClientProvider client={queryClient}>
        <AuthContextProvider>
          <AppRouterProvider />

          {import.meta.env.PUBLIC_ENABLE_REACT_QUERY_DEVTOOLS === 'true' && (
            <ReactQueryDevtools initialIsOpen={false} />
          )}
        </AuthContextProvider>
      </QueryClientProvider>
    </I18nextProvider>
  );
}
