import {
  Threshold,
  Switch,
  Thresholds,
  Button,
  DotsLoader,
} from '@eppendorf/vnls-react-components';
import { AlertState, RealtimeData } from '@eppendorf/vnls-telemetry-and-events-types';
import cn from 'classnames';
import { t } from 'i18next';
import { FormProvider } from 'react-hook-form';

import { AlertStatus } from '$features/monitoring/alert-status/alert-status';
import { parseBoolean } from '$features/monitoring/monitoring.utils';
import {
  createColorClassFor,
  getThresholdWarnText,
} from '$features/monitoring/parameters/utils';
import {
  createValueElement,
  applyFractionDigits,
} from '$features/monitoring/shared/formatting.utils';

import { MonitoringParameterFormErrors } from './monitoring-parameter-form-errors';
import { ThresholdsFormOptions, useThresholdsForm } from './use-thresholds-form';

import './thresholds-form.scss';

interface Props {
  isSensor: boolean;
  alertStatus?: AlertState;
  processValue?: Partial<RealtimeData>;
  computeThresholds: (editable: boolean) => Threshold[];
}

export function ThresholdsForm({
  isSensor,
  alertStatus,
  processValue,
  alertThresholds,
  computeThresholds,
  onSubmitThresholds,
}: Props & ThresholdsFormOptions) {
  const {
    form,
    isEditing,
    canSubmit,
    isSubmitting,
    alertThresholdsEnabled,
    handleAlertThresholdsToggle,
    handleReset,
    handleEdit,
    handleSubmit,
  } = useThresholdsForm({ alertThresholds, onSubmitThresholds });

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading -- FormProvider is used to provide the form context
    <FormProvider {...form}>
      <div className="thresholds-edit-form p-xxxl">
        <div
          className={cn(
            'm-top-xxl m-bottom-4xl flex flex__ai--center flex__jc--space-between border-solid border-left-m border-radius-s bg-white p-xxl select--full-width',
            {
              [createColorClassFor(alertStatus, 'border-color')]: alertStatus,
              'color-gray-900': !alertStatus,
            },
          )}
        >
          <span className="flex flex__ai--center">
            <AlertStatus alertStatus={alertStatus} className="m-right-m" />
            <div>
              {getThresholdWarnText(
                !!parseBoolean(alertThresholds?.enabled?.value),
                alertStatus,
                processValue?.timestamp,
              )}
            </div>
          </span>
          <div
            className={cn('font-size-4xl font-weight-bold', {
              [createColorClassFor(alertStatus, 'color')]: alertStatus,
              'color-gray-900': !alertStatus,
            })}
          >
            {createValueElement(
              applyFractionDigits(processValue?.fractionalDigits),
              processValue?.valueType,
              processValue?.value,
            )}
            {processValue?.unit}
          </div>
        </div>
        {!isSensor && (
          <div className="m-top-xl m-bottom-xl">{t('alertThresholds.alertHint')}</div>
        )}
        <form
          className="flex flex-wrap flex__ai--flex-end"
          onSubmit={handleSubmit}
          onReset={handleReset}
        >
          {isSubmitting ? (
            <div className="flex flex__dir--column flex__ai--center gap-m flex__jc--center flex-grow">
              <DotsLoader />
              <p>{t('alertThresholds.loading')}</p>
              <p>{t('alertThresholds.loadingHint')}</p>
            </div>
          ) : (
            <fieldset className="flex flex-wrap flex-grow">
              <div className="flex flex__jc--space-between flex__dir--row">
                <div className="font-size-m font-weight-bold m-top-m m-bottom-m">
                  {t('alertThresholds.parameterAlert')}
                </div>
                <Switch
                  disabled={!isEditing}
                  checked={alertThresholdsEnabled}
                  onCheckedChange={handleAlertThresholdsToggle}
                />
              </div>
              <div className="flex gap-m flex__ai--center flex__dir--column flex__jc--center select--full-width">
                <Thresholds
                  className="w-full"
                  disabled={!isEditing && !alertThresholdsEnabled}
                  thresholds={computeThresholds(isEditing)}
                />

                {isSensor && <MonitoringParameterFormErrors />}

                {isSensor && (
                  <div className="thresholds-form-actions">
                    {!isEditing && (
                      <Button size="small" onClick={handleEdit}>
                        {t('shared.edit')}
                      </Button>
                    )}

                    {isEditing && (
                      <div className="flex gap-m">
                        <Button size="small" variant="secondary" type="reset">
                          {t('shared.cancel')}
                        </Button>
                        <Button disabled={!canSubmit} size="small" type="submit">
                          {t('shared.save')}
                        </Button>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </fieldset>
          )}
        </form>
      </div>
    </FormProvider>
  );
}
