import { Button } from '@eppendorf/vnls-react-components';
import { FunctionComponentElement } from 'react';
import { useTranslation } from 'react-i18next';

export interface NotificationDetailEditButtonProps {
  onEditDialogOpen: () => void;
}

export function NotificationDetailEditButton({
  onEditDialogOpen,
}: NotificationDetailEditButtonProps): FunctionComponentElement<NotificationDetailEditButtonProps> {
  const { t } = useTranslation();

  return (
    <div className="m-top-xxl flex flex__jc--flex-end">
      <Button size="small" onClick={() => onEditDialogOpen()}>
        {t('shared.edit')}
      </Button>
    </div>
  );
}
