import { Select } from '@eppendorf/vnls-react-components';
import { UserRole } from '@eppendorf/vnls-user-tenant-utils';
import { FunctionComponentElement } from 'react';

import { stringifyUserRoles } from '$features/users/roles.utils';

interface RolesDropdownProps {
  currentRole: UserRole;
  onRoleChange: (role: UserRole) => void;
  disabled?: boolean;
}

export function RolesDropdown({
  currentRole,
  onRoleChange,
  disabled,
}: RolesDropdownProps): FunctionComponentElement<RolesDropdownProps> {
  const handleItemClick = (item: string) => {
    const roleItem = item as UserRole;
    onRoleChange(roleItem);
  };

  const electiveRoles = [UserRole.LabAdmin, UserRole.LabMember];

  const selectOptions = electiveRoles.map((role) => ({
    value: role,
    label: stringifyUserRoles([role]),
  }));

  return (
    <Select
      disabled={disabled}
      value={currentRole}
      placeholder={stringifyUserRoles([currentRole])}
      onChange={handleItemClick}
      options={selectOptions}
      ariaLabel="role"
    />
  );
}

export default RolesDropdown;
