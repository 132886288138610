export async function buildUserId(
  email?: string,
  tenantId?: string,
): Promise<string | undefined> {
  if (!email || !tenantId) return undefined;

  const encoder = new TextEncoder();
  const d = encoder.encode(`${tenantId}${email}`);

  const hashBuffer = await crypto.subtle.digest('SHA-256', d);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray.map((byte) => byte.toString(16).padStart(2, '0')).join('');

  return hashHex;
}
