import type { Device } from '@eppendorf/vnls-telemetry-and-events-types';
import type { ReactElement } from 'react';
import { useSearchParams } from 'react-router-dom';

import { BatteryStatus } from '$components/device/battery-status';
import { SignalStrengthIcon } from '$components/device/signal-strength-icon';
import { DoorOrLidStatusWrapper } from '$components/door-or-lid-status/door-or-lid-status-wrapper';
import { SidecarRouterOutlet } from '$components/sidecar-router/sidecar-router-outlet';
import { UnlessSenseMonitor } from '$components/unless-sense-monitor';
import { DeviceDetailHeader } from '$features/devices/device-detail-header';
import { useGetOperationalStatus } from '$features/devices/devices.api';
import { useDeviceViewConfig } from '$features/devices/devices.view.config';
import { ActivityStatusWrapper } from '$features/monitoring/activity-status';
import { UnitAlertState } from '$features/monitoring/alert-status/unit-alert-state';
import { MonitoringDetailNavigation } from '$features/monitoring/detail/monitoring-detail-navigation';
import { UnlessOffline } from '$features/monitoring/unless-offline';

export interface MonitoringDetailProps {
  data: Device;
}

export function MonitoringDetail({ data }: MonitoringDetailProps): ReactElement {
  const [searchParams] = useSearchParams();
  const viewConfig = useDeviceViewConfig(data);

  const { data: operationalStatus } = useGetOperationalStatus(
    searchParams.get('serialNumber') ?? '',
    viewConfig.showBattery,
  );

  return (
    <>
      <div
        className="flex flex__ai--center m-bottom-xxxl"
        data-testid="device-detail-header"
      >
        <DeviceDetailHeader className="m-right-m" device={data} />
        <ActivityStatusWrapper
          withDateWhenOffline
          bordered
          className="m-right-m"
          realtimeArray={data.realtime}
        />
        {viewConfig.showBattery && (
          <BatteryStatus
            soc={operationalStatus?.battery?.soc}
            charging={operationalStatus?.battery?.charging}
            className="m-right-m"
          />
        )}
        {viewConfig.showSignalStrength && (
          <SignalStrengthIcon
            signalStrength={operationalStatus?.signalStrength ?? null}
            className="m-right-m"
          />
        )}
        <UnlessSenseMonitor deviceModel={data.model} fallback="">
          <UnlessOffline realtimeArray={data.realtime} noFallback>
            <UnitAlertState
              className="m-right-m"
              realtimeArray={data.realtime}
              side="bottom"
            />
            <DoorOrLidStatusWrapper realtimeArray={data.realtime} fallback="" />
          </UnlessOffline>
        </UnlessSenseMonitor>
      </div>
      <div className="box flex__dir--column h-full overflow-hidden">
        <div className="box__header">
          <MonitoringDetailNavigation />
        </div>
        <SidecarRouterOutlet />
      </div>
    </>
  );
}
