import { Sidecar } from '@eppendorf/vnls-react-components';
import { ReactElement, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { LoadingErrorHint } from '$components/loading-error-hint/loading-error-hint';
import { MonitoringDetail } from '$features/monitoring/detail/monitoring-detail';
import { useRealtimeDevice } from '$features/monitoring/monitoring.graphql.api';

export function MonitoringDetailSidecar(): ReactElement {
  const [searchParams] = useSearchParams();

  const [isSidecarOpen, setIsSidecarOpen] = useState(true);
  const navigate = useNavigate();

  const { data } = useRealtimeDevice(
    searchParams.get('manufacturer')!,
    searchParams.get('serialNumber')!,
  );

  function handleSidecarOpenChange(): void {
    setIsSidecarOpen(false);
    navigate({
      search: '',
    });
  }

  return (
    <Sidecar isOpen={isSidecarOpen} onOpenChange={() => handleSidecarOpenChange()}>
      <div className="flex flex__dir--column flex__ai--stretch h-full">
        {!data && <LoadingErrorHint className="m-top-xl" />}
        {data && <MonitoringDetail data={data} />}
      </div>
    </Sidecar>
  );
}
