import { useSearchParams } from 'react-router-dom';

import { useSidecarRoute } from '$components/sidecar-router/sidecar-router-hooks';

export function SidecarRouterOutlet() {
  const route = useSidecarRoute();
  const [searchParams] = useSearchParams();

  const secondRoutePartial = searchParams.get('sidecarRoute')?.split('/')[1];

  return route?.children && route?.children[secondRoutePartial!]?.element;
}
