import { Dialog, DialogProps } from '@eppendorf/vnls-react-components';
import { FunctionComponentElement } from 'react';
import { useTranslation } from 'react-i18next';

import { NotificationRuleDialogContent } from '$features/notifications/add-or-edit-notification-rule-dialog/notification-rule-dialog-content';
import { SuccessScreen } from '$features/notifications/add-or-edit-notification-rule-dialog/screens/success-screen';
import { useNotificationRuleDialogForm } from '$features/notifications/add-or-edit-notification-rule-dialog/useNotificationRuleDialogForm';
import { NotificationRuleMode } from '$features/notifications/types';

export interface AddNotificationRuleDialogProps {
  isOpen?: boolean;
  onOpenChange: (open: boolean) => void;
  trigger?: DialogProps['trigger'];
}

export function AddNotificationRuleDialog({
  isOpen = false,
  onOpenChange,
  trigger,
}: AddNotificationRuleDialogProps): FunctionComponentElement<AddNotificationRuleDialogProps> {
  const { t } = useTranslation();
  const { handleFormSubmit, isLoading, requestError, isSuccess, formMethods } =
    useNotificationRuleDialogForm(undefined, NotificationRuleMode.CREATE);
  function handleOpenChange(open: boolean): void {
    if (onOpenChange) onOpenChange(open);
  }

  return (
    <Dialog
      isOpen={isOpen}
      onOpenChange={(open) => handleOpenChange(open)}
      trigger={trigger}
      className="w-max-7xl"
    >
      {!isSuccess && <Dialog.Title>{t('notificationRules.new')}</Dialog.Title>}
      {!isSuccess && (
        <NotificationRuleDialogContent
          handleFormSubmit={handleFormSubmit}
          isLoading={isLoading}
          requestError={requestError}
          formMethods={formMethods}
        />
      )}
      {isSuccess && (
        <SuccessScreen
          mode={NotificationRuleMode.CREATE}
          notiRule={formMethods.getValues()}
        />
      )}
    </Dialog>
  );
}
