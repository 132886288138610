import { FunctionComponentElement, useEffect, useState } from 'react';

import {
  AddOrEditNotiRuleModalScreens,
  AvailableScreenProps,
  NotificationRuleDialogContentProps,
  NotificationRuleSubmitForm,
} from '$features/notifications/types';

import {
  ConditionScreen,
  DevicesScreen,
  DeviceTypeScreen,
  ErrorDialog,
  NameScreen,
  RecipientsScreen,
} from './screens';

export function NotificationRuleDialogContent({
  handleFormSubmit,
  isLoading,
  requestError,
  formMethods,
  initialScreen: initialScreenProp,
  onScreenChange,
}: NotificationRuleDialogContentProps): FunctionComponentElement<NotificationRuleDialogContentProps> {
  const [screenHasErrors, setScreenHasErrors] = useState<boolean>(true);

  const notificationRuleScreens: Record<
    AddOrEditNotiRuleModalScreens,
    AvailableScreenProps
  > = {
    deviceTypeScreen: {
      key: 'deviceTypeScreen',
      fields: ['selectedDeviceType'],
    },
    devicesScreen: {
      key: 'devicesScreen',
      fields: ['devices'],
    },
    conditionScreen: {
      key: 'conditionScreen',
      fields: ['tags'],
    },
    recipientsScreen: {
      key: 'recipientsScreen',
      fields: ['recipients'],
    },
    nameScreen: {
      key: 'nameScreen',
      fields: ['notificationRuleName'],
    },
    successScreen: {
      key: 'successScreen',
      fields: [],
    },
    errorScreen: {
      key: 'errorScreen',
      fields: [],
    },
  };

  const initialScreen = initialScreenProp
    ? // eslint-disable-next-line security/detect-object-injection -- internal
      notificationRuleScreens[initialScreenProp]
    : notificationRuleScreens.deviceTypeScreen;

  const [activeScreen, setActiveScreen] = useState<AvailableScreenProps>(initialScreen);

  useEffect(() => {
    onScreenChange?.(activeScreen.key);
  }, [activeScreen]);

  function getNextScreen(): AvailableScreenProps | false {
    const entries = Object.entries(notificationRuleScreens);
    const currentStepIndex = entries.findIndex(([key]) => key === activeScreen.key);

    // If current step is the last step, return false
    if (currentStepIndex === entries.length - 1) {
      return false;
    }

    // Return the next step
    const next = entries[currentStepIndex + 1];
    return { ...next[1] };
  }

  const getPrevScreen = (): AvailableScreenProps | false => {
    const entries = Object.entries(notificationRuleScreens);
    const currentStepIndex = entries.findIndex(([key]) => key === activeScreen.key);

    // If current step is the first step, return false
    if (currentStepIndex === 0) {
      return false;
    }

    // Return the previous step
    const prev = entries[currentStepIndex - 1];
    return { ...prev[1] };
  };

  const handlePrevious = () => {
    const prevScreen = getPrevScreen();
    if (prevScreen) setActiveScreen(prevScreen);
  };

  const handleNext = () => {
    const nextScreen = getNextScreen();
    if (nextScreen) setActiveScreen(nextScreen);
  };

  // Check if screen has errors
  useEffect(() => {
    setScreenHasErrors(
      Object.keys(formMethods.formState.errors).some((key) =>
        activeScreen.fields.includes(key as keyof NotificationRuleSubmitForm),
      ),
    );
  }, [formMethods.formState, activeScreen]);

  useEffect(() => {
    const requestErrorStatus = requestError?.response?.status;
    if (requestErrorStatus && requestErrorStatus !== 409) {
      setActiveScreen(notificationRuleScreens.errorScreen);
    }
  }, [requestError]);

  return (
    <form onSubmit={formMethods.handleSubmit(handleFormSubmit)}>
      {activeScreen.key === 'deviceTypeScreen' && (
        <DeviceTypeScreen
          formControl={formMethods}
          onContinue={handleNext}
          isLoading={isLoading}
          screenHasErrors={screenHasErrors}
        />
      )}

      {activeScreen.key === 'devicesScreen' && (
        <DevicesScreen
          formControl={formMethods}
          onBack={handlePrevious}
          onContinue={handleNext}
          screenHasErrors={screenHasErrors}
        />
      )}

      {activeScreen.key === 'conditionScreen' && (
        <ConditionScreen
          formControl={formMethods}
          onBack={handlePrevious}
          onContinue={handleNext}
          screenHasErrors={screenHasErrors}
        />
      )}

      {activeScreen.key === 'recipientsScreen' && (
        <RecipientsScreen
          formControl={formMethods}
          onBack={handlePrevious}
          onContinue={handleNext}
          isLoading={isLoading}
          screenHasErrors={screenHasErrors}
        />
      )}

      {activeScreen.key === 'nameScreen' && (
        <NameScreen
          formControl={formMethods}
          onBack={handlePrevious}
          isLoading={isLoading}
          screenHasErrors={screenHasErrors}
        />
      )}

      {activeScreen.key === 'errorScreen' && <ErrorDialog onTryAgain={handlePrevious} />}
    </form>
  );
}
