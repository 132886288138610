import type { DeviceTypes } from '@eppendorf/vnls-application-configuration-service-types';
import { Device } from '@eppendorf/vnls-inventory-service-types';
import { NotificationRule } from '@eppendorf/vnls-notification-service-types';
import { User } from '@eppendorf/vnls-user-tenant-utils';
import { AxiosError } from 'axios';
import { UseFormReturn } from 'react-hook-form';

export type NotificationRuleSubmitForm = {
  notificationRuleName: string;
  devices: { id: string; value: string }[];
  recipients: User[];
  selectedDeviceType: DeviceTypes;
  tags: string[];
};

export enum NotificationRuleMode {
  EDIT = 'edit',
  CREATE = 'create',
}
export type AvailableScreenProps = {
  key: AddOrEditNotiRuleModalScreens;
  fields: Array<keyof NotificationRuleSubmitForm>;
};

export interface NotificationRuleDialogContentProps {
  data?: NotificationRuleDetailData;
  handleFormSubmit: (data: NotificationRuleSubmitForm) => Promise<void>;
  isLoading: boolean;
  requestError: AxiosError;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any -- we know it exists */
  formMethods: UseFormReturn<NotificationRuleSubmitForm, any, undefined>;
  initialScreen?: AddOrEditNotiRuleModalScreens;
  onScreenChange?: (screen: AddOrEditNotiRuleModalScreens) => void;
}

export interface AddNotificationRuleScreenProps {
  formControl: UseFormReturn<NotificationRuleSubmitForm, unknown, undefined>;
}

export type AddOrEditNotiRuleModalScreens =
  | 'deviceTypeScreen'
  | 'devicesScreen'
  | 'conditionScreen'
  | 'recipientsScreen'
  | 'nameScreen'
  | 'successScreen'
  | 'errorScreen';

export interface Severity {
  name: 'error' | 'alert' | 'warning' | 'info';
}

export interface NotificationRuleDetailData extends NotificationRule {
  devices: {
    details: Device[];
    errors: string[];
  };
}
