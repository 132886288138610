import { Button, Dialog, DotsLoader } from '@eppendorf/vnls-react-components';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { FunctionComponentElement, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { useAutoFocus } from '$shared/custom-hooks/useAutoFocus';

import { createDeviceQuery, devicesQueryKey } from '$features/devices/devices.api';

export interface WaitingScreenProps {
  onSubmit: () => void;
  serialNumber: string;
  onError: () => void;
}

export function WaitingScreen({
  onSubmit,
  serialNumber,
  onError,
}: WaitingScreenProps): FunctionComponentElement<WaitingScreenProps> {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const cancelButtonRef = useRef<null | HTMLButtonElement>(null);

  useAutoFocus(cancelButtonRef);

  const { data: device, isError } = useQuery({
    ...createDeviceQuery({ manufacturer: 'Eppendorf', serialNumber: serialNumber ?? '' }),
    refetchInterval: 1000,
    refetchIntervalInBackground: true,
    refetchOnWindowFocus: true,
  });

  useEffect(() => {
    if (device && device.connectionStatus === 'paired') {
      queryClient.invalidateQueries([devicesQueryKey]);
      onSubmit();
    }

    if (isError) {
      onError();
    }

    const waitForDeviceToExistTimer = setTimeout(() => {
      queryClient.invalidateQueries([devicesQueryKey]);
      onError();
    }, 30000);

    return () => {
      clearTimeout(waitForDeviceToExistTimer);
    };
  }, [device]);

  return (
    <>
      <DotsLoader className="m-y-4xl" />
      <Dialog.Title className="m-bottom-m font-size-xxl text-align-center">
        {t('addDevice.waiting')}
      </Dialog.Title>
      <p className="text-align-center">{t('addDevice.waitingInfo')}</p>
      <Dialog.Actions className="m-top-xxxl">
        <Dialog.Close>
          <Button ref={cancelButtonRef}>{t('shared.cancel')}</Button>
        </Dialog.Close>
      </Dialog.Actions>
    </>
  );
}
