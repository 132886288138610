import { FunctionComponentElement, useState } from 'react';

import {
  InviteScreen,
  InviteUserForm,
} from '$features/invite-user/invite-user-dialog/screens/invite-screen';
import { SuccessScreen } from '$features/invite-user/invite-user-dialog/screens/success-screen';

export enum UserInviteScreens {
  'InviteScreen',
  'SuccessScreen',
}

export interface UserInviteContentProps {
  activeScreen: UserInviteScreens;
  setActiveScreen: (screen: UserInviteScreens) => void;
}

export function InviteUserDialogContent({
  activeScreen,
  setActiveScreen,
}: UserInviteContentProps): FunctionComponentElement<void> {
  const [invitedUser, setInvitedUser] = useState<InviteUserForm>();

  function navigateToSuccessScreen(user: InviteUserForm): void {
    setActiveScreen(UserInviteScreens.SuccessScreen);
    setInvitedUser(user);
  }

  return (
    <>
      {activeScreen === UserInviteScreens.InviteScreen && (
        <InviteScreen onSubmit={(user) => navigateToSuccessScreen(user)} />
      )}

      {activeScreen === UserInviteScreens.SuccessScreen && (
        <SuccessScreen invitedUser={invitedUser} />
      )}
    </>
  );
}
