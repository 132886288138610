import { createContext } from 'react';

export interface AuthContextValue {
  isAuthenticated: boolean;
  isLoading: boolean;
  signOut: () => Promise<void>;
  signIn: () => Promise<void>;
}

export const AuthContext = createContext<AuthContextValue>({} as AuthContextValue);
