import { Input } from '@eppendorf/vnls-react-components';
import { FieldPath, useFormContext } from 'react-hook-form';

import { getThresholdName } from '$features/monitoring/monitoring.utils';
import { ThresholdsFormProfile } from '$features/monitoring/parameters/thresholds-form';
import { ThresholdValueLabelProps } from '$features/monitoring/parameters/types';

export function ThresholdDynamicValueLabel({ realtimeData }: ThresholdValueLabelProps) {
  const { register, formState } = useFormContext<ThresholdsFormProfile>();

  const name = getThresholdName(realtimeData) as FieldPath<ThresholdsFormProfile>;

  return (
    <>
      <Input
        id={name}
        type="number"
        // eslint-disable-next-line security/detect-object-injection -- this is safe
        invalid={!!formState.errors[name]}
        className="w-max-xxs bg-white"
        // eslint-disable-next-line react/jsx-props-no-spreading -- It is safe to spread register
        {...register(name)}
      />

      <span className="m-left-s">{realtimeData.unit}</span>
    </>
  );
}
