import { User } from '@eppendorf/vnls-user-tenant-utils';
import { FunctionComponentElement, useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { useAutoFocus } from '$shared/custom-hooks/useAutoFocus';

import {
  ConfirmDialogIconType,
  ConfirmationDialog,
} from '$components/confirmation-dialog/confirmation-dialog';
import { displayUser } from '$features/invite-user/invite-user-dialog/screens/display-user.util';

export interface DeleteErrorProps {
  user: User;
}

export function DeleteErrorScreen({
  user,
}: DeleteErrorProps): FunctionComponentElement<void> {
  const { t } = useTranslation();
  const closeButtonRef = useRef<null | HTMLButtonElement>(null);

  useAutoFocus(closeButtonRef);
  return (
    <ConfirmationDialog
      title={t('userDelete.deleteError')}
      iconType={ConfirmDialogIconType.error}
      dialogDescription={
        <>
          <Trans
            i18nKey="userDelete.descriptionUserNotDeleted"
            values={{
              user: displayUser(user),
            }}
            components={{ bold: <span className="font-weight-bold" /> }}
          />{' '}
          <p className="m-top-xl">
            <span>{t('errors.contactSupport')} </span>

            {/* TOOD: We dont have a Link/Anchor/.. component yet, see: https://github.com/eppendorf/vn-browser-libs/issues/202 */}
            <a
              href="https://www.eppendorf.com/service-support/"
              className="btn-base btn btn--tertiary btn--info btn--small"
              target="_blank"
              style={{ display: 'inline' }}
              rel="noreferrer"
            >
              {t('errors.contactSupport2')}
            </a>
          </p>
        </>
      }
    />
  );
}
