import { MonitorModels } from '@eppendorf/vnls-application-configuration-service-types';
import { HasChildren } from '@eppendorf/vnls-react-components';
import type { ReactNode } from 'react';

interface UnlessSenseMonitorProps extends HasChildren {
  deviceModel: string | undefined | null;
  fallback?: ReactNode;
}

export function UnlessSenseMonitor({
  deviceModel,
  children,
  fallback = '-',
}: UnlessSenseMonitorProps) {
  const isSenseMonitor = Object.values(MonitorModels).includes(
    deviceModel as MonitorModels,
  );

  if (isSenseMonitor) {
    return fallback ?? '-';
  }

  return children;
}
