import {
  Checkbox,
  Dialog,
  DotsLoader,
  SortableTable,
} from '@eppendorf/vnls-react-components';
import { useQueries } from '@tanstack/react-query';
import { createColumnHelper } from '@tanstack/react-table';
import cn from 'classnames';
import { useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import {
  ConfirmationDialog,
  ConfirmDialogIconType,
} from '$components/confirmation-dialog/confirmation-dialog';
import { RouterStatus, RouterStatusBadge } from '$components/device/router-status-badge';
import { SignalStrengthIcon } from '$components/device/signal-strength-icon';
import { RouterNameCell } from '$features/devices/associated-router/router-list-name-cell';
import {
  createDeviceQuery,
  useGetDevice,
  useGetGatewayAssociationList,
  useGetSenseDevice,
  useUpdateMonitorBlockedState,
} from '$features/devices/devices.api';
import { AssociatedMonitorModel } from '$features/devices/devices.types';

import styles from './associated-monitors.module.scss';

export function AssociatedMonitors() {
  const [searchParams] = useSearchParams();
  const gatewayId = searchParams.get('serialNumber') ?? '';
  const { t } = useTranslation();
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false);
  const [selectedAssociatedMonitor, setSelectedAssociatedMonitor] = useState({
    serialNumber: '',
    name: '',
    isBlocked: false,
  });
  const columnHelper = createColumnHelper<AssociatedMonitorModel>();
  const [columnsData, setColumnsData] = useState<AssociatedMonitorModel[]>([]);

  const {
    isLoading: isPatchBlockListLoading,
    mutateAsync: patchBlockList,
    isSuccess: isBlockListPatchSuccess,
  } = useUpdateMonitorBlockedState(gatewayId);

  const associationList = useGetGatewayAssociationList(gatewayId);

  const { data: inventoryGateway } = useGetDevice({
    manufacturer: searchParams.get('manufacturer') ?? '',
    serialNumber: gatewayId,
  });

  const {
    data: senseGateway = { serialNumber: '', clientsAvailable: [] },
    isLoading: isSenseDeviceLoading,
  } = useGetSenseDevice(gatewayId, 'gateways');

  const queryResults = useQueries({
    queries: senseGateway.clientsAvailable.map((client) =>
      createDeviceQuery({
        manufacturer: searchParams.get('manufacturer') ?? '',
        serialNumber: client.clientId,
        enabled: !isSenseDeviceLoading,
      }),
    ),
  });

  const allQueriesCompleted =
    !isSenseDeviceLoading &&
    queryResults.length === senseGateway.clientsAvailable.length &&
    queryResults.every((result) => result.isSuccess || result.isError) &&
    (associationList.isSuccess || associationList.isError);

  useEffect(() => {
    if (!isPatchBlockListLoading && isBlockListPatchSuccess) {
      setIsConfirmationDialogOpen(true);
    }
  }, [isPatchBlockListLoading]);

  /* eslint-disable security/detect-object-injection -- Improve in future */
  useEffect(() => {
    if (allQueriesCompleted) {
      const mappedData: AssociatedMonitorModel[] = [];

      for (let i = 0; i < queryResults.length; i += 1) {
        const availableClient = senseGateway.clientsAvailable[i];
        const availableClientInventory = queryResults[i].data;
        const isBlocked = associationList.data?.blocked.includes(
          availableClient.clientId,
        );

        const monitor: AssociatedMonitorModel = {
          serialNumber: availableClient.clientId || '',
          model: availableClientInventory?.model ?? 'Eppendorf',
          connectionStatus:
            availableClient.registrationState === 'notWhitelisted'
              ? RouterStatus.BLOCKED
              : availableClient.connectionStatus,
          signalStrength: availableClient.signalStrength,
          name: availableClientInventory?.name ?? availableClient.clientId,
          isBlocked,
        };

        mappedData.push(monitor);
      }

      setColumnsData(mappedData);
    }
  }, [allQueriesCompleted]);
  /* eslint-enable security/detect-object-injection */

  const handleOnBlock = async (
    serialNumber: string,
    associatedMonitorName: string,
    isBlocked: boolean,
  ) => {
    setColumnsData(
      columnsData.map((colData) => ({
        ...colData,
        isBlocked:
          colData.serialNumber === serialNumber ? !colData.isBlocked : colData.isBlocked,
      })),
    );
    setSelectedAssociatedMonitor({
      serialNumber,
      name: associatedMonitorName,
      isBlocked,
    });
    await patchBlockList({
      monitorId: serialNumber,
      isBlocked,
    });
  };

  const columns = useMemo(
    () => [
      columnHelper.accessor('connectionStatus', {
        header: () => t('deviceDetail.monitorsTable.connection'),
        // eslint-disable-next-line react/no-unstable-nested-components -- accepted until we figure out a better way
        cell: ({ row: { original: associatedMonitor } }) => (
          <div className="flex__ai--center flex__jc--space-between">
            <RouterStatusBadge status={associatedMonitor.connectionStatus} />
            <SignalStrengthIcon signalStrength={associatedMonitor.signalStrength} />
          </div>
        ),
      }),
      columnHelper.accessor('signalStrength', {
        header: () => t('deviceDetail.monitorsTable.monitors'),
        // eslint-disable-next-line react/no-unstable-nested-components -- accepted until we figure out a better way
        cell: ({ row: { original: associatedMonitor } }) => (
          <RouterNameCell
            manufacturer={associatedMonitor.model}
            routerName={associatedMonitor.name || associatedMonitor.serialNumber}
            icon="sense-monitor"
          />
        ),
      }),
      columnHelper.accessor('isBlocked', {
        header: () => t('deviceDetail.monitorsTable.block'),
        // eslint-disable-next-line react/no-unstable-nested-components -- accepted until we figure out a better way
        cell: ({ row: { original: associatedMonitor } }) => (
          <Checkbox
            checked={associatedMonitor.isBlocked}
            onChange={(checked) =>
              handleOnBlock(
                associatedMonitor.serialNumber,
                associatedMonitor.name,
                Boolean(checked),
              )
            }
          />
        ),
      }),
    ],
    [columnsData],
  );

  if (isSenseDeviceLoading || !allQueriesCompleted) {
    return <DotsLoader className="m-top-l" />;
  }

  if (!columnsData.length) {
    return (
      <div className="sub-title m-top-l">
        {t('deviceDetail.monitorsTable.emptyMessage')}
      </div>
    );
  }

  return (
    <div>
      <div className={cn(styles.monitorsTable)}>
        <SortableTable<AssociatedMonitorModel[], AssociatedMonitorModel>
          data={columnsData}
          columns={columns}
        />
      </div>
      <Dialog
        isOpen={isConfirmationDialogOpen}
        onOpenChange={(state) => setIsConfirmationDialogOpen(state)}
        hideCloseButton
      >
        <div title="associated-monitors-confirmation-dialog">
          <ConfirmationDialog
            title={t(
              `deviceDetail.tabs.associatedMonitorsDialog.${selectedAssociatedMonitor.isBlocked ? 'blockedTitle' : 'unblockedTitle'}`,
            )}
            dialogDescription={
              <>
                <Trans
                  i18nKey={`deviceDetail.tabs.associatedMonitorsDialog.${selectedAssociatedMonitor.isBlocked ? 'blockedDescription' : 'unblockedDescription'}`}
                  values={{
                    gatewayName: inventoryGateway?.name ?? 'Device',
                    associatedMonitorName: selectedAssociatedMonitor.name,
                  }}
                  components={{ bold: <span className="font-weight-bold" /> }}
                />
                <p style={{ marginTop: 8, fontStyle: 'italic' }}>
                  {t('deviceDetail.tabs.associatedMonitorsDialog.disclaimer')}
                </p>
              </>
            }
            iconType={ConfirmDialogIconType.success}
          />
        </div>
      </Dialog>
    </div>
  );
}
