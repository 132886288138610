import { ValidationMessage } from '@eppendorf/vnls-react-components';
import { useFormContext } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import './monitoring-parameter-form-errors.scss';
import {
  ThresholdsFormProfile,
  ThresholdsFormErrorTypes,
} from './thresholds-form-profile';

export function MonitoringParameterFormErrors() {
  const { t } = useTranslation();
  const {
    formState: { errors },
  } = useFormContext<ThresholdsFormProfile>();

  const hasLimitErrors = Object.keys(errors).some(
    (key) =>
      errors[key as keyof ThresholdsFormProfile]?.type ===
      ThresholdsFormErrorTypes.limits,
  );

  return (
    Object.keys(errors).length > 0 && (
      <div className="monitoring-parameter-form-errors border-solid bg-white border-radius-m border-s border-color-red-500 p-m">
        {hasLimitErrors && (
          <ValidationMessage className="font-weight-bold font-size-m">
            <Trans i18nKey="alertThresholds.limitsError" components={{ br: <br /> }} />
          </ValidationMessage>
        )}

        {errors.root && (
          <ValidationMessage className="font-weight-bold font-size-m">
            {t('errors.unexpected')}
          </ValidationMessage>
        )}
      </div>
    )
  );
}
