import { Device } from '@eppendorf/vnls-inventory-service-types';
import { useQueries, UseQueryResult } from '@tanstack/react-query';

import { createDeviceQuery } from '$features/devices/devices.api';

interface Subject {
  manufacturer?: string;
  id: string;
}

const useFetchDevices = (subjects: Subject[]) => {
  const queries = subjects.map((subject) => ({
    ...createDeviceQuery({ manufacturer: 'Eppendorf', serialNumber: subject.id }),
  }));

  const results = useQueries({ queries }) as UseQueryResult<Device, unknown>[];

  const devices = results
    .map((result) => (result.data ? result.data : null))
    .filter((device): device is Device => device !== null);

  const errors = results
    .map((result) => (result.error ? (result.error as Error).message : null))
    .filter((error): error is string => error !== null);

  return { devices, errors };
};

export default useFetchDevices;
