import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  DotProps,
  ReferenceDot,
} from 'recharts';

import { linearInterpolation, liveInterpolation } from '$shared/utils/charts.utils';
import { getLocalizedMonthAndDay, getLocalizedTime } from '$shared/utils/date.utils';

import { CustomTooltip } from '$features/monitoring/history-charts/custom-tooltip';
import { TransformedHistoricalData } from '$features/monitoring/history-charts/monitoring.history-charts.graphql.api';
import { useHistoryChartsConfig } from '$features/monitoring/history-charts/useHistoryChartsConfig';
import { RANGE_PERIOD } from '$features/monitoring/history-charts/useRangePeriod';

function CustomizedDot(props: DotProps) {
  const { chartColors } = useHistoryChartsConfig();
  const { cx, cy } = props;
  return (
    <svg data-testid="live-animation-dot">
      <circle
        className="live-animate live-chart-outer-circle"
        cx={cx}
        cy={cy}
        strokeWidth={0}
        fill={chartColors.processValueColor}
      />
      <circle
        className="live-animate live-chart-inner-circle"
        cx={cx}
        cy={cy}
        strokeWidth={0}
        fill={chartColors.processValueColor}
      />
      <circle
        cx={cx}
        cy={cy}
        r={7}
        strokeWidth={0}
        fill={chartColors.processValueColor}
      />
    </svg>
  );
}

function useDomainExtension() {
  const [searchParams] = useSearchParams();
  const activeRangeTab = searchParams.get('rangePeriod');
  const from = Number.parseInt(searchParams.get('from') ?? '', 10);
  const to = Number.parseInt(searchParams.get('to') ?? '', 10);

  if (activeRangeTab === RANGE_PERIOD.LIVE) {
    return 100000;
  }

  if (from && to) {
    return (to - from) * 0.03;
  }

  if (activeRangeTab === RANGE_PERIOD.CUSTOM) {
    return 100000;
  }

  return 0;
}

function useTickFormatter(ticksCount: number) {
  const [searchParams] = useSearchParams();
  const from = Number.parseInt(searchParams.get('from') ?? '', 10);
  const to = Number.parseInt(searchParams.get('to') ?? '', 10);
  const rangePeriod = searchParams.get('rangePeriod');
  const isLiveTab = rangePeriod === RANGE_PERIOD.LIVE;
  const isCustom = rangePeriod === RANGE_PERIOD.CUSTOM;
  const tickFormatter = useCallback(
    (value: number, ix: number) => {
      const getLocalizedPeriodFn =
        !isLiveTab &&
        Number.isFinite(from) &&
        Number.isFinite(to) &&
        to - from > 3 * 24 * 60 * 60 * 1000
          ? getLocalizedMonthAndDay
          : getLocalizedTime;

      if (isLiveTab || (!from && !to)) {
        return getLocalizedTime(value);
      }

      if (ix === 0 || (isCustom && ix === ticksCount - 1)) {
        return getLocalizedPeriodFn(value);
      }
      return '';
    },
    [from, to, rangePeriod],
  );

  return tickFormatter;
}

function useTicks(historicalData?: TransformedHistoricalData): Array<number> {
  const [searchParams] = useSearchParams();
  const activeRangeTab = searchParams.get('rangePeriod');
  const isLiveTab = activeRangeTab === RANGE_PERIOD.LIVE;
  const fromParam = Number.parseInt(searchParams.get('from') ?? '', 10);
  const toParam = Number.parseInt(searchParams.get('to') ?? '', 10);

  if (!historicalData?.buckets || historicalData?.buckets?.length <= 0) {
    return [];
  }
  const firstBucket = historicalData.buckets.at(0);
  const lastBucket = historicalData.buckets.at(-1);

  if (!firstBucket || !lastBucket) {
    return [];
  }

  const { from } = firstBucket;
  const { to } = lastBucket;

  if (isLiveTab || (!fromParam && !toParam)) {
    const ticks = liveInterpolation(to, 14);
    return ticks;
  }

  const ticks = linearInterpolation(from, to);
  ticks.push(lastBucket.to);
  return ticks;
}

export function HistoryChart({
  historicalData,
}: {
  historicalData?: TransformedHistoricalData;
}) {
  const [searchParams] = useSearchParams();
  const from = Number.parseInt(searchParams.get('from') ?? '', 10);
  const to = Number.parseInt(searchParams.get('to') ?? '', 10);
  const { showThresholdRefs, chartColors } = useHistoryChartsConfig();
  const activeDotCSS = { strokeWidth: 2, r: 7 };
  const activeRangeTab = searchParams.get('rangePeriod');
  const isLiveTab = activeRangeTab === RANGE_PERIOD.LIVE;

  const domainExtension = useDomainExtension();
  const ticks = useTicks(historicalData);
  const fontSizeXAxis = isLiveTab || (!from && !to) ? 12 : 14;
  const tickFormatter = useTickFormatter(ticks.length);

  return (
    <ResponsiveContainer width="98%" height={300}>
      <AreaChart
        syncId="anyId"
        // Width and height needed for unit-testing only.
        // They are ignored by ResponsiveContainer in production.
        width={100}
        height={100}
        data={historicalData?.buckets}
        margin={{
          top: 10,
          right: 5,
          left: 0, // needs to be 0, otherwise large y-axis values are not fully printed
          bottom: 0,
        }}
      >
        <CartesianGrid vertical={false} />

        <XAxis
          type="number"
          axisLine={false}
          dataKey="to"
          padding={{
            left: 5,
          }}
          domain={['dataMin', `dataMax + ${domainExtension}`]}
          tickMargin={10}
          interval={0} // Show all labels
          tick={{ fontSize: fontSizeXAxis }}
          ticks={ticks}
          tickFormatter={tickFormatter}
        />
        <YAxis
          padding={{
            top: 0,
          }}
          axisLine={false}
          tickLine={false}
        />

        <defs>
          <linearGradient id="colorProcessValue" x1="0" y1="0" x2="0" y2="1">
            <stop offset="0%" stopColor="#3B81F5" stopOpacity={0.2} />
            <stop offset="100%" stopColor="#3B81F5" stopOpacity={0} />
          </linearGradient>
        </defs>

        <Tooltip content={<CustomTooltip />} />

        {activeRangeTab === RANGE_PERIOD.LIVE && (
          <ReferenceDot
            x={historicalData?.buckets.findLast((item) => item.processValue)?.to}
            y={historicalData?.buckets
              .findLast((item) => item.processValue)
              ?.processValue?.toString()}
            shape={<CustomizedDot />}
          />
        )}

        {showThresholdRefs && (
          <>
            <Area
              type="linear"
              dataKey="setPoint"
              stroke={chartColors.setPointColor}
              strokeWidth={2}
              baseLine={8}
              fill="none"
              activeDot={activeDotCSS}
            />
            <Area
              type="linear"
              dataKey="alertHigh"
              stroke={chartColors.alertHighLowColor}
              strokeWidth={2}
              baseLine={8}
              fill="none"
              activeDot={activeDotCSS}
            />
            <Area
              type="linear"
              dataKey="alertLow"
              stroke={chartColors.alertHighLowColor}
              strokeWidth={2}
              baseLine={8}
              fill="none"
              activeDot={activeDotCSS}
            />
          </>
        )}

        <Area
          type="linear"
          dataKey="processValue"
          stroke={chartColors.processValueColor}
          strokeWidth={2}
          baseLine={8}
          fill="url(#colorProcessValue)"
          baseValue="dataMin"
          activeDot={activeDotCSS}
        />
      </AreaChart>
    </ResponsiveContainer>
  );
}
