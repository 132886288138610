import { Dialog } from '@eppendorf/vnls-react-components';
import { FunctionComponentElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { UnsavedDialog } from '$features/notifications/add-or-edit-notification-rule-dialog/edit-notification-rule/unsaved-dialog/unsaved-dialog';
import { NotificationRuleDialogContent } from '$features/notifications/add-or-edit-notification-rule-dialog/notification-rule-dialog-content';
import { SuccessScreen } from '$features/notifications/add-or-edit-notification-rule-dialog/screens/success-screen';
import { useNotificationRuleDialogForm } from '$features/notifications/add-or-edit-notification-rule-dialog/useNotificationRuleDialogForm';
import {
  AddOrEditNotiRuleModalScreens,
  NotificationRuleDetailData,
  NotificationRuleMode,
} from '$features/notifications/types';

export interface EditNotificationRuleDialogProps {
  isOpen?: boolean;
  onOpenChange: (open: boolean) => void;
  data: NotificationRuleDetailData;
}

type EditNotiRuleModalScreens = 'successScreen' | 'unsavedChangesScreen' | 'other';

export function EditNotificationRuleDialog({
  isOpen = false,
  onOpenChange,
  data,
}: EditNotificationRuleDialogProps): FunctionComponentElement<EditNotificationRuleDialogProps> {
  const { t } = useTranslation();
  const { handleFormSubmit, isLoading, requestError, isSuccess, formMethods } =
    useNotificationRuleDialogForm(data, NotificationRuleMode.EDIT);
  const [activeScreen, setActiveScreen] = useState<EditNotiRuleModalScreens>('other');
  const [previousScreen, setPreviousScreen] = useState<AddOrEditNotiRuleModalScreens>();

  // Needs to be desctructed during render, otherwise would be stale.
  // Also see https://react-hook-form.com/docs/useform/formstate -> Rules
  const { isDirty } = formMethods.formState;

  useEffect(() => {
    if (isSuccess) {
      setActiveScreen('successScreen');
    }
  }, [isSuccess]);

  function handleOpenChange(open: boolean): void {
    if (!open && isDirty && activeScreen === 'other') {
      setActiveScreen('unsavedChangesScreen');
      return;
    }

    if (onOpenChange) {
      onOpenChange(open);
    }
  }

  const handleContinueEditing = () => {
    setActiveScreen('other');
  };

  return (
    <Dialog
      isOpen={isOpen}
      onOpenChange={(open) => handleOpenChange(open)}
      className={activeScreen === 'other' ? 'w-max-7xl' : 'w-max-4xl'}
      hideCloseButton={
        activeScreen === 'successScreen' || activeScreen === 'unsavedChangesScreen'
      }
    >
      {activeScreen === 'other' && (
        <>
          <Dialog.Title>{t('notificationRules.edit')}</Dialog.Title>

          <NotificationRuleDialogContent
            handleFormSubmit={handleFormSubmit}
            isLoading={isLoading}
            requestError={requestError}
            formMethods={formMethods}
            initialScreen={previousScreen}
            onScreenChange={(screen) => setPreviousScreen(screen)}
          />
        </>
      )}

      {activeScreen === 'successScreen' && (
        <SuccessScreen
          mode={NotificationRuleMode.EDIT}
          notiRule={formMethods.getValues()}
        />
      )}

      {activeScreen === 'unsavedChangesScreen' && (
        <UnsavedDialog onContinueEditing={handleContinueEditing} />
      )}
    </Dialog>
  );
}
