import { Device } from '@eppendorf/vnls-inventory-service-types';
import { Badge } from '@eppendorf/vnls-react-components';
import cn from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { EditInfoItem } from '$features/devices/device-information/edit-info-item';
import { InfoItem } from '$features/devices/device-information/info-item';
import { UpdateGatewayDialog } from '$features/devices/device-information/update-dialogs/update-gateway-dialog';
import { useGetGatewayUpdateStatus } from '$features/devices/devices.api';
import { GatewayUpdateStatus } from '$features/devices/devices.types';

import styles from '../device-information.module.scss';

export function GeneralInformation({
  data,
  editMode,
}: {
  data: Device | undefined;
  editMode: boolean;
}) {
  const { t } = useTranslation();
  const [isUpdateDialogOpen, setIsUpdateDialogOpen] = useState(false);

  const { data: updateStatus } = useGetGatewayUpdateStatus<GatewayUpdateStatus>({
    enabled: data?.model?.includes('gateway'),
  });

  const getSoftwareVersion = () => (
    <>
      {data?.softwareRevision}
      {updateStatus?.isUpdateAvailable && (
        <div
          className={cn(styles.updateBadge, 'm-left-m')}
          onClick={() => setIsUpdateDialogOpen(true)}
          role="presentation"
        >
          <Badge bordered color="blue" size="small">
            {t('deviceDetail.deviceInformation.updateAvailable')}
          </Badge>
        </div>
      )}
    </>
  );

  return (
    <>
      <div className="sub-title m-y-xxl">
        {t('deviceDetail.deviceInformation.generalInformation')}
      </div>
      <InfoItem
        label={t('deviceDetail.deviceInformation.deviceType')}
        value={data?.type}
      />
      {!editMode && (
        <InfoItem
          label={t('deviceDetail.deviceInformation.deviceName')}
          value={data?.name}
        />
      )}
      {editMode && (
        <EditInfoItem id="name" label={t('deviceDetail.deviceInformation.deviceName')} />
      )}
      <InfoItem
        label={t('deviceDetail.deviceInformation.serialNumber')}
        value={data?.serialNumber}
      />
      {!editMode && (
        <InfoItem
          label={t('deviceDetail.deviceInformation.customInventoryId')}
          value={data?.registrationNumber}
        />
      )}
      {editMode && (
        <EditInfoItem
          id="registrationNumber"
          label={t('deviceDetail.deviceInformation.customInventoryId')}
        />
      )}
      <InfoItem
        label={t('deviceDetail.deviceInformation.manufacturer')}
        value={data?.manufacturer}
      />
      <InfoItem
        label={t('deviceDetail.deviceInformation.deviceModel')}
        value={data?.model}
      />
      <InfoItem
        label={t('deviceDetail.deviceInformation.softwareVersion')}
        value={getSoftwareVersion()}
      />

      {updateStatus?.isUpdateAvailable && (
        <UpdateGatewayDialog
          isOpen={isUpdateDialogOpen}
          onOpenChange={(status) => setIsUpdateDialogOpen(status)}
          updateStatus={updateStatus}
        />
      )}
    </>
  );
}
