import { User } from '@eppendorf/vnls-user-tenant-utils';
import { QueryClient } from '@tanstack/react-query';
import { ReactElement } from 'react';

import { prepopulateCache } from '$shared/utils/react-query.utils';

import { ShowUserWrapper } from '$features/show-user';
import { createGetUserQuery } from '$features/users/users.api';

export async function userDetailLoader(
  queryClient: QueryClient,
  userId: string,
): Promise<null> {
  return prepopulateCache<User>(queryClient, createGetUserQuery(userId));
}

export function UserDetailPage(): ReactElement {
  return <ShowUserWrapper />;
}
