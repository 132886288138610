import { Device } from '@eppendorf/vnls-inventory-service-types';
import { QueryClient } from '@tanstack/react-query';
import { ReactElement } from 'react';

import { prepopulateCache } from '$shared/utils/react-query.utils';

import { DeviceDetailWrapper } from '$features/devices/device-detail-wrapper';
import { createDeviceQuery } from '$features/devices/devices.api';

export async function deviceDetailLoader(
  queryClient: QueryClient,
  deviceSearch: Pick<Device, 'manufacturer' | 'serialNumber'>,
): Promise<null> {
  return prepopulateCache<Device>(queryClient, createDeviceQuery(deviceSearch));
}

export function DeviceDetailPage(): ReactElement {
  return <DeviceDetailWrapper />;
}
