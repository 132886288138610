import { ReactElement } from 'react';
import { useSearchParams } from 'react-router-dom';

import { FormWrapper } from '$features/show-user/form-wrapper';
import { ShowUserContent } from '$features/show-user/show-user-content';
import { useGetUser } from '$features/users/users.api';

export function ShowUserWrapper(): ReactElement {
  const [searchParams] = useSearchParams();

  const {
    data: userData,
    isLoading: isGetUserLoading,
    isError: isGetUserError,
  } = useGetUser(searchParams.get('id') || '');

  return (
    <FormWrapper user={userData}>
      <ShowUserContent
        user={userData}
        isError={isGetUserError}
        isLoading={isGetUserLoading}
      />
    </FormWrapper>
  );
}
