import { Device } from '@eppendorf/vnls-inventory-service-types';
import { Sidecar, Badge } from '@eppendorf/vnls-react-components';
import { Device as RealtimeDevice } from '@eppendorf/vnls-telemetry-and-events-types';
import { ReactElement, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { BatteryStatus } from '$components/device/battery-status';
import { SignalStrengthIcon } from '$components/device/signal-strength-icon';
import { useFeatureIsEnabled } from '$components/feature-toogle/feature-is-enabled';
import { LoadingErrorHint } from '$components/loading-error-hint/loading-error-hint';
import { SidecarProvider } from '$components/sidecar/sidecar-context';
import { SidecarRouterOutlet } from '$components/sidecar-router/sidecar-router-outlet';
import { TabsNavigation } from '$components/tabs';
import { DeviceDetailHeader } from '$features/devices/device-detail-header';
import { DeviceDetailRetryReg } from '$features/devices/device-detail-retry-reg/device-detail-retry-reg';
import { useGetOperationalStatus, useGetDevice } from '$features/devices/devices.api';
import { getConnectionStatusText } from '$features/devices/devices.utils';
import { useDeviceViewConfig } from '$features/devices/devices.view.config';

function getConnectionBadge(
  connectionStatus: Device['connectionStatus'] | RealtimeDevice['connectionStatus'],
) {
  switch (connectionStatus) {
    case 'pending':
    case 'readyToPair':
      return (
        <Badge color="orange" bordered>
          {getConnectionStatusText(connectionStatus)}
        </Badge>
      );
    case 'paired':
      return (
        <Badge color="green" bordered>
          {getConnectionStatusText(connectionStatus)}
        </Badge>
      );
    case 'waitingForMetadata':
      return (
        <Badge color="blue" bordered>
          {getConnectionStatusText(connectionStatus)}
        </Badge>
      );
    default:
      return '';
  }
}

export function DeviceDetail(): ReactElement {
  const [isCancelDialogOpen, setIsCancelDialogOpen] = useState(false);
  const [isSidecardClosing, setIsSidecarClosing] = useState(false);
  const retryEnabled = useFeatureIsEnabled('retryRegistration');
  const [searchParams] = useSearchParams();

  const { data: deviceData, isError } = useGetDevice({
    manufacturer: searchParams.get('manufacturer') ?? '',
    serialNumber: searchParams.get('serialNumber') ?? '',
  });

  const [isSidecarOpen, setIsSidecarOpen] = useState(true);
  const navigate = useNavigate();

  const viewConfig = useDeviceViewConfig(deviceData);

  const { data: operationalStatus } = useGetOperationalStatus(
    searchParams.get('serialNumber') ?? '',
    viewConfig.showBattery,
  );

  const {
    formState: { isDirty: isFormDirty },
  } = useFormContext();

  function handleSidecarOpenChange(): void {
    setIsSidecarClosing(true);

    if (isFormDirty) {
      setIsCancelDialogOpen(true);
      return;
    }

    setIsSidecarOpen(false);
    navigate({
      search: '',
    });
  }

  function handleOnTabChange(): void {
    setIsCancelDialogOpen(true);
  }

  const connectionBadge = getConnectionBadge(deviceData?.connectionStatus);

  return (
    <SidecarProvider
      value={{ isCancelDialogOpen, setIsCancelDialogOpen, isSidecardClosing }}
    >
      <Sidecar isOpen={isSidecarOpen} onOpenChange={() => handleSidecarOpenChange()}>
        <div className="flex flex__dir--column flex__ai--stretch h-full">
          <div className="flex flex__ai--center m-bottom-xxxl">
            <DeviceDetailHeader
              className="device-sidecar m-right-m"
              device={deviceData}
            />
            <div className="m-right-m">{connectionBadge}</div>
            {viewConfig.showBattery && (
              <BatteryStatus
                soc={operationalStatus?.battery?.soc}
                charging={operationalStatus?.battery?.charging}
                className="m-right-m"
              />
            )}
            {viewConfig.showSignalStrength && (
              <SignalStrengthIcon
                signalStrength={operationalStatus?.signalStrength ?? null}
              />
            )}
          </div>
          {isError && <LoadingErrorHint className="m-top-xl" />}
          {deviceData && (
            <>
              {retryEnabled && (
                <DeviceDetailRetryReg device={deviceData} className="m-bottom-xxxl" />
              )}

              <div className="h-full overflow-y-scroll">
                <div className="box flex__dir--column overflow-hidden">
                  <div className="box__header">
                    <TabsNavigation
                      routes={viewConfig.deviceDetailSidecarRoutes}
                      onTabChange={isFormDirty ? handleOnTabChange : undefined}
                      shouldChangeTabOnActive={!isFormDirty}
                    />
                  </div>
                  <SidecarRouterOutlet />
                </div>
              </div>
            </>
          )}
        </div>
      </Sidecar>
    </SidecarProvider>
  );
}
