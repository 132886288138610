import {
  NotificationRule,
  PaginationResponse,
} from '@eppendorf/vnls-notification-service-types';
import { QueryClient } from '@tanstack/react-query';

import { prepopulateCache } from '$shared/utils/react-query.utils';

import { NotificationRuleList } from '$features/notification-rule-list';
import { createNotificationRulesQueryWithQueryParams } from '$features/notifications/notification-rules.api';

export async function notificationRulesLoader(queryClient: QueryClient): Promise<null> {
  return prepopulateCache<{ data: NotificationRule[]; pagination: PaginationResponse }>(
    queryClient,
    createNotificationRulesQueryWithQueryParams({ page: 1, pageSize: 10 }),
  );
}

export function NotificationsPage() {
  return <NotificationRuleList />;
}
