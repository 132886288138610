import { QueryClient } from '@tanstack/react-query';

import { prepopulateCache } from '$shared/utils/react-query.utils';

import { MonitoringOverview } from '$features/monitoring';
import { createRealtimeDevicesQueryWithQueryParams } from '$features/monitoring/monitoring.graphql.api';

export async function monitoringLoader(queryClient: QueryClient): Promise<null> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- todo
  return prepopulateCache<any>(queryClient, createRealtimeDevicesQueryWithQueryParams());
}

export function MonitoringPage() {
  return <MonitoringOverview />;
}
