import { Badge, BadgeColor } from '@eppendorf/vnls-react-components';
import cn from 'classnames';

import { MonitoringParameterValueAge } from '$features/monitoring/parameters/monitoring-parameter-value-age';
import { ParameterHeadline } from '$features/monitoring/shared/parameter-headline';

export interface MonitoringParameterSimpleProps {
  name: string;
  value: string;
  timestamp: number;
  className: string;
  fullWidth: boolean;
}

const COLOR_SCHEME: Record<string, BadgeColor> = {
  Empty: 'dark-red',
  Normal: 'dark-green',
  Filled: 'dark-green',
  'Check bottle': 'orange',
};

export function MonitoringParameterSimple({
  name,
  value,
  timestamp,
  className,
  fullWidth,
}: MonitoringParameterSimpleProps) {
  const nodes = [
    <ParameterHeadline parameterName={name} className="p-right-m" />,
    <MonitoringParameterValueAge timestamp={timestamp} />,
  ];

  return (
    <div
      className={cn('flex flex__ai--center', className, {
        'flex__jc--space-between': fullWidth,
        'flex__dir--column flex__jc--center': !fullWidth,
      })}
    >
      {nodes[Number(!fullWidth)]}
      <div
        className={cn('flex', {
          'flex__dir--column flex__ai--flex-end': fullWidth,
          'flex__ai--center': !fullWidth,
        })}
      >
        {nodes[Number(fullWidth)]}
        {/* eslint-disable security/detect-object-injection -- no user input here */}
        <Badge
          size="small"
          color={COLOR_SCHEME[value]}
          className="whitespace-nowrap m-y-s"
        >
          {value}
        </Badge>
        {/* eslint-enable security/detect-object-injection */}
      </div>
    </div>
  );
}
