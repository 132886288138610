import { Button, HasClassName } from '@eppendorf/vnls-react-components';
import cn from 'classnames';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export function LoadingErrorHint({ className }: HasClassName): ReactElement {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className={cn(className, 'flex flex__jc--center')}>
      <div className=" flex flex__dir--column flex__ai--center">
        <span className="m-bottom-m">{t('errors.informationCouldNotBeLoaded')}</span>
        <Button variant="tertiary" size="small" onClick={() => navigate(0)}>
          {t('shared.tryAgain')}
        </Button>
      </div>
    </div>
  );
}
