import {
  Button,
  ButtonBase,
  Checkbox,
  Dialog,
  DotsLoader,
  Icon,
  IconSizeClasses,
  SortableTable,
  Tag,
  useDidMountEffect,
} from '@eppendorf/vnls-react-components';
import {
  User,
  UserStatus,
  Pagination as PaginationType,
} from '@eppendorf/vnls-user-tenant-utils';
import { CellContext, createColumnHelper } from '@tanstack/react-table';
import { FunctionComponentElement, ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Pagination } from '$components/table/pagination/pagination';
import { AddNotificationRuleScreenProps } from '$features/notifications/types';
import { useUsersWithQueryParams } from '$features/users/users.api';

const initialPageIndex = 1;
const initialPageSize = 10;

interface RecipientsScreenProps extends AddNotificationRuleScreenProps {
  onBack: () => void;
  onContinue: () => void;
  screenHasErrors: boolean;
  isLoading: boolean;
}

export function RecipientsScreen({
  formControl,
  onBack,
  onContinue,
  screenHasErrors,
  isLoading,
}: RecipientsScreenProps): FunctionComponentElement<ReactNode> {
  const { t } = useTranslation();
  const { register, watch, getValues, setValue } = formControl;

  const [{ page, pageSize }, setPagination] = useState<PaginationType>({
    page: initialPageIndex,
    pageSize: initialPageSize,
  });

  const {
    data: getUsersData,
    isLoading: getUsersLoading,
    refetch: refetchGetUsers,
  } = useUsersWithQueryParams({ page, pageSize, status: UserStatus.Active });

  useDidMountEffect(() => {
    refetchGetUsers();
  }, [page, pageSize]);

  function handlePageSizeChange(newPageSize: string): void {
    setPagination({ page: 1, pageSize: Number(newPageSize) });
  }

  function handlePageChange(newPage: number): void {
    setPagination({ pageSize, page: newPage });
  }

  const formSelectedRecipients = watch('recipients');

  useEffect(() => {
    register('recipients', {
      validate: (recipients) => recipients?.length > 0,
    });
  }, [register]);

  function removeRecipient(recipient: User): void {
    const updatedRecipients = getValues('recipients')?.filter(
      (u) => u.id !== recipient.id,
    );
    setValue('recipients', updatedRecipients, {
      shouldValidate: true,
      shouldDirty: true,
    });
  }

  function handleRecipientClick(recipientToAddOrRemove: User): void {
    const isRecipientSelected = getValues('recipients')?.some(
      (u) => u.id === recipientToAddOrRemove.id,
    );

    if (isRecipientSelected) {
      removeRecipient(recipientToAddOrRemove);
    } else {
      setValue('recipients', [...getValues('recipients'), recipientToAddOrRemove], {
        shouldValidate: true,
        shouldDirty: true,
      });
    }
  }

  function deselectAllRecipients(currentPageRecipients: User[]): void {
    setValue(
      'recipients',
      getValues('recipients')?.filter(
        (recipient) =>
          !currentPageRecipients.some(
            (currentPageRecipient) => currentPageRecipient.id === recipient.id,
          ),
      ),
      {
        shouldValidate: true,
        shouldDirty: true,
      },
    );
  }

  function handleHeaderCheckboxClick(): void {
    if (!getUsersData) return;

    const currentPageRecipients = getUsersData.data || [];
    const currentPageSelectedRecipients = currentPageRecipients.filter((user) =>
      getValues('recipients')?.some((selectedUser) => selectedUser.id === user.id),
    );

    if (currentPageSelectedRecipients.length === currentPageRecipients.length) {
      deselectAllRecipients(currentPageRecipients);
    } else {
      setValue(
        'recipients',
        [
          ...getValues('recipients').filter(
            (previousUser) =>
              !currentPageRecipients.some(
                (currentPageRecipient) => currentPageRecipient.id === previousUser.id,
              ),
          ),
          ...currentPageRecipients,
        ],
        {
          shouldValidate: true,
          shouldDirty: true,
        },
      );
    }
  }

  const columnHelper = createColumnHelper<User>();
  const columns = [
    {
      id: 'checked',
      // eslint-disable-next-line react/no-unstable-nested-components -- we want this here
      header: () => {
        const currentPageRecipients = getUsersData?.data || [];
        const currentPageSelectedRecipients = currentPageRecipients.filter((user) =>
          getValues('recipients')?.some(
            (selectedRecipient) => selectedRecipient.id === user.id,
          ),
        );
        return (
          <Checkbox
            onChange={() => handleHeaderCheckboxClick()}
            isIndeterminate={
              currentPageSelectedRecipients.length > 0 &&
              currentPageSelectedRecipients.length < currentPageRecipients.length
            }
            checked={
              currentPageSelectedRecipients.length === currentPageRecipients.length
            }
          />
        );
      },
      // eslint-disable-next-line react/no-unstable-nested-components -- we want this here
      cell: ({ row }: CellContext<User, string>) => (
        <Checkbox
          checked={getValues('recipients')?.some((u) => u.id === row.original.id)}
          onChange={() => handleRecipientClick(row.original)}
        />
      ),
    },
    columnHelper.accessor('firstName', {
      id: 'firstName',
      header: () => t('userManagement.firstName'),
      cell: (info) => info.renderValue(),
    }),
    columnHelper.accessor('lastName', {
      id: 'lastName',
      header: () => t('userManagement.lastName'),
      cell: (info) => info.renderValue(),
    }),
    columnHelper.accessor('email', {
      id: 'email',
      header: () => t('shared.email'),
      cell: (info) => info.renderValue(),
    }),
  ];

  return (
    <>
      {getUsersLoading && <DotsLoader className="m-top-10xl m-bottom-10xl" />}

      {getUsersData && !getUsersLoading && (
        <>
          <p className="m-bottom-xxxl">{t('notificationRules.selectRecipients')}</p>

          <div className="flex flex-wrap m-bottom-l">
            {formSelectedRecipients?.map((selectedRecipient) => (
              <Tag
                key={`${selectedRecipient.id}-tag`}
                variant="light"
                className="m-xs"
                trailingIcon={
                  <ButtonBase
                    onClick={() => {
                      removeRecipient(selectedRecipient);
                    }}
                  >
                    <Icon
                      name="close"
                      size={IconSizeClasses.XXSmall}
                      className="m-left-xs"
                    />
                  </ButtonBase>
                }
              >
                {selectedRecipient.email}
              </Tag>
            ))}
          </div>

          <SortableTable<User[], User>
            data={getUsersData.data}
            columns={columns}
            onRowClick={(user) => handleRecipientClick(user)}
          />
          <Pagination
            paginationData={getUsersData.pagination}
            onPageSizeChange={(size) => handlePageSizeChange(size)}
            onPageChange={(newPage) => handlePageChange(newPage)}
          />

          <div className="flex flex__ai--center m-top-xxxl">
            <span>{t('notificationRules.notificationsChannel')}</span>
            <Icon size={IconSizeClasses.Small} name="mail" className="m-x-l" />
            <span>{t('shared.email')}</span>
          </div>

          <Dialog.Actions>
            <Button variant="secondary" onClick={onBack}>
              {t('shared.back')}
            </Button>

            <Button onClick={onContinue} disabled={isLoading || screenHasErrors}>
              {t('shared.continue')}
            </Button>
          </Dialog.Actions>
        </>
      )}
    </>
  );
}
