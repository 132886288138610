export interface ThresholdsFormProfile {
  alertHigh: string;
  alertLow: string;
  warnHigh: string;
  warnLow: string;
  active: boolean;
}

export const ThresholdsFormDefaultValues: ThresholdsFormProfile = {
  alertHigh: '',
  alertLow: '',
  warnHigh: '',
  warnLow: '',
  active: false,
};

export const ThresholdsFormErrorTypes = {
  limits: 'limitsError',
};
