import { Amplify } from '@aws-amplify/core';

import { LOCALHOST_GRAPHQL_URL } from '$shared/utils/api-paths';

import { environment } from 'src/environment';

// TODO: it is really inconvenient that the FE needs this information (userPoolId, userPoolWebClientId etc.)
// i'm thinking more and more that a server side rendered solution would be a more appropriate
// way. An amplify based infrastructure would handle this for us.
// Also: This approach ends up in a one to one mapping between a frontend instance and an organisation/tenant.
// I think that is not what we want and probably won't scale.
Amplify.configure({
  Auth: {
    userPoolId: import.meta.env.PUBLIC_COGNITO_USER_POOL_ID,
    userPoolWebClientId: import.meta.env.PUBLIC_COGNITO_CLIENT_ID,
    region: import.meta.env.PUBLIC_AWS_REGION,
    oauth: {
      domain: import.meta.env.PUBLIC_COGNITO_OAUTH_DOMAIN,
      scope: ['email', 'profile', 'openid'],
      redirectSignIn: window.location.origin,
      redirectSignOut: `${window.location.origin}/logout`,
      responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
    },
  },
  API: {
    aws_appsync_graphqlEndpoint: environment.graphqlUrl || LOCALHOST_GRAPHQL_URL,
  },
});
