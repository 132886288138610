import { MutableRefObject, useEffect } from 'react';

export function useAutoFocus<T extends HTMLElement>(
  ref: MutableRefObject<T | null>
): void {
  useEffect(() => {
    if (ref.current) {
      ref.current.focus();
    }
  }, [ref.current]);
}
