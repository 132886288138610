import { useEffect, useState } from 'react';
import { useForm, FieldPath, ResolverError } from 'react-hook-form';

import { mapAlertThresholdsToFormValues } from '$features/monitoring/parameters/thresholds-form/thresholds-form-mapper';
import {
  ThresholdsFormProfile,
  ThresholdsFormDefaultValues,
  ThresholdsFormErrorTypes,
} from '$features/monitoring/parameters/thresholds-form/thresholds-form-profile';
import { AlertThresholds } from '$features/monitoring/parameters/types/alert-thresholds';

export interface ThresholdsFormOptions {
  alertThresholds?: AlertThresholds;
  onSubmitThresholds: (data: ThresholdsFormProfile) => Promise<AlertThresholds>;
}

export const useThresholdsForm = ({
  alertThresholds,
  onSubmitThresholds,
}: ThresholdsFormOptions) => {
  const [isEditing, setIsEditing] = useState(false);

  const form = useForm<ThresholdsFormProfile>({
    defaultValues: ThresholdsFormDefaultValues,
    mode: 'onBlur',
    resolver: (formValues) => {
      const result: ResolverError<ThresholdsFormProfile> = {
        errors: {},
        values: formValues,
      };

      const fieldsToValidate: Exclude<FieldPath<ThresholdsFormProfile>, 'active'>[] = [
        'alertHigh',
        'warnHigh',
        'warnLow',
        'alertLow',
      ];

      form.clearErrors(fieldsToValidate);

      for (let i = 0; i < fieldsToValidate.length; i += 1) {
        // eslint-disable-next-line security/detect-object-injection -- this is safe
        const key = fieldsToValidate[i];
        // eslint-disable-next-line security/detect-object-injection -- this is safe
        const currentValue = formValues[key];
        const nextValue = formValues[fieldsToValidate[i + 1]];

        if (!currentValue || Number.isNaN(Number(currentValue))) {
          // eslint-disable-next-line security/detect-object-injection -- this is safe
          result.errors[key] = { type: 'required' };

          form.setError(key, { type: 'required' });
        }

        if (currentValue && nextValue && Number(currentValue) <= Number(nextValue)) {
          // eslint-disable-next-line security/detect-object-injection -- this is safe
          result.errors[key] = { type: ThresholdsFormErrorTypes.limits };

          form.setError(key, { type: ThresholdsFormErrorTypes.limits });
        }
      }

      return result;
    },
  });

  const {
    formState,
    setError,
    setValue,
    watch,
    reset,
    handleSubmit: baseHandleSubmit,
  } = form;

  useEffect(() => {
    if (alertThresholds) {
      reset(mapAlertThresholdsToFormValues(alertThresholds));
    }
  }, [reset]);

  const handleAlertThresholdsToggle = (enabled: boolean): void => {
    setValue('active', enabled);
  };

  const alertThresholdsEnabled = watch('active');

  const handleReset = () => {
    reset();

    setIsEditing(false);
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSubmit = async (formValues: ThresholdsFormProfile) => {
    try {
      const newThresholds = await onSubmitThresholds(formValues);
      reset(mapAlertThresholdsToFormValues(newThresholds));
      setIsEditing(false);
    } catch (error) {
      setError('root', {
        message: 'Error while saving thresholds. Please try again.',
      });
    }
  };

  const { isSubmitting, isValid } = formState;

  const canSubmit = isValid;

  return {
    isEditing,
    canSubmit,
    isSubmitting,
    alertThresholdsEnabled,
    handleAlertThresholdsToggle,
    handleReset,
    handleEdit,
    handleSubmit: baseHandleSubmit(handleSubmit),
    form,
  };
};
