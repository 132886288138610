import {
  HasChildren,
  Icon,
  IconProps,
  IconSizeClasses,
} from '@eppendorf/vnls-react-components';
import { FunctionComponentElement, ReactElement } from 'react';

function SubTitle({ children }: HasChildren): ReactElement {
  return <div>{children}</div>;
}

function Title({ children }: HasChildren): ReactElement {
  return <div className="sub-title">{children}</div>;
}

function IconHint({ name }: Pick<IconProps, 'name'>): ReactElement {
  return (
    <Icon name={name} size={IconSizeClasses.Large} className="bg-gray-600 m-bottom-m" />
  );
}

function ErrorHintWrapper({
  children,
}: HasChildren): FunctionComponentElement<HasChildren> {
  return (
    <div className="bg-gray-50 flex flex__dir--column flex__ai--center p-xxxl">
      {children}
    </div>
  );
}

export const ErrorHint = Object.assign(ErrorHintWrapper, {
  IconHint,
  Title,
  SubTitle,
});
