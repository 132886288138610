import { AxiosError } from 'axios';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { createCurrentDateString } from '$shared/utils/date.utils';

import {
  useAddNotificationRule,
  useUpdateNotificationRule,
} from '$features/notifications/notification-rules.api';
import {
  NotificationRuleDetailData,
  NotificationRuleMode,
  NotificationRuleSubmitForm,
} from '$features/notifications/types';

export const useNotificationRuleDialogForm = (
  notificationRuleData: NotificationRuleDetailData | undefined,
  mode: NotificationRuleMode,
) => {
  const { t } = useTranslation();
  const editMode = mode === NotificationRuleMode.EDIT;
  const selectedRecipients = notificationRuleData?.recipients.map((recipient) => ({
    id: recipient.id,
    email: recipient.name,
  }));
  const selectedDevices = notificationRuleData?.devices.details.map((device) => ({
    id: device.serialNumber,
    value: device.serialNumber,
  }));

  const formMethods = useForm<NotificationRuleSubmitForm>({
    defaultValues: {
      selectedDeviceType: notificationRuleData?.selectedDeviceType || undefined,
      recipients: selectedRecipients || [],
      devices: selectedDevices || [],
      notificationRuleName:
        notificationRuleData?.name ||
        `${t('notificationRules.defaultNamePrefix')} - ${createCurrentDateString()}`,
      tags: notificationRuleData?.tags,
    },
  });

  const {
    mutateAsync,
    isLoading: isLoadingAdd,
    error: requestErrorAdd,
    isSuccess: isSuccessAdd,
    reset: resetAdd,
  } = useAddNotificationRule();

  const {
    mutateAsync: mutateEditAsync,
    isLoading: isLoadingEdit,
    isSuccess: isSuccessEdit,
    error: requestErrorEdit,
    reset: resetEdit,
  } = useUpdateNotificationRule();

  // Trigger validation for any field being edited
  useEffect(() => {
    const subscription = formMethods.watch((_value, { name }) =>
      formMethods.trigger(name),
    );
    return () => subscription.unsubscribe();
  }, [formMethods.watch]);

  // Watch for Backend API errors
  useEffect(() => {
    if (requestErrorAdd && requestErrorAdd.response?.status === 409) {
      formMethods.setError(
        'notificationRuleName',
        { message: t('notificationRules.error.nameAlreadyExists') },
        { shouldFocus: true },
      );
    }
  }, [requestErrorAdd]);
  useEffect(() => {
    const isAxiosError = (error: Error): error is AxiosError =>
      (error as AxiosError).isAxiosError !== undefined;

    if (
      requestErrorEdit &&
      isAxiosError(requestErrorEdit) &&
      requestErrorEdit.response?.status === 409
    ) {
      formMethods.setError(
        'notificationRuleName',
        { message: t('notificationRules.error.nameAlreadyExists') },
        { shouldFocus: true },
      );
    }
  }, [requestErrorEdit]);

  const handleFormSubmit = async (data: NotificationRuleSubmitForm) => {
    if (editMode) {
      try {
        await mutateEditAsync({
          id: notificationRuleData?.id || '',
          name: data.notificationRuleName,
          selectedDeviceType: data.selectedDeviceType,
          recipients: data.recipients.map((recipient) => ({
            id: recipient.id,
            type: 'user',
            channels: ['email'],
            name: recipient.email,
          })),
          subjects: data.devices.map(({ id }) => ({ type: 'device', id })),
          tags: data.tags,
        });
      } catch (_) {
        // already handled by the error effect
      }
    }
    if (mode === NotificationRuleMode.CREATE) {
      await mutateAsync({
        name: data.notificationRuleName,
        selectedDeviceType: data.selectedDeviceType,
        recipients: data.recipients.map((recipient) => ({
          id: recipient.id,
          type: 'user',
          channels: ['email'],
          name: recipient.email,
        })),
        subjects: data.devices.map(({ id }) => ({ type: 'device', id })),
        tags: data.tags,
      });
    }
  };

  return {
    handleFormSubmit,
    isLoading: editMode ? isLoadingEdit : isLoadingAdd,
    requestError: (editMode ? requestErrorEdit : requestErrorAdd) as AxiosError,
    isSuccess: editMode ? isSuccessEdit : isSuccessAdd,
    formMethods,
    reset: editMode ? resetEdit : resetAdd,
  };
};
