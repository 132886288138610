import { IconsSignal } from '@eppendorf/vnls-css/dist';
import { Icon, IconSizeClasses } from '@eppendorf/vnls-react-components';

export enum SignalStrength {
  STRONG = 'strong',
  FAIR = 'fair',
  WEAK = 'weak',
  NO = 'no',
}

const iconMap = new Map<SignalStrength, IconsSignal>([
  [SignalStrength.STRONG, 'signal-strong'],
  [SignalStrength.FAIR, 'signal-fair'],
  [SignalStrength.NO, 'signal-no'],
  [SignalStrength.WEAK, 'signal-weak'],
]);

interface SignalStrengthProps {
  signalStrength: number | null;
  className?: string;
}

function calcSignalIcon(strength: number | null) {
  if (strength == null) return SignalStrength.NO;
  if (strength > -70) return SignalStrength.STRONG;
  if (strength >= -85) return SignalStrength.FAIR;
  return SignalStrength.WEAK;
}

export function SignalStrengthIcon({ signalStrength, className }: SignalStrengthProps) {
  const icon = iconMap.get(calcSignalIcon(signalStrength));

  return icon ? (
    <Icon name={icon} size={IconSizeClasses.XSmall} className={className} />
  ) : null;
}
