import {
  Input,
  Label,
  ValidationMessage,
  Dialog,
  Button,
} from '@eppendorf/vnls-react-components';
import { FunctionComponentElement, ReactNode, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { AddNotificationRuleScreenProps } from '$features/notifications/types';

const maxNotificationRuleNameLength = 120;

interface NameScreenProps extends AddNotificationRuleScreenProps {
  onBack: () => void;
  screenHasErrors: boolean;
  isLoading: boolean;
}

export function NameScreen({
  formControl,
  onBack,
  isLoading,
}: NameScreenProps): FunctionComponentElement<ReactNode> {
  const { t } = useTranslation();
  const {
    register,
    watch,
    // We need to desctructure isValid directly here
    // Otherwise, say, if we would use formState.isValid, we would get stale state
    // Resulting in: Save button is disabled even though it should be enabled
    // Anyway: We should have been using the form context of react-hook-form for the whole form (Might have fixed this?)
    formState: { isValid, errors },
  } = formControl;

  useEffect(() => {
    const input = document.getElementById('notificationRuleName');
    if (input) {
      input.focus();
    }
  });

  return (
    <>
      <Label htmlFor="notificationRuleName">{t('notificationRules.inputName')}</Label>
      <Input
        invalid={!!errors.notificationRuleName}
        id="notificationRuleName"
        type="text"
        className="m-top-xs"
        maxLength={maxNotificationRuleNameLength}
        // eslint-disable-next-line react/jsx-props-no-spreading -- spread is needed for react-hook-form
        {...register('notificationRuleName', {
          required: true,
          maxLength: maxNotificationRuleNameLength,
        })}
      />

      <p className="color-gray-700 font-size-s m-top-xs m-bottom-xs">
        {watch('notificationRuleName')?.length}/{maxNotificationRuleNameLength}
      </p>
      {errors.notificationRuleName && (
        <ValidationMessage className="m-top-xs m-bottom-l">
          {errors.notificationRuleName.message}
        </ValidationMessage>
      )}

      <Dialog.Actions>
        <Button variant="secondary" onClick={onBack}>
          {t('shared.back')}
        </Button>

        <Button type="submit" disabled={isLoading || !isValid}>
          {t('shared.save')}
        </Button>
      </Dialog.Actions>
    </>
  );
}
