/* eslint-disable @typescript-eslint/no-explicit-any -- bringing in opensearch types is too expensive, gotta live with any */
import { Event as DeviceEvent } from '@eppendorf/vnls-notification-service-types';
import { useQuery } from '@tanstack/react-query';

import { fetcher } from '$shared/fetch';
import {
  DEVICE_EVENTS_BASE_PATH,
  objectToURLSearchParams,
} from '$shared/utils/api-paths';

export const deviceEventsQueryKey = 'deviceEvents';

export interface DeviceEventsQueryParams {
  from?: string | null;
  to?: string | null;
  serialNumber: string | null;
  message?: string | null;
  tags?: string[] | null;
  page?: number | null;
  pageSize?: number | null;
}

function prepareDeviceEvents(input: { _source: DeviceEvent; _id: string }[]) {
  // eslint-disable-next-line no-underscore-dangle -- given bx the interface of os response
  return input.map((a) => ({ ...a._source, uniqueId: a._id }));
}

export const getEvents = async (queryParams: DeviceEventsQueryParams) => {
  const result = await fetcher.post<any, any>(DEVICE_EVENTS_BASE_PATH, queryParams);

  return prepareDeviceEvents(result.data.hits.hits);
};

export const createDeviceEventsQueryWithQueryParams = (
  queryParams: DeviceEventsQueryParams,
) => ({
  queryKey: [deviceEventsQueryKey, ...objectToURLSearchParams(queryParams).values()],
  queryFn: async () => {
    const result = await fetcher.post<any, any>(DEVICE_EVENTS_BASE_PATH, queryParams);

    return prepareDeviceEvents(result.data.hits.hits);
  },
  staleTime: 5000,
  keepPreviousData: true,
  refetchOnWindowFocus: false,
  refetchOnReconnect: false,
});

export const getNewEvents = (queryParams: DeviceEventsQueryParams) => ({
  queryKey: ['latestDeviceEvents', ...objectToURLSearchParams(queryParams).values()],
  queryFn: async () => {
    const result = await fetcher.post<any, any>(DEVICE_EVENTS_BASE_PATH, queryParams);

    return prepareDeviceEvents(result.data.hits.hits);
  },
  staleTime: 1000,
  keepPreviousData: true,
  refetchInterval: (_data: unknown, _other: unknown) => {
    if (
      queryParams.to &&
      new Date(queryParams.to).setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0)
    ) {
      return false;
    }
    return 5000;
  },
});

export const useDeviceEventsWithQueryParams = (queryParams: DeviceEventsQueryParams) =>
  useQuery(createDeviceEventsQueryWithQueryParams(queryParams));

export const useLatestDeviceEventsWithQueryParams = (
  queryParams: DeviceEventsQueryParams,
) =>
  useQuery<(DeviceEvent & { uniqueId: string })[]>(
    getNewEvents({ ...queryParams, page: 1 }),
  );
/* eslint-enable @typescript-eslint/no-explicit-any */
