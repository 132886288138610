import { parseBoolean } from '$features/monitoring/monitoring.utils';
import {
  ThresholdsFormProfile,
  ThresholdsFormDefaultValues,
} from '$features/monitoring/parameters/thresholds-form/thresholds-form-profile';
import { AlertThresholds } from '$features/monitoring/parameters/types/alert-thresholds';

export function mapAlertThresholdsToFormValues(
  thresholds: AlertThresholds,
): ThresholdsFormProfile {
  return {
    alertHigh: thresholds.alertHigh?.value ?? ThresholdsFormDefaultValues.alertHigh,
    alertLow: thresholds.alertLow?.value ?? ThresholdsFormDefaultValues.alertLow,
    warnHigh: thresholds.warnHigh?.value ?? ThresholdsFormDefaultValues.warnHigh,
    warnLow: thresholds.warnLow?.value ?? ThresholdsFormDefaultValues.warnLow,
    active: parseBoolean(thresholds.enabled?.value) ?? ThresholdsFormDefaultValues.active,
  };
}
