import type { Icons } from '@eppendorf/vnls-css/dist';
import {
  AlertState,
  type RealtimeData,
} from '@eppendorf/vnls-telemetry-and-events-types';

import type { AlertThresholdKey } from '$features/monitoring/parameters/types';
import type { AlertThresholds } from '$features/monitoring/parameters/types/alert-thresholds';

export function findBySubjectAndComponent(
  realtime: (RealtimeData | null)[] | undefined,
  subject: string,
  subcomponent: string,
): RealtimeData | undefined {
  return (
    realtime?.find(
      (item) =>
        item != null && item.subject === subject && item.subcomponent === subcomponent,
    ) || undefined
  );
}

export function findAlertThresholdsBySubject(
  realtime: (RealtimeData | null)[] | undefined,
  subject: string,
): AlertThresholds {
  return {
    alertHigh: findBySubjectAndComponent(realtime, subject, 'AlertMonitor#alertHigh'),
    alertLow: findBySubjectAndComponent(realtime, subject, 'AlertMonitor#alertLow'),
    warnHigh: findBySubjectAndComponent(realtime, subject, 'AlertMonitor#warnHigh'),
    warnLow: findBySubjectAndComponent(realtime, subject, 'AlertMonitor#warnLow'),
    enabled: findBySubjectAndComponent(realtime, subject, 'AlertMonitor#enabled'),
  };
}

export function buildAlertConfig(
  subject: string,
  realtime: (RealtimeData | null)[] | undefined,
) {
  return {
    setPoint: findBySubjectAndComponent(realtime, subject, 'setPoint'),
    processValue: findBySubjectAndComponent(realtime, subject, 'processValue'),
    alertStatus: findBySubjectAndComponent(realtime, subject, 'alertState')?.value as
      | AlertState
      | undefined,
    alertThresholds: findAlertThresholdsBySubject(realtime, subject),
  };
}

export function alertThresholdsAvailable(alertThresholds?: AlertThresholds): boolean {
  return !!(
    alertThresholds?.alertHigh ||
    alertThresholds?.alertLow ||
    alertThresholds?.warnHigh ||
    alertThresholds?.warnHigh ||
    alertThresholds?.enabled
  );
}

export function parseBoolean(arg: string | undefined): boolean | undefined {
  if (arg?.toLowerCase?.() === 'false') return false;
  if (arg?.toLowerCase?.() === 'true') return true;
  return undefined;
}

export function getThresholdName(
  realtimeData: RealtimeData | undefined,
): AlertThresholdKey | undefined {
  if (!realtimeData) {
    return undefined;
  }

  const [subcomponentCategory, name] = realtimeData.subcomponent.split('#');

  if (subcomponentCategory !== 'AlertMonitor') {
    return undefined;
  }

  return name as AlertThresholdKey;
}

export function getDefaultAlertThresholds(
  parameterTelemetry: RealtimeData,
): AlertThresholds {
  const getAlertThreshold = (key: AlertThresholdKey) => ({
    ...parameterTelemetry,
    dataType: 'status',
    subcomponent: `AlertMonitor#${key}`,
    value: '–',
    valueType: 'string',
  });

  return {
    alertHigh: getAlertThreshold('alertHigh'),
    alertLow: getAlertThreshold('alertLow'),
    warnHigh: getAlertThreshold('warnHigh'),
    warnLow: getAlertThreshold('warnLow'),
    alertState: {
      ...parameterTelemetry,
      value: AlertState.Idle,
      dataType: 'status',
      unit: '',
      valueType: 'string',
      subcomponent: 'alertState',
    },
    enabled: {
      ...parameterTelemetry,
      value: 'false',
      valueType: 'boolean',
      dataType: 'status',
      subcomponent: `AlertMonitor#enabled`,
      unit: '',
    },
  };
}

export function getIconNameForParameterName(parameterName?: string): Icons {
  if (!parameterName) {
    return 'placeholder' as Icons;
  }

  if (parameterName.startsWith('Temperature (Probe)')) {
    return 'temperature' as Icons;
  }

  return parameterName.replace(' ', '-').normalize('NFKD').toLowerCase() as Icons;
}
