import { Device } from '@eppendorf/vnls-inventory-service-types';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { DeviceNameCell } from '$components/device/name-cell';

export function NotificationDevicesInfo({
  devices,
  fetchError,
}: {
  devices: Device[] | null;
  fetchError: boolean;
}): ReactElement {
  const { t } = useTranslation();
  const noDevices = devices?.length === 0;

  return (
    <div className="devices row">
      <div className="col-3">
        <h3 className="body-bold"> {t('notificationDetail.devices')}</h3>
      </div>
      <div className="device-list col-9 ">
        {devices &&
          devices.map((device, index) => (
            <div
              key={device.serialNumber}
              className={
                index === devices.length - 1
                  ? 'flex flex__ai--center'
                  : 'flex flex__ai--center p-bottom-m'
              }
            >
              <DeviceNameCell device={device} />
            </div>
          ))}
        {fetchError && !noDevices && (
          <p className="color-red-500">
            {t('notificationDetail.errorLoadingDevices')}{' '}
            {t(`notificationDetail.tryAgain`)}
          </p>
        )}
        {noDevices && (
          <p className="color-red-500">
            {t('notificationDetail.noDevicesFound')} {t(`notificationDetail.tryAgain`)}
          </p>
        )}
      </div>
    </div>
  );
}
