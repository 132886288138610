import { Badge } from '@eppendorf/vnls-react-components';
import { useTranslation } from 'react-i18next';

export enum RouterStatus {
  ONLINE = 'online',
  WEAK_SIGNAL = 'weaksignal',
  TIMEOUT = 'timeout',
  BLOCKED = 'blocked',
  OFFLINE = 'offline',
}

interface Props {
  status: string;
}

export function RouterStatusBadge({ status }: Props) {
  const { t } = useTranslation();

  switch (status) {
    case RouterStatus.BLOCKED:
      return (
        <Badge color="blue" size="small">
          {t('deviceDetail.routerStatus.blocked')}
        </Badge>
      );
    case RouterStatus.ONLINE:
      return (
        <Badge color="green" size="small">
          {t('deviceDetail.routerStatus.stable')}
        </Badge>
      );
    case RouterStatus.TIMEOUT:
    case RouterStatus.OFFLINE:
      return <Badge size="small">{t('deviceDetail.routerStatus.noConnection')}</Badge>;
    case RouterStatus.WEAK_SIGNAL:
      return (
        <Badge color="red" size="small">
          {t('deviceDetail.routerStatus.unstable')}
        </Badge>
      );
    default:
      return '';
  }
}
