import './monitoring-parameter.scss';

import { DeviceParameterConfig } from '@eppendorf/vnls-application-configuration-service-types';
import {
  ButtonBase,
  Icon,
  IconSizeClasses,
  Threshold,
} from '@eppendorf/vnls-react-components';
import { AlertState, RealtimeData } from '@eppendorf/vnls-telemetry-and-events-types';
import cn from 'classnames';
import React, { useState } from 'react';

import { AlertStatus } from '$features/monitoring/alert-status/alert-status';
import { alertThresholdsAvailable } from '$features/monitoring/monitoring.utils';
import { MonitoringParameterValue } from '$features/monitoring/parameters/monitoring-parameter-value';
import { MonitoringParameterValueAge } from '$features/monitoring/parameters/monitoring-parameter-value-age';
import {
  ThresholdsFormProfile,
  ThresholdsForm,
} from '$features/monitoring/parameters/thresholds-form';
import { AlertThresholds } from '$features/monitoring/parameters/types/alert-thresholds';
import { applyFractionDigits } from '$features/monitoring/shared/formatting.utils';
import { ParameterHeadline } from '$features/monitoring/shared/parameter-headline';

export interface MonitoringParameterProps {
  name: string;
  parameterConfig?: DeviceParameterConfig;
  processValue?: Partial<RealtimeData>;
  setPoint?: Partial<RealtimeData>;
  alertStatus?: AlertState;
  alertThresholds?: AlertThresholds;
  fractionalDigits?: number | null;
  isSensor: boolean;
  computeThresholds: (editable: boolean) => Threshold[];
  onSubmitThresholds: (data: ThresholdsFormProfile) => Promise<AlertThresholds>;
}

export function MonitoringParameter({
  name,
  parameterConfig,
  processValue,
  setPoint,
  alertStatus,
  alertThresholds,
  isSensor,
  computeThresholds,
  onSubmitThresholds,
}: MonitoringParameterProps) {
  const [showThreshholds, setShowThresholds] = useState(false);

  const thresholdsAvailable = alertThresholdsAvailable(alertThresholds);

  function handleExpand(): void {
    if (thresholdsAvailable) {
      setShowThresholds(!showThreshholds);
    }
  }

  function handleKeyUp(event: React.KeyboardEvent): void {
    if (event.key === ' ' || event.key === 'Enter') {
      handleExpand();
    }
  }

  return (
    <div className="box monitoring-parameter-box bg-gray-50">
      <div
        role="button"
        tabIndex={0}
        className={cn('flex flex__jc--space-between flex__ai--center p-xxxl', {
          interactive: thresholdsAvailable,
        })}
        onClick={handleExpand}
        onKeyUp={handleKeyUp}
      >
        <div className="flex flex__jc--flex-end flex__dir--column">
          <ParameterHeadline parameterName={name} parameterConfig={parameterConfig} />
        </div>
        <div className="flex flex__jc--flex-end">
          <div className="flex flex__dir--column flex__ai--flex-end">
            <MonitoringParameterValueAge timestamp={processValue?.timestamp} />
            {!showThreshholds && (
              <>
                <div className="flex flex__ai--center">
                  <AlertStatus alertStatus={alertStatus} className="m-right-m" />
                  <MonitoringParameterValue
                    data-testid={`process-value-${processValue?.subject}-${processValue?.subcomponent}`}
                    value={processValue?.value}
                    unit={processValue?.unit}
                    valueType={processValue?.valueType}
                    status={alertStatus}
                    formatter={applyFractionDigits(processValue?.fractionalDigits)}
                    className="font-size-4xl font-weight-bold"
                  />
                </div>
                <MonitoringParameterValue
                  value={setPoint?.value}
                  unit={setPoint?.unit}
                  valueType={setPoint?.valueType}
                  formatter={applyFractionDigits(setPoint?.fractionalDigits)}
                  className="font-size-xl"
                />
              </>
            )}
          </div>
          {alertThresholds?.enabled !== undefined && (
            <ButtonBase className="m-left-l">
              <Icon
                name={showThreshholds ? 'down' : 'right'}
                size={IconSizeClasses.XSmall}
              />
            </ButtonBase>
          )}
        </div>
      </div>
      {showThreshholds && (
        <ThresholdsForm
          alertStatus={alertStatus}
          processValue={processValue}
          isSensor={isSensor}
          alertThresholds={alertThresholds}
          computeThresholds={computeThresholds}
          onSubmitThresholds={onSubmitThresholds}
        />
      )}
    </div>
  );
}
