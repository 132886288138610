import { Button, Dialog, Icon, IconSizeClasses } from '@eppendorf/vnls-react-components';
import { FunctionComponentElement, useRef } from 'react';
import { useTranslation } from 'react-i18next';

export interface UnsavedDialogProps {
  onContinueEditing: () => void;
}

export function UnsavedDialog({
  onContinueEditing,
}: UnsavedDialogProps): FunctionComponentElement<UnsavedDialogProps> {
  const { t } = useTranslation();
  const continueButtonRef = useRef<null | HTMLButtonElement>(null);

  return (
    <>
      <div className="flex flex__dir--column flex__ai--center text-align-center">
        <Icon
          name="warning"
          className="bg-orange-400 m-bottom-xxl"
          size={IconSizeClasses.XLarge}
        />
        <Dialog.Title>{t('shared.unsavedChangesWarning')}</Dialog.Title>
        <Dialog.Description className="m-bottom-xxs">
          {t('shared.unsavedEditDescription')}
        </Dialog.Description>
      </div>
      <Dialog.Actions className="m-top-xxl">
        <Button ref={continueButtonRef} onClick={onContinueEditing} variant="secondary">
          {t('shared.continueEdit')}
        </Button>

        <Dialog.Close>
          <Button variant="primary"> {t('shared.closeWithoutSaving')}</Button>
        </Dialog.Close>
      </Dialog.Actions>
    </>
  );
}
