import { Device } from '@eppendorf/vnls-inventory-service-types';
import {
  Button,
  Checkbox,
  Dialog,
  DotsLoader,
  Icon,
  IconSizeClasses,
  Label,
  SortableTable,
} from '@eppendorf/vnls-react-components';
import { CellContext, createColumnHelper } from '@tanstack/react-table';
import { FunctionComponentElement, ReactNode, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useDeviceModels } from '$components/app-config/useDeviceModels';
import { DeviceNameCell } from '$components/device/name-cell';
import { useDevicesWithQueryParams } from '$features/devices/devices.api';
import { AddNotificationRuleScreenProps } from '$features/notifications/types';

type FormattedDevice = Device & { id: string };

const PAGE_INDEX = 1;
const PAGE_SIZE = 500;

function DeviceCellInfo({ primary, secondary }: { primary: string; secondary: string }) {
  return (
    <div>
      <h3 className="cap-first font-size-m p-bottom-xxs">{primary}</h3>
      {secondary && <p className=" cap-first color-gray-700 font-size-m">{secondary}</p>}
    </div>
  );
}

interface DevicesScreenProps extends AddNotificationRuleScreenProps {
  onBack: () => void;
  onContinue: () => void;
  screenHasErrors: boolean;
}

export function DevicesScreen({
  formControl,
  onBack,
  onContinue,
  screenHasErrors,
}: DevicesScreenProps): FunctionComponentElement<ReactNode> {
  const { t } = useTranslation();
  const { register, getValues, setValue, watch } = formControl;
  const models = useDeviceModels(getValues().selectedDeviceType);

  const { data, isLoading } = useDevicesWithQueryParams({
    page: PAGE_INDEX,
    pageSize: PAGE_SIZE,
    connectionStatus: 'paired',
    models,
  });

  const devicesList = useMemo(
    () =>
      (data?.data ?? []).map((device) => ({
        id: device.serialNumber,
        ...device,
      })),
    [data],
  );

  function removeDevice(device: FormattedDevice): void {
    const updatedDevices = getValues('devices')?.filter((u) => u.id !== device.id);
    setValue('devices', updatedDevices, {
      shouldValidate: true,
      shouldDirty: true,
    });
  }

  function removeAllDevices(devices: FormattedDevice[]): void {
    setValue(
      'devices',
      getValues('devices')?.filter(
        (device) =>
          !devices.some(
            (currentSelectedDevice) => currentSelectedDevice.id === device.id,
          ),
      ),
      {
        shouldValidate: true,
        shouldDirty: true,
      },
    );
  }

  const handleHeaderCheckBoxClick = (): void => {
    if (!devicesList) return;

    const currentSelectedDevices = devicesList.filter((device) =>
      getValues('devices')?.some((selectedDevice) => selectedDevice.id === device.id),
    );

    if (currentSelectedDevices.length === devicesList.length) {
      removeAllDevices(devicesList);
    } else {
      setValue(
        'devices',
        [
          ...getValues('devices'),
          ...devicesList.map((device) => ({ id: device.id, value: device.serialNumber })),
        ],
        {
          shouldValidate: true,
          shouldDirty: true,
        },
      );
    }
  };

  function handleDeviceClick(deviceToAddOrRemove: FormattedDevice): void {
    const isDeviceSelected = getValues('devices')?.some(
      (u) => u.id === deviceToAddOrRemove.id,
    );

    if (isDeviceSelected) {
      removeDevice(deviceToAddOrRemove);
    } else {
      setValue(
        'devices',
        [
          ...getValues('devices'),
          { id: deviceToAddOrRemove.id, value: deviceToAddOrRemove.serialNumber },
        ],
        {
          shouldValidate: true,
          shouldDirty: true,
        },
      );
    }
  }

  const columnHelper = createColumnHelper<FormattedDevice>();
  const columns = [
    {
      id: 'checked',
      // eslint-disable-next-line react/no-unstable-nested-components -- we want this here
      header: () => {
        const selectedDevices = devicesList.filter((d) =>
          watch('devices')?.some((device) => device.id === d.id),
        );
        return (
          <Checkbox
            onChange={handleHeaderCheckBoxClick}
            isIndeterminate={
              selectedDevices.length > 0 && selectedDevices.length < devicesList.length
            }
            checked={selectedDevices.length === devicesList.length}
          />
        );
      },
      // eslint-disable-next-line react/no-unstable-nested-components -- we want this here
      cell: ({ row }: CellContext<FormattedDevice, string>) => (
        <Checkbox
          checked={watch('devices')?.some((u) => u.id === row.original.id)}
          onChange={() => handleDeviceClick(row.original)}
        />
      ),
    },
    columnHelper.accessor('name', {
      id: 'device',
      header: () => 'Device',
      // eslint-disable-next-line react/no-unstable-nested-components -- we want this here
      cell: ({ row: { original: rowData } }: CellContext<FormattedDevice, string>) => (
        <div className="flex gap-s flex__ai--center">
          <DeviceNameCell device={rowData} />
        </div>
      ),
    }),
    columnHelper.accessor('location', {
      id: 'location',
      header: () => 'Location',
      // eslint-disable-next-line react/no-unstable-nested-components -- we want this here
      cell: ({
        row: { original: rowData },
      }: CellContext<FormattedDevice, FormattedDevice['location']>) => (
        <DeviceCellInfo
          primary={rowData.location?.room || ''}
          secondary={rowData.location?.building || ''}
        />
      ),
    }),
  ];

  useEffect(() => {
    register('devices', {
      validate: (devices) => devices?.length > 0,
    });
  }, [register]);

  useEffect(() => {
    if (isLoading) return;
    const devicesIds = new Set(devicesList.map((device) => device.id));
    const filteredDevices =
      getValues('devices')?.filter((d) => devicesIds.has(d.id)) ?? [];

    setValue('devices', filteredDevices, { shouldValidate: true, shouldDirty: true });
  }, [devicesList, isLoading]);

  return isLoading ? (
    <DotsLoader className="m-top-10xl m-bottom-10xl" />
  ) : (
    <>
      {!!devicesList.length && (
        <>
          <Label htmlFor="devices" className="m-top-xxl cap-first font-size-l">
            {`${t('notificationRules.addRuleSteps.chooseDevices.labelPrefix')} ${getValues().selectedDeviceType}`}
          </Label>
          <SortableTable<FormattedDevice[], FormattedDevice>
            data={devicesList}
            columns={columns}
            onRowClick={(device) => handleDeviceClick(device)}
          />
        </>
      )}
      {!devicesList.length && (
        <div className="flex flex__dir--column flex__ai--center p-xl w-full h-full">
          <span className="m-bottom-l">
            <Icon
              name="no-results"
              size={IconSizeClasses.Large}
              className="bg-gray-500"
            />
          </span>
          <div className="font-size-xl font-weight-medium m-bottom-s">
            {t('notificationRules.error.noDevicesFound')}
          </div>
        </div>
      )}

      <Dialog.Actions>
        <Button variant="secondary" onClick={onBack}>
          {t('shared.back')}
        </Button>

        <Button onClick={onContinue} disabled={isLoading || screenHasErrors}>
          {t('shared.continue')}
        </Button>
      </Dialog.Actions>
    </>
  );
}
