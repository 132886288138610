import {
  Button,
  Dialog,
  Icon,
  IconProps,
  IconSizeClasses,
} from '@eppendorf/vnls-react-components';
import { FunctionComponentElement, ReactNode, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { useAutoFocus } from '$shared/custom-hooks/useAutoFocus';

export enum ConfirmDialogIconType {
  'success',
  'error',
}

export interface ConfirmationDialogProps {
  title: string;
  dialogDescription: ReactNode;
  iconType: ConfirmDialogIconType;
}

function iconByType(type: ConfirmDialogIconType): FunctionComponentElement<IconProps> {
  if (type === ConfirmDialogIconType.success)
    return (
      <Icon
        name="success"
        className="bg-green-500 m-bottom-xxl"
        size={IconSizeClasses.XLarge}
      />
    );
  return (
    <Icon
      name="failed"
      className="bg-red-500 m-bottom-xxl"
      size={IconSizeClasses.XLarge}
    />
  );
}

export function ConfirmationDialog({
  title,
  dialogDescription,
  iconType,
}: ConfirmationDialogProps): FunctionComponentElement<ConfirmationDialogProps> {
  const { t } = useTranslation();
  const closeButtonRef = useRef<null | HTMLButtonElement>(null);

  useAutoFocus(closeButtonRef);
  return (
    <>
      <div className="flex flex__dir--column flex__ai--center text-align-center">
        {iconByType(iconType)}
        <Dialog.Title>{title}</Dialog.Title>
        <Dialog.Description className="m-bottom-xxs">
          {dialogDescription}
        </Dialog.Description>
      </div>
      <Dialog.Actions className="m-top-xxl">
        <Dialog.Close>
          <Button ref={closeButtonRef} className="btn--solid">
            {t('shared.close')}
          </Button>
        </Dialog.Close>
      </Dialog.Actions>
    </>
  );
}
