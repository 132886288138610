import { HasChildren } from '@eppendorf/vnls-react-components';

export interface ChartLegendParam {
  value: string;
  color: string;
  show: boolean;
}

function Parameter({ value, color }: Omit<ChartLegendParam, 'show'>) {
  return (
    <small className="flex flex__ai--center">
      <div
        role="status"
        aria-label="no-alerts"
        className="circle"
        style={{ backgroundColor: color }}
      />
      {value}
    </small>
  );
}

function ChartLegendWrapper({ children }: HasChildren) {
  return (
    <div className="font-size-s flex flex__ai--center gap-l p-x-l m-y-xs">{children}</div>
  );
}

export const ChartLegend = Object.assign(ChartLegendWrapper, { Parameter });
