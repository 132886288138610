import {
  type DeviceParameterConfig,
  Tags,
} from '@eppendorf/vnls-application-configuration-service-types';
import {
  type HasClassName,
  Icon,
  IconSizeClasses,
} from '@eppendorf/vnls-react-components';
import cn from 'classnames';

import { getIconNameForParameterName } from '$features/monitoring/monitoring.utils';

interface ParameterHeadlineProps extends HasClassName {
  parameterName?: string;
  parameterConfig?: DeviceParameterConfig;
  unit?: string | null;
}

export function ParameterHeadline({
  className,
  parameterName,
  parameterConfig,
  unit,
}: ParameterHeadlineProps) {
  return (
    <div className={cn('flex flex__ai--center', className)}>
      <Icon
        name={getIconNameForParameterName(parameterName)}
        size={IconSizeClasses.Small}
      />
      <span className="p-left-m whitespace-nowrap">
        {parameterName} {unit && `(${unit})`}
      </span>
      {parameterConfig?.tags?.includes(Tags.Extension) && (
        <Icon name="sense-sensor" size={IconSizeClasses.XSmall} />
      )}
    </div>
  );
}
