import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { Overview } from '$components/overview-box/overview-box';
import { MonitoringList } from '$features/monitoring/monitoring-list';

export function MonitoringOverview(): ReactElement {
  const { t } = useTranslation();

  return (
    <Overview>
      <Overview.Header>
        <h1 className="title">{t('monitoring.overview')}</h1>
      </Overview.Header>

      <Overview.Body>
        <MonitoringList />
      </Overview.Body>
    </Overview>
  );
}
