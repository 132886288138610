import { Button, Icon, IconSizeClasses } from '@eppendorf/vnls-react-components';
import { Trans } from 'react-i18next';

export type NoEventsToShowProps = {
  onResetSearch: () => void;
};

export function NoEventsToShow({ onResetSearch }: NoEventsToShowProps) {
  return <div className="flex flex__dir--column flex__ai--center p-xl w-full h-full">
    <span className="m-bottom-l">
      <Icon name="no-results" size={IconSizeClasses.Large} className="bg-gray-500" />
    </span>

    <div className="font-size-xl font-weight-medium m-bottom-s">
      <Trans i18nKey="monitoringDetail.eventsDetail.noEventsFound" />
    </div>
    <div className="m-bottom-xxl">
      <Trans i18nKey="monitoringDetail.eventsDetail.changeSearchFilters" />
    </div>

    <Button onClick={onResetSearch}>
      <Trans i18nKey="monitoringDetail.eventsDetail.resetSearch" />
    </Button>
  </div>
}
