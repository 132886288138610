import { Button } from '@eppendorf/vnls-react-components';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { CookieElementWrapper } from '$components/cookie-policy/cookie-element-wrapper';
import { FooterText } from '$components/footer/footer-text';

// This is a usual button instead of a button provided by OneTrust.
// Using the button from OneTrust script produces side effects such as:
// - The button is not styled correctly (this can be solved by removing the id="ot-sdk-btn" from the button)
// - OneTrust sets localization string automatically, which is not working in some cases (after soft reload, fixed by hard reload)
// - The Privacy Preference Center close button (x) does not hide/close the overlay that blocks the entire page

export function CookieInfoButton(): ReactElement {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate('/cookie-list');
  };

  return (
    <CookieElementWrapper>
      <Button className="p-x-s" onClick={handleNavigate} type="button" variant="tertiary">
        <FooterText>{t('footer.cookieInfo')}</FooterText>
      </Button>
    </CookieElementWrapper>
  );
}
