import { Button } from '@eppendorf/vnls-react-components';
import { HTMLAttributeAnchorTarget, ReactElement } from 'react';
import { Link } from 'react-router-dom';

import { FooterText } from '$components/footer/footer-text';

interface Props {
  navigationPath: string;
  text: string;
  target?: HTMLAttributeAnchorTarget;
}

export function FooterLink({ navigationPath, text, target }: Props): ReactElement {
  return (
    <Button className="p-x-s" type="button" variant="tertiary">
      <Link to={navigationPath} target={target ?? '_blank'}>
        <FooterText>{text}</FooterText>
      </Link>
    </Button>
  );
}
