import { HasClassName, Tooltip, TooltipProps } from '@eppendorf/vnls-react-components';
import { AlertState, RealtimeData } from '@eppendorf/vnls-telemetry-and-events-types';
import { ReactElement } from 'react';

import { AlertStatus } from '$features/monitoring/alert-status/alert-status';
import { findBySubjectAndComponent } from '$features/monitoring/monitoring.utils';

function isAlert(maybeAlert: string | undefined): maybeAlert is AlertState {
  if (!maybeAlert || !Object.keys(AlertState).includes(maybeAlert)) {
    return false;
  }

  return maybeAlert === AlertState.Alert || maybeAlert === AlertState.Warn;
}

export interface UnitAlertStateProps extends HasClassName {
  realtimeArray: (RealtimeData | null)[];
  side: TooltipProps['side'];
}

export function UnitAlertState({
  realtimeArray,
  className,
  side,
}: UnitAlertStateProps): ReactElement {
  const status = findBySubjectAndComponent(realtimeArray, 'Unit', 'alertState');

  if (isAlert(status?.value)) {
    return <AlertStatus className={className} alertStatus={status?.value} />;
  }

  return (
    <Tooltip
      trigger={
        <div role="status" aria-label="no-alerts" className="circle circle--green" />
      }
      side={side}
    >
      OK
    </Tooltip>
  );
}
