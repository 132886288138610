import i18next from 'i18next';

export function createValueElement(
  formatter: Intl.NumberFormat,
  valueType?: string,
  value?: string,
): string | undefined {
  switch (valueType) {
    case 'double':
      return formatter.format(Number(value));
    default:
      return value;
  }
}

export const applyFractionDigits = (fd?: number | null, useGrouping: boolean = true) =>
  new Intl.NumberFormat(i18next.resolvedLanguage, {
    maximumFractionDigits: fd ?? 1,
    minimumFractionDigits: fd ?? 1,
    useGrouping
  });
