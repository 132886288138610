import { User } from '@eppendorf/vnls-user-tenant-utils';
import { FunctionComponentElement } from 'react';

import { DeleteConfirmScreen } from '$features/delete-user/delete-user-dialog/screens/delete-confirm-screen';
import { DeleteErrorScreen } from '$features/delete-user/delete-user-dialog/screens/delete-error-screen';
import { DeleteSuccessScreen } from '$features/delete-user/delete-user-dialog/screens/delete-success-screen';

export enum UserDeleteScreens {
  'DeleteConfirmScreen',
  'DeleteSuccessScreen',
  'DeleteErrorScreen',
}

export interface DeleteUserDialogContentProps {
  selectedUser: User;
  activeScreen: UserDeleteScreens;
  setActiveScreen: (screen: UserDeleteScreens) => void;
}

export function DeleteUserDialogContent({
  activeScreen,
  setActiveScreen,
  selectedUser,
}: DeleteUserDialogContentProps): FunctionComponentElement<DeleteUserDialogContentProps> {
  function navigateToScreen(screen: UserDeleteScreens): void {
    setActiveScreen(screen);
  }

  return (
    <>
      {activeScreen === UserDeleteScreens.DeleteConfirmScreen && (
        <DeleteConfirmScreen
          onSubmit={() => navigateToScreen(UserDeleteScreens.DeleteSuccessScreen)}
          onError={() => navigateToScreen(UserDeleteScreens.DeleteErrorScreen)}
          selectedUser={selectedUser}
        />
      )}

      {activeScreen === UserDeleteScreens.DeleteSuccessScreen && (
        <DeleteSuccessScreen user={selectedUser} />
      )}
      {activeScreen === UserDeleteScreens.DeleteErrorScreen && (
        <DeleteErrorScreen user={selectedUser} />
      )}
    </>
  );
}
