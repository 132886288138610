import { Device } from '@eppendorf/vnls-inventory-service-types';
import { FunctionComponentElement } from 'react';

import { useFeatureIsEnabled } from '$components/feature-toogle/feature-is-enabled';
import { ErrorScreen } from '$features/add-device/device-dialog/screens/error-screen';
import { SubmitScreen } from '$features/add-device/device-dialog/screens/submit-screen';
import { SuccessScreen } from '$features/add-device/device-dialog/screens/success-screen';
import { WaitingScreen } from '$features/add-device/device-dialog/screens/waiting-screen';
import { useReregisterDevice } from '$features/devices/devices.api';

export type AddDeviceDialogScreens =
  | 'submitScreen'
  | 'waitingScreen'
  | 'successScreen'
  | 'errorScreen';

export interface DeviceDialogContentProps {
  activeScreen: AddDeviceDialogScreens;
  serialNumber?: string;
  onSerialNumberChange: (serialNumber: string) => void;
  setActiveScreen: (screen: AddDeviceDialogScreens) => void;
  device?: Device;
}

export function DeviceDialogContent({
  activeScreen,
  serialNumber,
  onSerialNumberChange,
  setActiveScreen,
  device,
}: DeviceDialogContentProps): FunctionComponentElement<DeviceDialogContentProps> {
  const retryEnabled = useFeatureIsEnabled('retryRegistration');
  const { mutateAsync: reregisterDevice } = useReregisterDevice();

  function navigateToWaitingScreen(serial: string): void {
    setActiveScreen('waitingScreen');
    onSerialNumberChange(serial);
  }

  function navigateToAddedScreen(): void {
    setActiveScreen('successScreen');
  }

  function navigateToErrorScreen(): void {
    setActiveScreen('errorScreen');
  }

  function tryAgain(): void {
    if (retryEnabled) {
      if (!serialNumber) {
        return;
      }

      reregisterDevice({ manufacturer: 'Eppendorf', serialNumber });
      setActiveScreen('waitingScreen');
    } else {
      setActiveScreen('submitScreen');
      onSerialNumberChange('');
    }
  }

  return (
    <>
      {activeScreen === 'submitScreen' && (
        <SubmitScreen onSubmit={(s) => navigateToWaitingScreen(s)} />
      )}
      {activeScreen === 'waitingScreen' && serialNumber && (
        <WaitingScreen
          onSubmit={() => navigateToAddedScreen()}
          serialNumber={serialNumber}
          onError={() => navigateToErrorScreen()}
        />
      )}
      {activeScreen === 'errorScreen' && serialNumber && (
        <ErrorScreen onSubmit={() => tryAgain()} device={device} />
      )}
      {activeScreen === 'successScreen' && <SuccessScreen device={device} />}
    </>
  );
}
