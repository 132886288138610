import { Logo } from '@eppendorf/vnls-react-components';
import { PropsWithChildren, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { Footer } from '$components/footer';
import '../landing-page.module.scss';

export function MasterLayout({ children }: PropsWithChildren): ReactElement {
  const { t } = useTranslation();

  const classicVisionizeUrl = import.meta.env.PUBLIC_CLASSIC_VISIONIZE_URL;

  return (
    <div className="landing-wrapper flex flex__dir--column flex__jc--space-between  p-top-xxxl ">
      <div className="flex flex__ai--center flex__jc--center p-left-6xl p-right-6xl p-bottom-xxxl">
        <div className="landing-container box flex p-0">
          <div className="text-block p-xxxl flex flex__dir--column">
            <div>
              <div className="flex flex__jc--center">
                <Logo isExpanded />
              </div>
              <h1 className="title m-bottom-xxl m-top-xxl font-size-l font-weight-bold">
                {t('landing.title')}
                <span className="font-size-s">{t('landing.symbol')}</span>{' '}
                {t('landing.labSuiteExperienceText')}
              </h1>
              {children}
            </div>
            {classicVisionizeUrl && (
              <p className="font-size-s font-weight-regular m-top-4xl">
                {t('landing.classicVisionizeHint')}
                <span className="font-size-xxs">{t('landing.symbol')}</span>{' '}
                {t('landing.labSuiteExperienceQuestion')}{' '}
                <a href={classicVisionizeUrl} className="color-blue-500">
                  {t('landing.classicVisionizeLink')}
                </a>
              </p>
            )}
          </div>
          <div className="img-block" />
        </div>
      </div>

      <Footer />
    </div>
  );
}
