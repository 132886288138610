import type {
  DeviceModels,
  DeviceTypeConfig,
} from '@eppendorf/vnls-application-configuration-service-types';
import { QueryClient } from '@tanstack/react-query';

import { prepopulateCache } from '$shared/utils/react-query.utils';

import {
  createGetDeviceTypeConfigsQuery,
  createGetFeatureToggleQuery,
} from '$components/app-config/app-config.api';

async function deviceTypesLoader(qc: QueryClient): Promise<null> {
  return prepopulateCache<DeviceTypeConfig<DeviceModels>[]>(
    qc,
    createGetDeviceTypeConfigsQuery(),
  );
}

async function featureToggleLoader(qc: QueryClient): Promise<null> {
  return prepopulateCache<string[]>(qc, createGetFeatureToggleQuery());
}

export async function appConfigLoader(qc: QueryClient): Promise<null> {
  return Promise.all([deviceTypesLoader(qc), featureToggleLoader(qc)]).then(() => null);
}
