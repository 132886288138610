import { Button } from '@eppendorf/vnls-react-components';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { useAuthContext } from '$shared/auth/use-auth-context';

import { MasterLayout } from '../master-layout/master-layout';

export function SigninAccountPage(): ReactElement {
  const { signIn } = useAuthContext();
  const { t } = useTranslation();

  const createAccountUrl = import.meta.env.PUBLIC_CREATE_ACCOUNT_URL;

  return (
    <MasterLayout>
      <p className="m-bottom-xxl font-size-m font-weight-regular">
        {t('landing.signInHint')}
      </p>
      <Button
        onClick={signIn}
        type="button"
        variant="primary"
        className="m-bottom-5xl w-full"
      >
        {t('landing.signInButton')}
      </Button>
      {createAccountUrl && (
        <>
          <p className="m-bottom-xxl font-size-m font-weight-regular">
            {t('landing.signUpHint')}
          </p>
          <a
            className="btn-base btn w-full btn--secondary btn--info btn--medium m-bottom-7xl"
            href={createAccountUrl}
          >
            {t('landing.createNewAccountHint')}
          </a>
        </>
      )}
    </MasterLayout>
  );
}
