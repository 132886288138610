import { DotsLoader, SortableTable } from '@eppendorf/vnls-react-components';
import { useQueries } from '@tanstack/react-query';
import { createColumnHelper } from '@tanstack/react-table';
import cn from 'classnames';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { RouterStatus, RouterStatusBadge } from '$components/device/router-status-badge';
import { SignalStrengthIcon } from '$components/device/signal-strength-icon';
import { RouterNameCell } from '$features/devices/associated-router/router-list-name-cell';
import {
  createDeviceQuery,
  useGetMonitorAssociations,
} from '$features/devices/devices.api';
import { AssociatedRouterModel } from '$features/devices/devices.types';

import styles from './associated-router.module.scss';

export function AssociatedRouter() {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  const [columnsData, setColumnsData] = useState<AssociatedRouterModel[]>([]);

  const { data = [], isLoading: isSenseMonitorLoading } = useGetMonitorAssociations(
    searchParams.get('serialNumber') ?? '',
  );

  const queryResults = useQueries({
    queries: data.map((router) =>
      createDeviceQuery({
        manufacturer: 'Eppendorf',
        serialNumber: router.gatewaySerialNumber,
        enabled: !isSenseMonitorLoading,
      }),
    ),
  });

  const allQueriesCompleted =
    !isSenseMonitorLoading &&
    queryResults.length === data.length &&
    queryResults.every((result) => result.isSuccess || result.isError);

  /* eslint-disable security/detect-object-injection -- Improve in future */
  useEffect(() => {
    if (allQueriesCompleted) {
      const mappedData: AssociatedRouterModel[] = [];

      for (let i = 0; i < queryResults.length; i += 1) {
        const availableClient = data[i];

        const router: AssociatedRouterModel = {
          gatewaySerialNumber: data[i].gatewaySerialNumber || '',
          model: queryResults[i].data?.model ?? 'Eppendorf',
          connectionStatus:
            availableClient.registrationState === 'notWhitelisted'
              ? RouterStatus.BLOCKED
              : availableClient.connectionStatus,
          signalStrength: availableClient.signalStrength,
          name: queryResults[i].data?.name ?? data[i].gatewaySerialNumber,
        };
        mappedData.push(router);
      }

      setColumnsData(mappedData);
    }
  }, [allQueriesCompleted]);
  /* eslint-enable security/detect-object-injection */

  const columnHelper = createColumnHelper<AssociatedRouterModel>();

  const columns = useMemo(
    () => [
      columnHelper.accessor('connectionStatus', {
        header: () => t('deviceDetail.routerTable.connection'),
        // eslint-disable-next-line react/no-unstable-nested-components -- accepted until we figure out a better way
        cell: ({ row: { original: associatedRouter } }) => (
          <div className="flex__ai--center flex__jc--space-between">
            <RouterStatusBadge status={associatedRouter.connectionStatus} />
            <SignalStrengthIcon signalStrength={associatedRouter.signalStrength} />
          </div>
        ),
      }),
      columnHelper.accessor('gatewaySerialNumber', {
        header: () => t('deviceDetail.routerTable.router'),
        // eslint-disable-next-line react/no-unstable-nested-components -- accepted until we figure out a better way
        cell: ({ row: { original: associatedRouter } }) => (
          <RouterNameCell
            manufacturer={associatedRouter.model}
            routerName={associatedRouter.name || associatedRouter.gatewaySerialNumber}
            icon="sense-gateway"
          />
        ),
      }),
    ],
    [columnsData],
  );

  if (isSenseMonitorLoading || !allQueriesCompleted) {
    return <DotsLoader className="m-top-l" />;
  }

  if (columnsData.length === 0) {
    return (
      <div className="sub-title m-top-l">
        {t('deviceDetail.routerTable.emptyMessage')}
      </div>
    );
  }

  return (
    <div>
      <div className={cn(styles.pairingInfo)}>
        <SortableTable<AssociatedRouterModel[], AssociatedRouterModel>
          data={columnsData}
          columns={columns}
        />
      </div>
    </div>
  );
}
