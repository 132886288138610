import type { HasClassName } from '@eppendorf/vnls-react-components';
import type { RealtimeData } from '@eppendorf/vnls-telemetry-and-events-types';
import type { ReactElement } from 'react';

import { capitalize } from '$shared/utils/string.utils';

import { DoorOrLidStatus } from '$components/door-or-lid-status/door-or-lid-status';
import { findBySubjectAndComponent } from '$features/monitoring/monitoring.utils';

export interface DoorOrLidStatusWrapperProps extends HasClassName {
  realtimeArray: (RealtimeData | null)[];
  fallback?: string;
}

export function DoorOrLidStatusWrapper({
  realtimeArray,
  fallback,
  className,
}: DoorOrLidStatusWrapperProps): ReactElement {
  const doorOrLidSubject: 'door' | 'lid' = findBySubjectAndComponent(
    realtimeArray,
    'Lid',
    'state',
  )
    ? 'lid'
    : 'door';

  const doorOrLidState = findBySubjectAndComponent(
    realtimeArray,
    capitalize(doorOrLidSubject),
    'state',
  );

  return (
    <DoorOrLidStatus
      className={className}
      state={doorOrLidState?.value}
      subject={doorOrLidSubject}
      fallback={fallback}
    />
  );
}
