import { Device } from '@eppendorf/vnls-inventory-service-types';
import { Icon, IconSizeClasses } from '@eppendorf/vnls-react-components';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { useDeviceType } from '$components/app-config/useDeviceType';
import { searchDeviceIcon } from '$features/devices/devices.utils';

export function DeviceTypeCell({ device }: { device: Device | null }): ReactElement {
  const deviceType = useDeviceType(device)?.toLowerCase();
  const { t } = useTranslation();

  return (
    <div className="flex gap-s flex__jc--start flex__ai--center">
      <div className="w-3xs h-3xs flex flex__jc--center flex__ai--center">
        <Icon
          size={IconSizeClasses.Small}
          name={searchDeviceIcon(deviceType as string)}
        />
      </div>
      {/* eslint-disable-next-line @typescript-eslint/no-explicit-any -- we know it exists */}
      {t(`deviceType.${deviceType}` as any)}
    </div>
  );
}
