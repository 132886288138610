import { UserRole } from '@eppendorf/vnls-user-tenant-utils';
import { t } from 'i18next';

export const roleDisplayNameMap: Record<UserRole, string> = {
  LAB_ADMIN: t('userManagement.roles.labAdmin'),
  LAB_MEMBER: t('userManagement.roles.labMember'),
};

export function stringifyUserRoles(roles: UserRole[]): string {
  return (
    roles
      // eslint-disable-next-line security/detect-object-injection -- we want this
      .map((role) => roleDisplayNameMap[role])
      .join(', ')
  );
}
