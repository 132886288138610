import { useSearchParams } from 'react-router-dom';

export const useHistoryChartsConfig = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const setPointColor = '#000';
  const alertHighLowColor = '#db171e';
  const processValueColor = '#145ADD';

  const toggleShowThresholdRefs = (state: boolean) => {
    setSearchParams((prev) => {
      prev.set('showAlertsThreshold', String(state));
      return prev;
    });
  };

  return {
    showThresholdRefs: searchParams.get('showAlertsThreshold')! === 'true',
    toggleShowThresholdRefs,
    chartColors: {
      setPointColor,
      alertHighLowColor,
      processValueColor,
    },
  };
};
