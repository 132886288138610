import { DeviceParameterConfig } from '@eppendorf/vnls-application-configuration-service-types';
import { Device, RealtimeData } from '@eppendorf/vnls-telemetry-and-events-types';

import { useDeviceTypeConfig } from '$components/app-config/useDeviceTypeConfig';

export function useDeviceParameterAccessor(
  device?: Device,
): (realtimeData: RealtimeData) => DeviceParameterConfig | undefined {
  const config = useDeviceTypeConfig(device);

  return (realtimeData: RealtimeData) =>
    config?.parameters?.find(
      ({ subject, name }) =>
        subject === realtimeData.subject || name === realtimeData.name,
    );
}
