import {
  Tags,
  type DeviceParameterConfig,
} from '@eppendorf/vnls-application-configuration-service-types';
import { Icon, IconSizeClasses, Badge } from '@eppendorf/vnls-react-components';
import { useTranslation } from 'react-i18next';

import { getIconNameForParameterName } from '$features/monitoring/monitoring.utils';

export interface MonitoringParameterOfflineProps {
  name: string;
  parameterConfig?: DeviceParameterConfig | undefined;
}

export function MonitoringParameterOffline({
  name,
  parameterConfig,
}: MonitoringParameterOfflineProps) {
  const { t } = useTranslation();

  return (
    <div className="box bg-gray-50" role="button">
      <div className="flex flex__jc--space-between flex__ai--center">
        <div className="flex flex__ai--center">
          <Icon name={getIconNameForParameterName(name)} size={IconSizeClasses.Small} />
          <span className="p-left-m">{name}</span>
          {parameterConfig?.tags?.includes(Tags.Extension) && (
            <Icon name="sense-sensor" size={IconSizeClasses.XSmall} />
          )}
        </div>
        <Badge size="small">{t('monitoringDetail.noLiveData')}</Badge>
      </div>
    </div>
  );
}
