import { FunctionComponentElement } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import {
  ConfirmDialogIconType,
  ConfirmationDialog,
} from '$components/confirmation-dialog/confirmation-dialog';
import { displayUser } from '$features/invite-user/invite-user-dialog/screens/display-user.util';
import { InviteUserForm } from '$features/invite-user/invite-user-dialog/screens/invite-screen';

export interface SuccessScreenProps {
  invitedUser?: InviteUserForm;
}

export function SuccessScreen({
  invitedUser,
}: SuccessScreenProps): FunctionComponentElement<SuccessScreenProps> {
  const { t } = useTranslation();

  return (
    <ConfirmationDialog
      title={t('userInvite.inviteSuccess')}
      iconType={ConfirmDialogIconType.success}
      dialogDescription={
        <Trans
          i18nKey="userInvite.descriptionInviteConfirmed"
          values={{
            user: displayUser(invitedUser),
          }}
          components={{ bold: <span className="font-weight-bold" /> }}
        />
      }
    />
  );
}
