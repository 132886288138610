import { Event as DeviceEvent } from '@eppendorf/vnls-notification-service-types';
import { addDays } from 'date-fns';
import { useCallback, useEffect, useState, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import {
  useDeviceEventsWithQueryParams,
  useLatestDeviceEventsWithQueryParams,
} from '$features/monitoring/events/events.api';

const DEFAULT_PAGE_SIZE = 15;
const DEFAULT_PAGE_NUMBER = 1;
const DEFAULT_FROM_DATE = addDays(new Date(), -90).getTime().toString();

export const useRequestMonitoringEvents = () => {
  const [searchParams] = useSearchParams();
  const [events, setEvents] = useState<(DeviceEvent & { uniqueId: string })[]>([]);
  const [page, setPage] = useState(DEFAULT_PAGE_NUMBER);
  const [deviceHasNewEvents, setDeviceHasNewEvents] = useState(false);

  const queryParams = useMemo(
    () => ({
      serialNumber: searchParams.get('serialNumber') ?? '',
      tags: searchParams.get('tags')?.split(',') || undefined,
      from: searchParams.get('from') ?? DEFAULT_FROM_DATE,
      to: searchParams.get('to'),
      pageSize: DEFAULT_PAGE_SIZE,
      message: searchParams.get('message') || undefined,
      page,
    }),
    [searchParams, page],
  );

  const { data, ...requestStatus } = useDeviceEventsWithQueryParams(queryParams);
  const { data: latestEvents } = useLatestDeviceEventsWithQueryParams(queryParams);

  const updateFirstPageEvents = useCallback(() => {
    if (latestEvents) {
      setEvents((prev) => {
        const newEvents = latestEvents.filter(
          (latestEvent) => !prev.some((event) => event.uniqueId === latestEvent.uniqueId),
        );
        return [...newEvents, ...prev]
          .slice(0, DEFAULT_PAGE_SIZE)
          .sort((a, b) => b.timestamp.localeCompare(a.timestamp));
      });
    }
  }, [latestEvents]);

  useEffect(() => {
    setPage(DEFAULT_PAGE_NUMBER);
    setEvents([]);
  }, [queryParams.message, queryParams.from, queryParams.to]);

  useEffect(() => {
    if (data?.length) {
      setEvents((prev) => {
        const newEvents = data.filter(
          (event) =>
            !prev.some((existingEvent) => existingEvent.uniqueId === event.uniqueId),
        );
        return [...prev, ...newEvents];
      });
    }
  }, [data]);

  useEffect(() => {
    if (latestEvents?.length && !deviceHasNewEvents) {
      const initFirstPageEventIds = events
        .slice(0, DEFAULT_PAGE_SIZE)
        .map((event) => event.uniqueId);
      const hasNewEvents = latestEvents
        .map((event) => event.uniqueId)
        .some((eventId) => !initFirstPageEventIds.includes(eventId));
      setDeviceHasNewEvents(hasNewEvents);
    }
  }, [latestEvents, deviceHasNewEvents, events]);

  const loadMore = useCallback(() => {
    setPage((prev) => prev + 1);
  }, []);

  const resetNewEventsTracker = useCallback(() => {
    setDeviceHasNewEvents(false);
  }, []);

  return {
    events,
    loadMore,
    requestStatus,
    deviceHasNewEvents,
    resetNewEventsTracker,
    updateFirstPage: updateFirstPageEvents,
  };
};
