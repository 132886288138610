import { Button, Dialog, Icon, IconSizeClasses } from '@eppendorf/vnls-react-components';
import { FunctionComponentElement } from 'react';
import { useTranslation } from 'react-i18next';

export interface CancelEditDialogProps {
  isOpen?: boolean;
  onOpenChange?: (open: boolean) => void;
  onDiscardChanges: () => void;
  onSaveChanges: () => void;
  isLoading?: boolean;
}

export function CancelEditDialog({
  isOpen = false,
  onOpenChange,
  onDiscardChanges,
  onSaveChanges,
  isLoading,
}: CancelEditDialogProps): FunctionComponentElement<CancelEditDialogProps> {
  const { t } = useTranslation();

  function handleOpenChange(open: boolean): void {
    if (onOpenChange) onOpenChange(open);
  }

  return (
    <Dialog isOpen={isOpen} onOpenChange={(open) => handleOpenChange(open)}>
      <>
        <div className="flex flex__dir--column flex__ai--center">
          <Icon
            name="info"
            size={IconSizeClasses.XLarge}
            className="bg-orange-400 m-bottom-xxl"
          />
          <Dialog.Title>{t('shared.unsavedChangesWarning')}</Dialog.Title>
          <Dialog.Description>{t('shared.unsavedChangesQuestion')}</Dialog.Description>
        </div>
        <Dialog.Actions>
          <Button
            variant="secondary"
            type="submit"
            onClick={() => {
              onSaveChanges();
            }}
            disabled={isLoading}
          >
            {t('shared.saveChanges')}
          </Button>
          <Button
            onClick={() => {
              onDiscardChanges();
            }}
            className="btn--solid"
            disabled={isLoading}
          >
            {t('shared.discard')}
          </Button>
        </Dialog.Actions>
      </>
    </Dialog>
  );
}
