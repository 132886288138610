export const getEventIconName = (
  tag: string,
): 'error' | 'warning' | 'notice' | 'alert' | 'info' => {
  switch (tag) {
    case 'warning':
    case 'warn':
      return 'warning';
    case 'info':
    case 'notice':
      return 'notice';
    case 'error':
      return 'error';
    default:
      return 'alert';
  }
};
