import { Button } from '@eppendorf/vnls-react-components';
import { ReactElement, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { CookieElementWrapper } from '$components/cookie-policy/cookie-element-wrapper';

// This is a usual button instead of a button provided by OneTrust.
// Using the button from OneTrust script produces side effects such as:
// - The button is not styled correctly (this can be solved by removing the id="ot-sdk-btn" from the button)
// - OneTrust sets localization string automatically, which is not working in some cases (after soft reload, fixed by hard reload)
// - The Privacy Preference Center close button (x) does not hide/close the overlay that blocks the entire page

export function CookieSettingsButton(): ReactElement {
  const { t } = useTranslation();

  const handleOpenConsentSettings = useCallback(() => {
    if (window?.OneTrust) {
      window.OneTrust.ToggleInfoDisplay?.();
    }
  }, [window?.OneTrust]);

  return (
    <CookieElementWrapper>
      <Button type="button" onClick={handleOpenConsentSettings}>
        {t('footer.cookieSettings')}
      </Button>
    </CookieElementWrapper>
  );
}
