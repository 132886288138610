import { ReactElement } from 'react';

import { NotificationDevicesInfo } from '$features/notifications/detail/notification-detail-devices';
import { NotificationDetailEditButton } from '$features/notifications/detail/notification-detail-edit-button';
import { NotificationDetailHeader } from '$features/notifications/detail/notification-detail-header';
import { NotificationRuleDetailData } from '$features/notifications/types';

import { NotificationDetailCondition } from './notification-detail-condition';
import { NotificationDetailCreatedInfo } from './notification-detail-created-info';
import { NotificationDetailRecipient } from './notifications-detail-recipient';

export interface NotificationRuleDetailProps {
  data: NotificationRuleDetailData;
  onEditDialogOpen: () => void;
}

export function NotificationRuleDetail({
  data,
  onEditDialogOpen,
}: NotificationRuleDetailProps): ReactElement {
  const errorsFound = data.devices.errors.length > 0;

  return (
    <>
      <div
        className="flex flex__ai--center m-bottom-xxxl"
        data-testid="device-detail-header"
      >
        <NotificationDetailHeader className="m-right-m" notificationRule={data} />
      </div>
      <div className="box flex__dir--column h-full overflow-scroll">
        <NotificationDevicesInfo
          devices={data.devices.details}
          fetchError={errorsFound}
        />
        <NotificationDetailCondition tags={data.tags} />
        <NotificationDetailRecipient recipients={data.recipients} />
        <NotificationDetailCreatedInfo data={data} />
        <NotificationDetailEditButton onEditDialogOpen={() => onEditDialogOpen()} />
      </div>
    </>
  );
}
