import {
  HasClassName,
  Icon,
  IconSizeClasses,
  Tooltip,
} from '@eppendorf/vnls-react-components';
import { AlertState } from '@eppendorf/vnls-telemetry-and-events-types';
import { ReactElement } from 'react';

import { getTitleByStatus } from '$features/monitoring/alert-status/alert-status.utils';

export interface AlertStatusProps extends HasClassName {
  alertStatus?: AlertState;
}

export function AlertStatus({ alertStatus, className }: AlertStatusProps): ReactElement {
  function getIconByStatus(status?: string) {
    switch (status) {
      case AlertState.Alert:
        return <Icon name="event-alert" size={IconSizeClasses.XSmall} />;
      case AlertState.Warn:
        return <Icon name="event-warning" size={IconSizeClasses.XSmall} />;
      default:
        return null;
    }
  }

  return (
    <Tooltip
      trigger={
        <div className={className} data-testid={getTitleByStatus(alertStatus)}>
          {getIconByStatus(alertStatus)}
        </div>
      }
    >
      {getTitleByStatus(alertStatus)}
    </Tooltip>
  );
}
