import { Icon, IconSizeClasses } from '@eppendorf/vnls-react-components';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { getEventIconName } from '$features/monitoring/events/events.utils';
import { severities } from '$features/notifications/add-or-edit-notification-rule-dialog/screens/condition-screen';

export function NotificationDetailCondition({
  tags,
}: {
  tags: string[] | null;
}): ReactElement {
  const { t } = useTranslation();

  const showConditionMessage = (tag: string) => (
    <div key={`tag-${tag}`} className="flex flex__ac--flex-start">
      <Icon
        name={`event-${getEventIconName(tag)}`}
        size={IconSizeClasses.XSmall}
        key={`tag-${tag}`}
        className="m-x-m p-x-m"
      />
      <span className="p-top-xxs">
        <span className="font-weight-bold">
          {/* eslint-disable-next-line @typescript-eslint/no-explicit-any -- we know it exists */}
          {t(`notificationTags.${tag}` as any)}
        </span>
        <span>
          {/* eslint-disable-next-line @typescript-eslint/no-explicit-any -- we know it exists */}
          {t(`notificationTags.${tag}Message` as any)}
        </span>
      </span>
    </div>
  );

  return (
    <div className="condition row p-top-xl">
      <div className="col-3">
        <h3 className="body-bold">{t('notificationDetail.condition')} </h3>
      </div>
      <div className="severity-list col-9 flex flex__dir--column gap-xl">
        <span>
          {t(`tagsCondition.notify`)}
          <span className="body-bold">{t(`tagsCondition.events`)} </span>
          {t(`tagsCondition.within`)}
          <span className="body-bold">{t(`tagsCondition.severity`)} </span>
        </span>
        {tags &&
          severities
            .map((severity) => severity.name)
            .filter((tag) => tags.includes(tag))
            .map((tag) => showConditionMessage(tag))}
      </div>
    </div>
  );
}
