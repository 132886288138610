import { Device } from '@eppendorf/vnls-inventory-service-types';
import { useTranslation } from 'react-i18next';

import { EditInfoItem } from '$features/devices/device-information/edit-info-item';
import { InfoItem } from '$features/devices/device-information/info-item';
import { resolveDeviceMetaDataValue } from '$features/devices/devices.utils';

export function DeviceLocation({
  data,
  editMode,
}: {
  data: Device['location'] | undefined;
  editMode: boolean;
}) {
  const { t } = useTranslation();

  return (
    <>
      <div className="sub-title m-y-xxl">
        {t('deviceDetail.deviceInformation.location')}
      </div>
      {!editMode && (
        <>
          <InfoItem
            label={t('deviceDetail.deviceInformation.site')}
            value={resolveDeviceMetaDataValue(data?.site)}
          />
          <InfoItem
            label={t('deviceDetail.deviceInformation.building')}
            value={resolveDeviceMetaDataValue(data?.building)}
          />
          <InfoItem
            label={t('deviceDetail.deviceInformation.room')}
            value={resolveDeviceMetaDataValue(data?.room)}
          />
          <InfoItem
            label={t('deviceDetail.deviceInformation.position')}
            value={resolveDeviceMetaDataValue(data?.position)}
          />
        </>
      )}
      {editMode && (
        <>
          <EditInfoItem id="site" label={t('deviceDetail.deviceInformation.site')} />
          <EditInfoItem
            id="building"
            label={t('deviceDetail.deviceInformation.building')}
          />
          <EditInfoItem id="room" label={t('deviceDetail.deviceInformation.room')} />
          <EditInfoItem
            id="position"
            label={t('deviceDetail.deviceInformation.position')}
          />
        </>
      )}
    </>
  );
}
