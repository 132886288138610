import { subHours } from 'date-fns';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

export const RANGE_PERIOD = {
  CUSTOM: 'custom',
  DAYS_30: '30days',
  DAYS_14: '14days',
  DAYS_7: '7days',
  HOURS_24: '24hours',
  LIVE: 'live',
};

export const useRangePeriod = () => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const rangePeriod = searchParams.get('rangePeriod');
  const [{ from, to }, setFromTo] = useState<{ from: number | null; to: number | null }>(
    () => {
      const range: { from: number | null; to: number | null } = {
        from: null,
        to: null,
      };

      if (rangePeriod === RANGE_PERIOD.CUSTOM) {
        range.from = Number(searchParams.get('from')) || null;
        range.to = Number(searchParams.get('to')) || null;
      }

      return range;
    },
  );

  const setRange = (
    range: string,
    nextFrom: number | null,
    nextTo: number | null = Date.now(),
  ) => {
    /*
     * params received from useSearchParams can have stale data, window.location.search is used as the source of truth
     * https://github.com/remix-run/react-router/issues/9757
     */
    const params = new URLSearchParams(window.location.search);

    params.set('rangePeriod', range);
    setSearchParams(params);
    setFromTo({ from: nextFrom, to: nextTo });
  };

  const [rangePeriodItems, setRangePeriodItems] = useState([
    {
      id: RANGE_PERIOD.CUSTOM,
      text: t('monitoringDetail.historyChartsDetail.rangePeriod.custom'),
      active: rangePeriod === RANGE_PERIOD.CUSTOM,
      onClick: () => {
        const params = new URLSearchParams(window.location.search);

        setRange(
          RANGE_PERIOD.CUSTOM,
          Number(params.get('from')) || null,
          Number(params.get('to')) || null,
        );
      },
    },

    // Predefined ranges, will be used in ESEVNLS-2406, ESEVNLS-2407

    // {
    //   id: RANGE_PERIOD.DAYS_30,
    //   text: t('monitoringDetail.historyChartsDetail.rangePeriod.30days'),
    //   active: rangePeriod === RANGE_PERIOD.DAYS_30,
    //   onClick: () => setRange(RANGE_PERIOD.DAYS_30, subDays(Date.now(), 30).getTime()),
    // },
    // {
    //   id: RANGE_PERIOD.DAYS_14,
    //   text: t('monitoringDetail.historyChartsDetail.rangePeriod.14days'),
    //   active: rangePeriod === RANGE_PERIOD.DAYS_14,
    //   onClick: () => setRange(RANGE_PERIOD.DAYS_14, subDays(Date.now(), 14).getTime()),
    // },
    // {
    //   id: RANGE_PERIOD.DAYS_7,
    //   text: t('monitoringDetail.historyChartsDetail.rangePeriod.7days'),
    //   active: rangePeriod === RANGE_PERIOD.DAYS_7,
    //   onClick: () => setRange(RANGE_PERIOD.DAYS_7, subDays(Date.now(), 7).getTime()),
    // },
    // {
    //   id: RANGE_PERIOD.HOURS_24,
    //   text: t('monitoringDetail.historyChartsDetail.rangePeriod.24hours'),
    //   active: rangePeriod === RANGE_PERIOD.HOURS_24,
    //   onClick: () => setRange(RANGE_PERIOD.HOURS_24, subHours(Date.now(), 24).getTime()),
    // },

    {
      id: RANGE_PERIOD.LIVE,
      text: t('monitoringDetail.historyChartsDetail.rangePeriod.live'),
      active: rangePeriod === RANGE_PERIOD.LIVE,
      onClick: () => setRange(RANGE_PERIOD.LIVE, subHours(Date.now(), 1).getTime()),
    },
  ]);

  // Sync "from" and "to" from search params with internal state
  useEffect(() => {
    if (rangePeriod === RANGE_PERIOD.CUSTOM) {
      setFromTo({
        from: Number(searchParams.get('from')) || null,
        to: Number(searchParams.get('to')) || null,
      });
    }
  }, [searchParams]);

  // Set active button
  useEffect(() => {
    setRangePeriodItems((prev) =>
      prev.map((item) => ({
        ...item,
        active: item.id === rangePeriod,
      })),
    );
  }, [rangePeriod]);

  // Set default range if not set or invalid
  useEffect(() => {
    (rangePeriodItems.find((item) => item.active) ?? rangePeriodItems[0]).onClick();
  }, []);

  return {
    rangePeriodItems,
    rangePeriod,
    from,
    to,
  };
};
