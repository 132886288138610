import {
  Button,
  Dialog,
  Input,
  Label,
  ValidationMessage,
} from '@eppendorf/vnls-react-components';
import {
  ChangeEvent,
  FormEvent,
  FunctionComponentElement,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { isSerialNumberValid } from '$features/add-device/add-device.helper';
import { useAddDevice } from '$features/devices/devices.api';

export interface SubmitScreenProps {
  onSubmit: (s: string) => void;
}

export function SubmitScreen({
  onSubmit,
}: SubmitScreenProps): FunctionComponentElement<SubmitScreenProps> {
  const [inputValue, setInputValue] = useState('');
  const [validationMessage, setValidationMessage] = useState('');

  const { t } = useTranslation();
  const { mutate, isError, error, isSuccess, isLoading, reset } = useAddDevice();

  useEffect(() => {
    if (inputValue && !isSerialNumberValid(inputValue)) {
      setValidationMessage(t('serialNumbers.validationMessage'));
    } else if (error?.response) {
      if (error.response?.status === 409) {
        setValidationMessage(t('serialNumbers.serialNumberDuplicateErrorMessage'));
      } else {
        setValidationMessage(t('errors.unexpected'));
      }
    } else {
      setValidationMessage('');
    }
  }, [inputValue, error]);

  useEffect(() => {
    if (isSuccess && !isError) {
      onSubmit(inputValue);
    }
  }, [isSuccess, isError]);

  function handleFormSubmit(e: FormEvent<HTMLFormElement>): void {
    e.preventDefault();

    mutate({
      manufacturer: 'Eppendorf',
      serialNumber: inputValue,
    });
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setInputValue(e.target.value);
    if (isError) {
      reset();
    }
  };

  return (
    <>
      <Dialog.Title>{t('addDevice.add')}</Dialog.Title>
      <Dialog.Description className="font-size-xl m-y-xxl">
        {t('addDevice.connectableDevice')}
      </Dialog.Description>
      <form onSubmit={handleFormSubmit}>
        <Label htmlFor="serialNumber">{t('serialNumbers.enter')}</Label>
        <Input
          invalid={(inputValue && !isSerialNumberValid(inputValue)) || isError}
          className="m-top-xs"
          id="serialNumber"
          type="text"
          value={inputValue}
          onChange={handleChange}
          disabled={isLoading}
        />
        {validationMessage && <ValidationMessage>{validationMessage}</ValidationMessage>}
        <Dialog.Actions className="m-top-xxxl">
          <Dialog.Close>
            <Button variant="secondary">{t('shared.close')}</Button>
          </Dialog.Close>
          <Button
            type="submit"
            disabled={
              !inputValue || !isSerialNumberValid(inputValue) || isLoading || isError
            }
          >
            {t('serialNumbers.submit')}
          </Button>
        </Dialog.Actions>
      </form>
    </>
  );
}
