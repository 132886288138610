import { Event as DeviceEvent } from '@eppendorf/vnls-notification-service-types';
import { Icon, IconSizeClasses } from '@eppendorf/vnls-react-components';

import { EventDateTimeCell } from '$features/monitoring/events/components/event-date-time-cell';
import { getEventIconName } from '$features/monitoring/events/events.utils';

export function EventRow({ deviceEvent }: { deviceEvent: DeviceEvent }) {
  return (
    <div className="event_row list__item flex flex__ai--center p-l border-solid border-color-gray-400 border-bottom-s row">
      <div className="col-2">
        {deviceEvent.tags
          .filter(
            (tag) =>
              tag === 'error' ||
              tag === 'warn' ||
              tag === 'notice' ||
              tag === 'info' ||
              tag === 'alert',
          )
          .map((tag) => (
            <Icon
              name={`event-${getEventIconName(tag.toLowerCase())}`}
              size={IconSizeClasses.XSmall}
              key={`tag-${tag.toLowerCase()}`}
            />
          ))}
      </div>
      <div className="col-3">
        <EventDateTimeCell timestamp={deviceEvent.timestamp} />
      </div>
      <div className="col-7">{deviceEvent.message}</div>
    </div>
  );
}
