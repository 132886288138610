import { Device as InventoryDevice } from '@eppendorf/vnls-inventory-service-types';
import { Icon, IconSizeClasses } from '@eppendorf/vnls-react-components';
import { Device } from '@eppendorf/vnls-telemetry-and-events-types';
import { ReactElement } from 'react';

import { useDeviceType } from '$components/app-config/useDeviceType';
import { DeviceNameOrSerialNumber } from '$features/device-list/device-name-or-serialnumber';
import { searchDeviceIcon } from '$features/devices/devices.utils';

export function DeviceNameCell({
  device,
}: {
  device: Device | InventoryDevice | null;
}): ReactElement {
  const deviceType = useDeviceType(device);

  return (
    <div className="flex flex__ai--center">
      <Icon size={IconSizeClasses.Small} name={searchDeviceIcon(deviceType as string)} />

      <div className="m-left-s">
        <h2 className="font-weight-bold">
          <DeviceNameOrSerialNumber device={device || undefined} />
        </h2>
        <div className="color-gray-800">
          {device?.manufacturer} {device?.model}
        </div>
      </div>
    </div>
  );
}
