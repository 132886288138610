import { hasPermission, PermissionsType } from '@eppendorf/vnls-user-tenant-utils';
import { useEffect, useState } from 'react';

import { useTokenAttributes } from '$shared/custom-hooks/useTokenAttributes';

export const useAccessControlGuard = (
  requiredPermissions: PermissionsType | PermissionsType[],
) => {
  const [hasAccess, setHasAccess] = useState(false);
  const { permissions: userPermissions } = useTokenAttributes(['permissions']);

  useEffect(() => {
    if (userPermissions && requiredPermissions) {
      setHasAccess(
        hasPermission(requiredPermissions, userPermissions as PermissionsType),
      );
    }
  }, [userPermissions, requiredPermissions]);

  return hasAccess;
};
