import { DateRangePickerProps } from '@eppendorf/vnls-react-components';
import { endOfDay, startOfDay } from 'date-fns';
import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

export const useDateRangeChange = () => {
  const setSearchParams = useSearchParams()[1];

  const onRangeChange = useCallback(
    (dateRange: DateRangePickerProps['dateRange']) => {
      setSearchParams((prev) => {
        // by default we set "from" and "to" to the same day
        if (dateRange?.from) {
          prev.set('from', startOfDay(dateRange.from).getTime().toString());
          prev.set('to', endOfDay(dateRange.from).getTime().toString());
        }
        // if "to" is selected, we set it to the end of the day
        if (dateRange?.to) {
          prev.set('to', endOfDay(dateRange.to).getTime().toString());
        }
        // if both are empty, we remove them from the search params
        if (!dateRange?.from && !dateRange?.to) {
          prev.delete('from');
          prev.delete('to');
        }

        return prev;
      });
    },
    [setSearchParams],
  );

  return { onRangeChange };
};
