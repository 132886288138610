import { Badge, HasClassName } from '@eppendorf/vnls-react-components';
import { UnitState } from '@eppendorf/vnls-telemetry-and-events-types';
import { ReactElement } from 'react';

import { getLocalizedDate } from '$shared/utils/date.utils';

import { getActivityStatusText } from '$features/monitoring/activity-status/activity-status.utils';

export interface ActivityStatusProps extends HasClassName {
  activityStatus: UnitState | null;
  bordered?: boolean;
  offlineSince?: number;
}

export function ActivityStatus({
  activityStatus,
  className,
  bordered = false,
  offlineSince,
}: ActivityStatusProps): ReactElement {
  function getColorBadgeByStatus(status: UnitState | null) {
    switch (status) {
      case UnitState.Paused:
      case UnitState.Hold:
      case UnitState.Preparing:
        return (
          <Badge bordered={bordered} color="orange">
            {getActivityStatusText(status)}
          </Badge>
        );

      case UnitState.AutomaticallyRunning:
      case UnitState.ManuallyRunning:
      case UnitState.Running:
        return (
          <Badge bordered={bordered} color="green">
            {getActivityStatusText(status)}
          </Badge>
        );

      case UnitState.Idle:
      case UnitState.Done:
      case UnitState.Online:
        return (
          <Badge bordered={bordered} color="blue">
            {getActivityStatusText(status)}
          </Badge>
        );

      case UnitState.Unreachable:
      case UnitState.Error:
        return (
          <Badge bordered={bordered} color="red">
            {getActivityStatusText(status)}
          </Badge>
        );

      case UnitState.Offline:
        return (
          <Badge bordered={bordered}>
            {`${getActivityStatusText(status)}${
              offlineSince ? ` since ${getLocalizedDate(offlineSince)}` : ''
            }`}
          </Badge>
        );
      default:
        return '';
    }
  }

  return <div className={className}>{getColorBadgeByStatus(activityStatus)}</div>;
}
