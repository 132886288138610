import { format } from 'date-fns';

import i18next from '$shared/i18n/i18n';

export function createCurrentDateString(): string {
  const date = new Date();
  return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
}

export function getLocalizedTime(UTCUnixTime: string | number): string {
  return i18next.t('translation:shared.intlDateTime', {
    val: new Date(UTCUnixTime),
    formatParams: {
      val: {
        hour: 'numeric',
        minute: 'numeric',
        hourCycle: 'h23',
      },
    },
  });
}

export function getLocalizedMonthAndDay(UTCUnixTime: string | number): string {
  return i18next.t('translation:shared.intlDateTime', {
    val: new Date(UTCUnixTime),
    formatParams: {
      val: {
        month: 'numeric',
        day: 'numeric',
      },
    },
  });
}

export function getLocalizedTimeWithSeconds(UTCUnixTime: string | number): string {
  return i18next.t('translation:shared.intlDateTime', {
    val: new Date(UTCUnixTime),
    formatParams: {
      val: {
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hourCycle: 'h23',
      },
    },
  });
}

export function getLocalizedDate(UTCUnixTime: string | number): string {
  return i18next.t('translation:shared.intlDateTime', {
    val: new Date(UTCUnixTime),
    formatParams: {
      val: {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      },
    },
  });
}

export function getLocalizedDateTime(UTCUnixTime: string | number): string {
  return i18next.t('translation:shared.intlDateTime', {
    val: new Date(UTCUnixTime),
    formatParams: {
      val: {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
      },
    },
  });
}

export function getFormatedLocalizedDate(UTCUnixTime: string | number): string {
  return format(UTCUnixTime, 'dd.MM.yyyy, HH:mm')
}