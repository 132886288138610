import { Environment } from './env';

export const apiUrl = import.meta.env.PUBLIC_API_URL;
export const graphqlUrl = import.meta.env.PUBLIC_GRAPHLQL_ENDPOINT;
export const targetEnvironment = import.meta.env.PUBLIC_ENVIRONMENT;

export const environment: Environment = {
  apiUrl,
  graphqlUrl,
  targetEnvironment,
};
