import { Auth } from '@aws-amplify/auth';
import type { CognitoUserSession } from 'amazon-cognito-identity-js';
import { useEffect, useMemo, useState } from 'react';

export function useTokenAttributes(tokenAttributes: string[]): Record<string, string> {
  const [session, setSession] = useState<CognitoUserSession | null>(null);

  useEffect(() => {
    const fetchSession = async () => {
      try {
        const currentSession: CognitoUserSession = await Auth.currentSession();
        setSession(currentSession);
      } catch (error) {
        // eslint-disable-next-line no-console -- log for the time being
        console.error('An error occurred fetching the current user session', error);
      }
    };

    fetchSession();
  }, []);

  const attributes = useMemo(() => {
    if (session) {
      const tokenPayload = session.getIdToken().payload;

      return Object.fromEntries(
        tokenAttributes.map((attribute) => [
          attribute,
          // eslint-disable-next-line security/detect-object-injection -- no user input here
          tokenPayload[attribute],
        ]),
      );
    }

    return {};
  }, [session, tokenAttributes]);

  return attributes;
}
