import { createContext } from 'react';

import { SidecarRoute } from '$components/sidecar-router/sidecar-router.definitions';

export interface SidecarRouterContextValue {
  route?: SidecarRoute;
}

export const SidecarRouterContext = createContext<SidecarRouterContextValue>({});
export const SidecarRouterProvider = SidecarRouterContext.Provider;
