import { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLoaderData } from 'react-router-dom';

import {
  LicensesMap,
  createLicensesQuery,
  mapLicensesToTableItems,
  OpenSourceLibrariesTable,
} from '$features/licenses';

export async function librariesLoader(): Promise<LicensesMap> {
  return createLicensesQuery();
}

export function OpenSourceLibrariesPage(): ReactElement {
  const { t } = useTranslation();

  const data = useLoaderData() as LicensesMap | null;

  const tableItems = useMemo(() => mapLicensesToTableItems(data), [data]);

  return (
    <div className="box">
      <h1 className="title">{t('osl.title')}</h1>

      <OpenSourceLibrariesTable items={tableItems} />
    </div>
  );
}
