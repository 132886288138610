import { DeviceTypes } from '@eppendorf/vnls-application-configuration-service-types';
import {
  Label,
  CardSelect,
  CardSelectOption,
  Dialog,
  Button,
} from '@eppendorf/vnls-react-components';
import { FunctionComponentElement, ReactNode, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { useGetDeviceTypeConfigs } from '$components/app-config/app-config.api';
import { searchDeviceIcon } from '$features/devices/devices.utils';
import { AddNotificationRuleScreenProps } from '$features/notifications/types';

interface DeviceTypeScreenProps extends AddNotificationRuleScreenProps {
  onContinue: () => void;
  screenHasErrors: boolean;
  isLoading: boolean;
}

export function DeviceTypeScreen({
  formControl,
  onContinue,
  screenHasErrors,
  isLoading,
}: DeviceTypeScreenProps): FunctionComponentElement<ReactNode> {
  const { t } = useTranslation();
  const { register, watch, setValue } = formControl;

  const { data: deviceTypesConfigs } = useGetDeviceTypeConfigs();
  const deviceTypeOptions: CardSelectOption[] = (deviceTypesConfigs || []).map(
    ({ type }) => ({
      label: type,
      value: type,
      icon: searchDeviceIcon(type),
    }),
  );

  useEffect(() => {
    register('selectedDeviceType', {
      validate: (v) => !!v,
    });
    formControl.trigger('selectedDeviceType');
  }, [register]);

  return (
    <>
      <div className="m-top-m">
        <Label htmlFor="deviceType" className="font-size-xl">
          {t('notificationRules.addRuleSteps.selectDeviceType.label')}
        </Label>
      </div>
      <p className="p-y-l">
        <Trans
          i18nKey="notificationRules.addRuleSteps.selectDeviceType.fieldDescription"
          components={{ bold: <span className="font-weight-bold" /> }}
        />
      </p>
      {deviceTypesConfigs && deviceTypeOptions && (
        <CardSelect
          className="m-top-xs w-full flex__jc--space-between h-max-s"
          value={watch('selectedDeviceType')}
          onChange={(value) =>
            setValue('selectedDeviceType', value as DeviceTypes, { shouldDirty: true })
          }
        >
          {deviceTypeOptions.map((option) => (
            <CardSelect.Card
              className="device-category-btn"
              key={option.value}
              label={option.label}
              value={option.value}
              icon={option.icon}
            />
          ))}
        </CardSelect>
      )}

      <Dialog.Actions>
        <Dialog.Close>
          <Button variant="secondary">{t('shared.close')}</Button>
        </Dialog.Close>

        <Button onClick={onContinue} disabled={isLoading || screenHasErrors}>
          {t('shared.continue')}
        </Button>
      </Dialog.Actions>
    </>
  );
}
