import { ReactElement, useEffect } from 'react';

import { CookieSettingsButton } from '$components/cookie-policy/cookie-settings-button';

export function CookieList(): ReactElement {
  useEffect(() => {
    if (window?.OneTrust) {
      window.OneTrust.initializeCookiePolicyHtml?.();
    }
  }, [window?.OneTrust]);

  return (
    <div className="box">
      <div className="flex flex__jc--flex-end">
        <CookieSettingsButton />
      </div>
      <div id="ot-sdk-cookie-policy" />
    </div>
  );
}
