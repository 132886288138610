import { Auth } from '@aws-amplify/auth';
import { ReactElement, useEffect } from 'react';

import { Loader } from '$components/loader/loader';

export function SignoutPage(): ReactElement {
  useEffect(() => {
    const redirectToSignin = async () => {
      await Auth.federatedSignIn();
    };

    redirectToSignin();
  }, []);

  return <Loader />;
}
