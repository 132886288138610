import {
  ButtonBase,
  DateRangePicker,
  DotsLoader,
  Icon,
  IconSizeClasses,
  Input,
  ValidationMessage,
} from '@eppendorf/vnls-react-components';
import cn from 'classnames';
import { addDays, addMonths } from 'date-fns';
import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { VirtuosoHandle } from 'react-virtuoso';

import { useDebouncedCallback } from '$shared/custom-hooks/useDebouncedCallback';

import { LoadingErrorHint } from '$components/loading-error-hint/loading-error-hint';
import { NoEventsToShow } from '$features/monitoring/events/components/no-events-to-show';
import { VirtualList } from '$features/monitoring/events/components/virtual-list';
import { useRequestMonitoringEvents } from '$features/monitoring/events/useRequestMonitoringEvents';
import { useDateRangeChange } from '$features/monitoring/history-charts/useDateRangeChange';

import styles from './monitoring-events.module.scss';

export function MonitoringEvents() {
  const MAX_SEARCH_TERM_LENGTH = 120;
  const SEARCH_DEBOUNCE_DELAY = 500;
  const NOTIFY_NEW_EVENTS_SCROLL_OFFSET = 50;
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const to = searchParams.get('to');
  const from = searchParams.get('from');
  const message = searchParams.get('message') || '';
  const [searchInputValue, setSearchInputValue] = useState<string>(message);
  const {
    events,
    requestStatus,
    loadMore,
    deviceHasNewEvents,
    resetNewEventsTracker,
    updateFirstPage,
  } = useRequestMonitoringEvents();
  const eventsSearchInputRef = useRef<HTMLInputElement>(null);
  const [virtualListState, setVirtualListState] = useState({ scrollPosition: 0 });
  const [userIsScrollig, setUserIsScrolling] = useState(false);
  const virtualListRef = useRef<VirtuosoHandle>(null);
  const { onRangeChange } = useDateRangeChange();

  useEffect(() => {
    virtualListRef?.current?.getState((args) => {
      setVirtualListState((prev) => ({ ...prev, scrollPosition: args.scrollTop }));
    });
  }, [userIsScrollig === true]);

  useEffect(() => {
    if (
      virtualListState.scrollPosition < NOTIFY_NEW_EVENTS_SCROLL_OFFSET &&
      deviceHasNewEvents
    ) {
      updateFirstPage();
      resetNewEventsTracker();
    }
  }, [
    virtualListState.scrollPosition < NOTIFY_NEW_EVENTS_SCROLL_OFFSET,
    deviceHasNewEvents,
  ]);

  const debounceRunFullTextSearch = useDebouncedCallback(
    (searchString: string) => {
      setSearchParams((prev) => {
        if (!searchString) {
          prev.delete('message');
        } else {
          prev.set('message', searchString);
        }

        return prev;
      });
    },
    [setSearchParams],
    SEARCH_DEBOUNCE_DELAY,
  );

  const updateFullTextSearch = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    setSearchInputValue(value);
    debounceRunFullTextSearch.cancel();
    if (!value || value.length > 1) {
      debounceRunFullTextSearch(value);
    }
  };

  const isMaxSearchCharsExceeded = () =>
    (searchInputValue || message).length >= MAX_SEARCH_TERM_LENGTH;

  const resetSearch = useCallback(() => {
    setSearchParams((prev) => {
      prev.delete('from');
      prev.delete('to');
      prev.delete('message');
      setSearchInputValue('');

      return prev;
    });
  }, []);

  const clearSearchInput = useCallback(() => {
    setSearchParams((prev) => {
      prev.delete('message');
      setSearchInputValue('');
      eventsSearchInputRef.current?.focus();

      return prev;
    });
  }, [searchInputValue]);

  const goToNewEvents = useCallback(() => {
    virtualListRef.current?.scrollToIndex({
      index: 0,
      align: 'start',
      behavior: 'smooth',
    });
    resetNewEventsTracker();
  }, [virtualListState.scrollPosition > 0, deviceHasNewEvents]);

  const currentDate = new Date();
  const isLoadingOrFetching =
    requestStatus.isLoading || (requestStatus.isFetching && events.length === 0);

  return (
    <>
      <div className="m-top-xxl m-bottom-xxl flex flex__dir--column">
        <DateRangePicker
          dateRange={{
            from: from ? new Date(Number(from)) : undefined,
            to: to ? new Date(Number(to)) : undefined,
          }}
          onDateRangeChange={onRangeChange}
          initialFocus
          defaultMonth={addMonths(currentDate, -1)}
          toDate={new Date()}
          fromDate={addDays(new Date(), -90)}
          className="m-bottom-m"
        />
        <div className="m-bottom-m">
          <Input
            id="eventsFullTextSearch"
            ref={eventsSearchInputRef}
            placeholder={t('monitoringDetail.eventsDetail.eventSearchInputPlaceholder')}
            maxLength={MAX_SEARCH_TERM_LENGTH}
            value={searchInputValue}
            invalid={isMaxSearchCharsExceeded()}
            onChange={updateFullTextSearch}
            leadingVisual={
              <Icon name="search" alt="search input" size={IconSizeClasses.XSmall} />
            }
            trailingAction={
              searchInputValue.length ? (
                <Input.Action
                  icon={
                    <Icon name="close" alt="clear input" size={IconSizeClasses.XSmall} />
                  }
                  title={t('shared.clear')}
                  onClick={clearSearchInput}
                />
              ) : undefined
            }
          />
          {isMaxSearchCharsExceeded() && (
            <ValidationMessage severity="error">
              {t('validationMessages.searchMaxLength120')}
            </ValidationMessage>
          )}
        </div>
      </div>

      <small className="font-size-s m-bottom-m">
        {t('monitoringDetail.eventsDetail.timezoneHint')}
      </small>

      {isLoadingOrFetching && <DotsLoader className="m-top-10xl m-bottom-10xl" />}

      {!isLoadingOrFetching && (
        <>
          <div className="device-event__header p-m row font-weight-bold border-bottom-s border-solid border-color-gray-400">
            <div className="col-2">{t('monitoringDetail.eventsDetail.type')}</div>
            <div className="col-3">{t('monitoringDetail.eventsDetail.time')}</div>
            <div className="col-7">{t('monitoringDetail.eventsDetail.event')}</div>
          </div>
          <div className="device-event__list overflow-hidden overflow-y-auto h-full">
            {events.length === 0 && !requestStatus.isError ? (
              <NoEventsToShow onResetSearch={resetSearch} />
            ) : (
              <>
                <VirtualList
                  data={events}
                  loadMore={loadMore}
                  ref={virtualListRef}
                  isScrolling={setUserIsScrolling}
                />
                {deviceHasNewEvents &&
                  virtualListState.scrollPosition > NOTIFY_NEW_EVENTS_SCROLL_OFFSET && (
                    <div className={cn('container', styles.overlayBottom5)}>
                      <div className="row">
                        <ButtonBase
                          type="button"
                          className="col-offset-4 btn-base btn btn--primary btn--info btn--small p-s"
                          onClick={goToNewEvents}
                        >
                          <div className="flex flex__dir--row">
                            <div className="m-left-m p-top-xs p-right-s">
                              {t('monitoringDetail.eventsDetail.newEvents')}
                            </div>
                            <div className="m-left-m">
                              <Icon
                                name="back-to-top"
                                className="bg-white"
                                size={IconSizeClasses.XSmall}
                              />
                            </div>
                          </div>
                        </ButtonBase>
                      </div>
                    </div>
                  )}
              </>
            )}
          </div>
        </>
      )}
      {requestStatus.isError && !requestStatus.isLoading && (
        <LoadingErrorHint className="box p-s m-top-m" />
      )}
    </>
  );
}
