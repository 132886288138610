import { Button, Dialog } from '@eppendorf/vnls-react-components';
import { User } from '@eppendorf/vnls-user-tenant-utils';
import { FunctionComponentElement, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { useAutoFocus } from '$shared/custom-hooks/useAutoFocus';

import { displayUser } from '$features/invite-user/invite-user-dialog/screens/display-user.util';
import { useDeleteUser } from '$features/users/users.api';

export interface DeleteConfirmProps {
  onSubmit: () => void;
  onError: () => void;
  selectedUser: User;
}

export function DeleteConfirmScreen({
  onSubmit,
  onError,
  selectedUser,
}: DeleteConfirmProps): FunctionComponentElement<DeleteConfirmProps> {
  const { mutateAsync } = useDeleteUser();
  const [isSubmitted, setIsSubmitted] = useState(false);

  async function handleSubmit(): Promise<void> {
    if (!selectedUser.id) return;
    try {
      setIsSubmitted(true);
      await mutateAsync(selectedUser.id);
    } catch {
      onError();
      return;
    }

    onSubmit();
  }

  const { t } = useTranslation();
  const cancelButtonRef = useRef<null | HTMLButtonElement>(null);

  useAutoFocus(cancelButtonRef);

  return (
    <>
      <Dialog.Title>{t('userDelete.deleteUserQuestion')}</Dialog.Title>
      <Dialog.Description>
        <Trans
          i18nKey="userDelete.deleteUserLongQuestion"
          values={{
            user: displayUser(selectedUser),
          }}
          components={{ bold: <span className="font-weight-bold" /> }}
        />
      </Dialog.Description>
      <Dialog.Actions>
        <Dialog.Close>
          <Button variant="secondary" ref={cancelButtonRef}>
            {t('shared.cancel')}
          </Button>
        </Dialog.Close>
        <Button
          color="danger"
          type="submit"
          onClick={() => {
            handleSubmit();
          }}
          className="btn--solid"
          disabled={isSubmitted}
        >
          {t('shared.delete')}
        </Button>
      </Dialog.Actions>
    </>
  );
}
