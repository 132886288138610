import { QueryClient } from '@tanstack/react-query';
import { ReactElement } from 'react';

import { prepopulateCache } from '$shared/utils/react-query.utils';

import { MonitoringDetailSidecar } from '$features/monitoring/detail/monitoring-detail-sidecar';
import { createRealtimeDeviceQuery } from '$features/monitoring/monitoring.graphql.api';

export async function monitoringDetailLoader(
  qClient: QueryClient,
  observableDeviceSearch: { manufacturer: string; serialNumber: string },
): Promise<null> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- todo
  return prepopulateCache<any>(
    qClient,
    createRealtimeDeviceQuery(
      observableDeviceSearch.manufacturer,
      observableDeviceSearch.serialNumber,
    ),
  );
}

export function MonitoringDetailPage(): ReactElement {
  return <MonitoringDetailSidecar />;
}
